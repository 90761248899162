import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { BaseURL } from "../../../api/DataApis";

function View(props) {
  document.title = "Bus Route | View";
  let location = useLocation();
  // const BusRoutesViewData = location.state.data;

  const [map, setMap] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [routeData, setRouteData] = useState([]);
  const [tripPath, setTripPath] = useState(null);

  // Load the Google Maps script
  useEffect(() => {
    if (!scriptLoaded) {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDZW0zTKDKdxBG1eC5ACKsR1Gp9PcduvKo`;
      googleMapsScript.async = true;
      googleMapsScript.onload = () => setScriptLoaded(true);
      document.body.appendChild(googleMapsScript);

      return () => {
        document.body.removeChild(googleMapsScript);
      };
    }
  }, [scriptLoaded]);

  // Initialize the map once the script is loaded
  useEffect(() => {
    if (scriptLoaded && !map) {
      const newMap = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 26.912434, lng: 75.78727 },
        zoom: 8,
      });
      setMap(newMap);
    }
  }, [scriptLoaded, map]);

  // Fetch route data at regular intervals
  useEffect(() => {
    const interval = setInterval(() => {
      fetchRouteData();
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  const fetchRouteData = async () => {
    try {
      const response = await axios.post(
        `${BaseURL}/busroute/busRouteView/252/404704100063290`
      );
      console.log("Full response:", response);

      // Accessing the actual route data based on your response structure
      const liveRouteData = response.data[0]?.liveRouteData;
      if (liveRouteData && liveRouteData.length > 0) {
        const newRouteData = liveRouteData.map(route => ({
          lat: parseFloat(route.gps_2),
          lng: parseFloat(route.gps_3),
        }));
        setRouteData(prevData => [...prevData, ...newRouteData]);
      } else {
        console.error("liveRouteData is missing or empty");
      }
    } catch (error) {
      console.error("Error fetching route data:", error);
    }
  };

  // Draw the route on the map whenever routeData changes
  useEffect(() => {
    if (map && routeData.length > 0) {
      if (tripPath) {
        tripPath.setMap(null);
      }
      const newTripPath = new window.google.maps.Polyline({
        path: routeData,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      newTripPath.setMap(map);
      map.setCenter(routeData[routeData.length - 1]);
      map.setZoom(14);
      setTripPath(newTripPath);
    }
  }, [routeData, map]);

  console.log("Route data:", routeData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div id="hide-table">
              <h1 className="page-title">Bus Route</h1>
            </div>
          </div>
        </div>
      </div>
      <div id="map" style={{ height: "400px", width: "90%", marginLeft: "40px", marginBottom: "20px" }}></div>
    </>
  );
}

export default View;
