import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
import "./Active.css";
import axios from "axios";

function StudentStatus() {
  document.title = "Students | Status";
  const { enquiryID, classesID } = useParams();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectClassid, setSelectClassid] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [parents, setParents] = useState([]);
  const [sections, setSections] = useState([]);

  // Handler to select class and fetch students accordingly
  const SelectClassHandler = (e) => {
    setSelectClassid(e.target.value);
    fetchStudents(e.target.value);
  };

  // Fetch classes from the API
  const fetchClasses = async () => {
    setLoading(true);
    try {
      const response = await request({
        url: `${BaseURL}/classes/getClasses`,
        method: "POST",
        data: JSON.stringify({ schoolyearID }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response.classes) {
        setClasses(response.classes);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchClasses();
  }, []);

  // Fetch students from the API
  const fetchStudents = async (classID) => {
    setLoading(true);
    const apiEndpoint = `${BaseURL}/student/getStudents`;
    let url = apiEndpoint;

    if (classID) {
      url = `${apiEndpoint}/${classID}`;
    }

    try {
      const response = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({ schoolyearID }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response.students) {
        setStudents(response.students);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    if (searchQuery) {
      const filteredStudents = students.filter(
        (student) =>
          student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          student.registerNO.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setStudents(filteredStudents);
    } else {
      fetchStudents(selectClassid);
    }
  }, [searchQuery]);

  const changeStatus = async (studentID, newStatus) => {
    try {
      const response = await request({
        url: `${BaseURL}/student/changeStatus`,
        method: "POST",
        data: JSON.stringify({ sID: studentID, status: newStatus }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

     

      fetchStudents(selectClassid);
      Swal.fire({
        icon: "success",
        title: "Status Updated",
        text: "The status has been successfully changed.",
      });
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const apiEndpointt = `${BaseURL}/parents/getParents`;

  const fetchParents = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: apiEndpointt,
        method: "GET",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (response && response.parents) {
        setParents(response.parents);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParents();
  }, []);

  const getFatherName = (parentID) => {
    const parent = parents.find((p) => p.parentsID === parentID);
    return parent ? parent.father_name : 'Loading....';
  };

  const getClassname = (classID) => {
    const classItem = classes.find((c) => c.classesID === classID);
    return classItem ? classItem.classes : 'Loading....';
  };

  const fetchSection = async () => {
    setLoading(true);
    const response = await axios.get(`${BaseURL}/section/getSections`);
    const data = await response.data;
    setTimeout(() => {
      if (data) {
        setLoading(false);
        setSections(data.sections);
      }
    }, 1000);
  };

  useEffect(() => {
    fetchSection();
  }, []);

  const getSectionName = (sectionID) => {
    const section = sections.find((s) => s.sectionID === sectionID);
    return section ? section.section : "Loading....";
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <div
                  className="add"
                  style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                >
                  <h5 style={{ marginLeft: "30px", marginTop: "15px" }}>Student Status</h5>
                  <select
                    className="form-control text-center"
                    onChange={SelectClassHandler}
                    style={{  borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                      border: "-1px ", position: "relative", top: "5px" }}
                  >
                    <option value="">Select Class</option>
                    <option value="-1">View All</option>
                    {classes && classes.map((classItem) => (
                      <option key={classItem.classesID} value={classItem.classesID}>
                        {classItem.classes}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <hr style={{ marginTop: "18px" }} />
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="display dataTable" id="advance-1">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>#</th>
                      <th>Photo</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Registration No</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.length > 0 ? (
                      students.map((u, i) => (
                        <tr key={i}>
                          <td><input type="checkbox" /></td>
                          <td>{i + 1}</td>
                          <td><img style={{ width: "24px" }} src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png" /></td>
                          <td>{u?.name}</td>
                          <td>{getFatherName(u?.parentID)}</td>
                          <td>{getClassname(u?.classesID)}</td>
                          <td>{getSectionName(u?.sectionID)}</td>
                          <td>{u?.registerNO}</td>
                          <td>
                            {u?.active === "0" ? (
                              <button className="btn btn-success" onClick={() => changeStatus(u.studentID, 1)}>
                                Change to Activate
                              </button>
                            ) : (
                              <button className="btn btn-danger" onClick={() => changeStatus(u.studentID, "0")}>
                                Change to Deactivate
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9">Please Select Class</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentStatus;
