import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { schoolyearID, uniqueToken, BaseURL } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2"; 

function View(props) {
  const [Librarymemberdata, setLibrarymemberdata] =  useState({});
  const [IssueData, setIssueData] =  useState([]);
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  let navigate = useNavigate()
   let LibraryViewData = location?.state?.LibraryViewData;
   console.log("LibraryViewData", LibraryViewData);

   const FetchLibraryData = async (LibraryViewData) => {
    setLoading(true);

    const url = `${BaseURL}/lmember/lmemberView/${LibraryViewData?.studentID}`;

    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res?.lmember) {
        setLibrarymemberdata(res.lmember);
      }
      if (res && res?.issues) {
        setIssueData(res?.issues);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialLibraryViewData = { studentID: LibraryViewData?.studentID };
    FetchLibraryData(initialLibraryViewData);
  }, []);

console.log("Librarymemberdata", Librarymemberdata);
console.log("Issuememberdata", IssueData);
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item ms-4">
            <i className="fa fa-laptop"></i>
            <Link to="/"> Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/staff"> Staff </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View
          </li>
        </ol>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div id="printablediv">
                <section className="panel">
                  <div className="profile-view-head">
                    <a href="#">
                    <img style={{width:"24px"}} src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png" />
                    </a>

                    <h1><b> {LibraryViewData?.name}</b></h1>
                    <p><b>Class: {}</b> </p>
                  </div>
                  <div className="panel-body profile-view-dis">
                    <h1>Personal Information</h1>
                    <div className="row">
                      <div className="profile-view-tab">
                        <p>
                          <span>Register NO </span>: <b>{LibraryViewData?.registerNO}</b>
                          
                        </p>
                      </div>

                      <div className="profile-view-tab">
                        <p>
                          <span>Roll </span>:{LibraryViewData?.roll}
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Section </span>:  {LibraryViewData?.sectionID}
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Library ID </span>: K02
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Library Fee </span>: 0.00
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Join Date </span>: <b>{LibraryViewData?.doj}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Date of Birth </span>: <b>{LibraryViewData?.dob}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Gender </span>:<b> {LibraryViewData?.sex}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Blood Group </span>:{" "}
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Religion </span>:{" "}
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Email </span>:{" "}
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Phone </span>: 7297879578
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Address </span>:{" "}
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>State </span>:{" "}
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Country </span>:{" "}
                        </p>
                      </div>
                    </div>

                    <h1>Book Issue History</h1>

                    <div className="row">
                      <div className="col-sm-12">
                        <div id="hide-table">
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Book</th>
                                <th>Author</th>
                                <th>Serial No</th>
                                <th>Issue Date</th>
                                <th>Due Date</th>
                                <th>Return Date</th>
                              </tr>
                            </thead>
                            <tbody>
                      {IssueData &&
                        IssueData.map((u, i) => { 
                       return (
                      <tr>
                        <td>{i+1}</td>
                        <td>{u?.book}</td>
                        <td>{u?.author}</td>
                        <td>{u?.serial_no}</td>
                        <td>{u?.issue_date}</td>
                        <td>{u?.due_date}</td>
                        <td>{u?.return_date}</td>
                      
                      </tr>
                       ); 
                     })}
                    </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default View;
