import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
// import Breadcrumb from "../../Components/BreadCrumb/Breadcrumb";
import Loader from "../../../../Components/Loader/Loader";
import axios from "axios";
import { BaseURL, uniqueToken, schoolyearID } from "../../../../api/DataApis";
import request from "../../../../Apis/request";

function Duefees(props) {
  document.title = "Due Fees";
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [DueFeesData, setDueFeesData] = useState([]);

  const selectClassHandler = (e) => {
    setSelectedClassId(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `${BaseURL}/feecollection/dueFees`;
        if (selectedClassId) {
          url = `${BaseURL}/feecollection/dueFees/${selectedClassId}`;
        }
        const res = await request({
          url: url,
          method: "POST",
          data: JSON.stringify({
            schoolyearID: schoolyearID,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        setClasses(res.classes);
        setDueFeesData(res.students);
        console.log("DueFees_list", res.students); // Use res.advance directly here
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Call fetchData after selectedClassId is updated
    fetchData();
  }, [selectedClassId]);
  // useEffect depends on selectedClassId

  return (
    <>
      {/* <Breadcrumb title="Subject" /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div
                    className=" add"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto", marginLeft: "30px" }}
                      to="/duefees/add"
                    >
                      <i className="fa fa-plus"></i> Add Due Fees
                    </Link>
                    <select
                      style={{
                        position: "relative",

                        border: "1px solid",
                        borderRadius: "45px",
                        top: "5px",
                        width: "133px",
                        textAlign: "center",
                        fontWeight: "600",
                      }}
                      onChange={selectClassHandler}
                      name="classesID"
                      id="classesID"
                      className="form-control select2 select2-offscreen"
                      tabindex="-1"
                    >
                      <option value="0" selected="selected">
                        Select Class
                      </option>
                      <option value="-1">Select All</option>
                      {classes.map((classes) => {
                        return (
                          <option
                            key={classes.classesID}
                            value={classes.classesID}
                          >
                            {classes.classes}
                          </option>
                        );
                      })}
                    </select>{" "}
                  </div>
                  
                </div>
                <div>
                  <hr />
                </div>
                <h5>All Due Feesssss</h5>
              </div>
              <div className="card-body">
                {/* {loading && <Loader />} */}
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Enroll Id</th>
                        <th>Student Name</th>
                        <th>Class</th>
                        <th>ACA FEES Total</th>
                        <th>Deposited</th>

                        <th>Due Amount</th>
                        <th>Bus Fees</th>
                        <th>Deposited</th>
                        <th>Rebate</th>
                        <th>Due</th>
                        <th>Total Due</th>
                        <th>Action</th>

                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {DueFeesData &&
                        DueFeesData.map((u, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{u.registerNO}</td>
                              <td>{u.name}</td>
                              <td>
                                {" "}
                                {u &&
                                  u.classesID &&
                                  classes.find(
                                    (c) => c.classesID === u.classesID
                                  )?.classes}
                              </td>
                              <td></td>
                              <td>{u.totalPaid}</td>
                              <td>{u.totalDue}</td>

                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {/* <td>
                              
                                
                                  <i className="fa fa-eye view"></i>
                                
                                
                                  <i className="fa fa-trash delete"></i>
                                
                            </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Duefees;
