import React, { useState } from 'react';
import { BaseURL, uniqueToken } from '../../api/DataApis';

function Imports() {
  const [file, setFile] = useState(null);
  const [Books, setBooks] = useState(null);

  const handleFileChange = (e) => {
    console.log('File selected:', e.target.files[0]);
    setFile(e.target.files[0]);
  };
  const handleBooksChange = (e) => {
    console.log('File selected:', e.target.files[0]);
    setBooks(e.target.files[0]);
  };


  const headers = {
    Authorization: `Bearer ${uniqueToken}`,
  };

  const handleTeacher = async (e) => {
    e.preventDefault();
    console.log('Form submission triggered');

    if (!file) {
      alert('Please select a file');
      return;
    }
  

    const loginuserID = '3'; // Static login user ID
    const username = 'admin'; // Static username
    const usertype = 'admin'; // Static usertype

    const formData = new FormData();
    formData.append('csvFile', file);
    formData.append('loginuserID', loginuserID);
    formData.append('username', username);
    formData.append('usertype', usertype);

    console.log('Sending formData:', {
      file,
      loginuserID,
      username,
      usertype,
    });

    try {
      const response = await fetch(`${BaseURL}/bulkimport/teacher_bulkimport_data`, {
        method: 'POST',
        body: formData,
        headers: headers,
      });

      const responseData = await response.json();

      if (responseData.status === '200') {
        console.log('Response:', responseData);
        alert('File uploaded successfully');
      } else {
        alert('File upload failed');
        console.log('Response:', responseData);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while uploading the file');
    }
  };
  const handleBooks = async (e) => {
    e.preventDefault();
    console.log('Form submission triggered');

 
    if (!Books) {
      alert('Please select a file');
      return;
    }

    const loginuserID = '3'; // Static login user ID
    const username = 'admin'; // Static username
    const usertype = 'admin'; // Static usertype

    const formData = new FormData();
    formData.append('csvFile', Books);
    // formData.append('loginuserID', loginuserID);
    // formData.append('username', username);
    // formData.append('usertype', usertype);

    console.log('Sending formData:', {
      Books,
      // loginuserID,
      // username,
      // usertype,
    });

    try {
      const response = await fetch(`${BaseURL}/bulkimport/book_bulkimport_data`, {
        method: 'POST',
        body: formData,
        headers: headers,
      });

      const responseData = await response.json();

      if (responseData.status === '200') {
        console.log('Response:', responseData);
        alert('File uploaded successfully');
      } else {
        alert('File upload failed');
        console.log('Response:', responseData);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while uploading the file');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <h1 className="page-title"></h1>
            <div className="portlet light bordered">
              <div className="portlet-title">
                <div className="caption font-dark">
                  <h4 className="caption-subject bold uppercase mx-3">
                    Import Details
                  </h4>
                </div>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <form
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <div className="row px-3">
                            <label
                              htmlFor="file"
                              className="col-sm-2 control-label col-md-2"
                            >
                              Add Teacher &nbsp;
                              <i
                                className="fa fa-question-circle"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Download the teacher sample csv file first then see the format and make a copy of that file and add your data with exact format which is used in our csv file then upload the file."
                              ></i>
                            </label>

                            <div className="col-sm-4">
                              <div
                                className="input-group image-preview"
                                title=""
                              >
                                <input
                                  style={{ background: 'gray', height:"34px" }}
                                  type="text"
                                  className="form-control image-preview-filename"
                                  disabled
                                  value={file ? file.name : ''}
                                
                                />
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    className="btn btn-default image-preview-clear"
                                    style={{ display: 'none' }}
                                  >
                                    <span className="fa fa-remove"></span>Clear
                                  </button>
                                  <div
                                    className="btn btn-success image-preview-input"
                                    style={{ marginLeft: '-15px' }}
                                  >
                                    <span className="fa fa-repeat"></span>
                                    <span className="image-preview-input-title">
                                      File Browse
                                    </span>
                                    <input
                                      type="file"
                                      accept=".csv"
                                      onChange={handleFileChange}
                                    />
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="col-md-2 col-sm-2 rep-mar">
                              <input
                                type="submit"
                                className="btn btn-success"
                                onClick={handleTeacher}
                                value="Import"
                              />
                            </div>
                            <div className="col-md-3">
                              <a
                                className="btn btn-info"
                                href="http://localhost/schoolcode/assets/csv/sample_teacher.csv"
                              >
                                <i className="fa fa-download"></i> Download Sample
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                      <form
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <div className="row px-3">
                            <label
                              htmlFor="file"
                              className="col-sm-2 control-label col-md-2"
                            >
                              Add Books &nbsp;
                              <i
                                className="fa fa-question-circle"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Download the teacher sample csv file first then see the format and make a copy of that file and add your data with exact format which is used in our csv file then upload the file."
                              ></i>
                            </label>

                            <div className="col-sm-4">
                              <div
                                className="input-group image-preview"
                                title=""
                              >
                                <input
                                  style={{ background: 'gray', height:"34px" }}
                                  type="text"
                                  className="form-control image-preview-filename"
                                  disabled
                                  value={Books ? Books.name : ''}
                                />
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    className="btn btn-default image-preview-clear"
                                    style={{ display: 'none' }}
                                  >
                                    <span className="fa fa-remove"></span>Clear
                                  </button>
                                  <div
                                    className="btn btn-success image-preview-input"
                                    style={{ marginLeft: '-15px' }}
                                  >
                                    <span className="fa fa-repeat"></span>
                                    <span className="image-preview-input-title">
                                      File Browse
                                    </span>
                                    <input
                                      type="file"
                                      accept=".csv"
                                      onChange={handleBooksChange}
                                    />
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="col-md-2 col-sm-2 rep-mar">
                              <input
                                type="submit"
                                className="btn btn-success"
                                onClick={handleBooks}
                                value="Import"
                              />
                            </div>
                            <div className="col-md-3">
                              <a
                                className="btn btn-info"
                                href="http://localhost/schoolcode/assets/csv/sample_teacher.csv"
                              >
                                <i className="fa fa-download"></i> Download Sample
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Imports;
