import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";

const Edit = (props) => {
 

  document.title = "Classes | Edit";
  const [shift, setShift] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [Classes, setClasses] = useState([]);
  const [ClassNumeric, setClassNumeric] = useState([]);
  const [teacherID, setTeacherID] = useState("");
  const [Note, setNote]= useState([]);
  const [loading, setLoading] = useState(false);
  const [SelectedShift, setSelectedShift] = useState([]);

  const [upclass, setUpclass] = useState({
    
    classes_numeric: "",
    shiftID: "",
    teacherID: "",
    note: "",
    schoolyearID: schoolyearID,
  });

  useEffect(() => {
   
      // const response = await axios.post(`${BaseURL}/classes/addClasses`);
      // const data = await response.data;
      setClasses(Classdata?.classes);
      setSelectedShift(Classdata?.shiftID);
      setTeachers(Classdata?.teachers);
      setClassNumeric(Classdata?.classes_numeric);
      setNote(Classdata?.note);
   
  }, []);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const Classdata = location.state.classesData;

  const updateClass = async (e) => {
    e.preventDefault();
    await fetch(`${BaseURL}/classes/editClasses/${Classdata.classesID}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uniqueToken}`,
      },
      body: JSON.stringify(


  {

    "classes" : Classes,
     "classes_numeric" : ClassNumeric,
      "shiftID" : SelectedShift, 
      "teacherID" :"100",
       "note" :"Hello"
    
    }


      ),
    }).then((result) => {
      result.json().then((resp) => {
        Swal.fire({
          icon: "success",
          title: "Updated!",
          text: "Data Updated Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/academic/class");
      });
    });
  };
const HandleClass = (e) => {
  setClasses(e.target.value);
}
const HandleShift = (e) => {
  setSelectedShift(e.target.value);
}
const HandleNote = (e) => {
  setNote(e.target.value);
}

const fetchShift = async () => {
  // props.setProgress(10);
  setLoading(true);
  await axios
    .get(`${BaseURL}/shift/getShift`)
    .then(async (response) => {
      // props.setProgress(30);
      const data = await response.data;
      // props.setProgress(80);
      setTimeout(() => {
        if (data) {
          setLoading(false);
          setShift(data.shift);
        }
        // props.setProgress(100);
      }, 1000);
    });
};

useEffect(() => {
  fetchShift();
}, []);


const fetchTeacher = async () => {
  // props.setProgress(10);
  setLoading(true);
  await axios
    .get(`${BaseURL}/classes/addClasses`)
    .then(async (response) => {
      // props.setProgress(30);
      const data = await response.data;
      // props.setProgress(80);
      setTimeout(() => {
        if (data) {
          setLoading(false);
          setTeachers(data?.teachers);
        }
        // props.setProgress(100);
      }, 1000);
    });
};

useEffect(() => {
  fetchTeacher();
}, []);

console.log("Classdata", Classdata);
console.log("shifts", shift);
console.log("teachers", teachers);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              {/* <h3 className="page-title">Notice Edit</h3> */}
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Edit Class Details
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        role="form"
                        method="post"
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="classes"
                              className="col-sm-2 control-label"
                            >
                              Class <span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="classes"
                                name="classes"
                                value={Classes}
                                onChange={HandleClass}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="classes_numeric"
                              className="col-sm-2 control-label"
                            >
                              Class Numeric <span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="classes_numeric"
                                name="classes_numeric"
                                value={ClassNumeric}
                                onChange=""
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="classesID"
                              className="col-sm-2 control-label"
                            >
                              Select Shift<span className="text-red"> *</span>
                            </label>
                            <div className="col-sm-6">
                              <div className="input-group">
                                <select
                                  name="shiftID"
                                  id="shiftID"
                                  className="form-control select2"
                                  value={SelectedShift}
                                  onChange={HandleShift}
                                >
                                  <option value="0">Select Shift</option>
                                
                                  {shift && shift?.map((shifts) => {
                                    return (
                                      <option
                                        key={shifts?.shiftID}
                                        value={shifts?.shiftID}
                                      >
                                        {shifts?.shift_name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="input-group-addon add-btn">
                                  <Link to="/shift/add" className="add-button">
                                    <i className="fa fa-plus"></i>
                                    Quick Add
                                  </Link>
                                </span>
                              </div>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Teacher Name
                            </label>
                            <div className="col-sm-6">
                              <select
                                name="teacherID"
                                id="teacherID"
                                className="form-control select2 select2-offscreen"
                                tabIndex="-1"
                                
                                
                              >
                                <option value="0">Select Teacher</option>
                              <option value="testing">Jayant choudhary</option>
                                  
                                   
                                   {teachers && teachers.map((teacher) => {
                                    return (
                                      <option
                                        key={teacher?.teacherID}
                                        value={teacher?.teacherID}
                                      >
                                        {teacher?.name}
                                      </option>
                                    );
                                  })}
                                   
                                   </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="note"
                              className="col-sm-2 control-label"
                            >
                              Note
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                className="form-control"
                                style={{ resize: "none" }}
                                id="note"
                                name="note"
                                value={Note}
                                onChange={HandleNote}
                              ></textarea>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              value="Update Class"
                              onClick={updateClass}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
