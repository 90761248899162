import React from "react";
import { Link } from "react-router-dom";
import "./View.css";
import { useLocation } from "react-router-dom";

function View(props) {
  let location = useLocation();
  const ViewData = location.state?.BusMasterViewData
  console.log("BusMasterViewData",ViewData);
  return (
    <>
  
   <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <h1 className="bus_master">Bus Master</h1>
            <hr className="hr_line12"/>
            <h3 className="Bus_no"> Bus :- <span>{ViewData?.busName}</span></h3>
            <h3 className="Bus_no"> Bus No :- <span>{ViewData?.busNo} </span></h3>

          <table>
            
          <div className="main_part_table_bus">
          <h3 className="Bus_no1">Bus Details</h3>
          <tr>
             <td><b>Bus Name</b></td>
             <td>{ViewData?.busName}</td>
            </tr>
            <tr>
             <td><b>Bus Start</b></td>
             <td>{ViewData?.busStart}</td>
            </tr>
            <tr>
             <td><b>Bus End</b></td>
             <td>{ViewData?.busEnd}</td>
            </tr>
            <tr>
             <td><b>Driver Name</b></td>
             <td>{ViewData?.busDriver}</td>
            </tr>
            <tr>
             <td><b>Bus Number</b></td>
             <td>{ViewData?.busNo}</td>
            </tr>
          </div>
          </table>
          </div>
        </div>
      </div>
    </div>
   </section>
    </>
  );
}

export default View;
