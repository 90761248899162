import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, uniqueToken } from "../../../api/DataApis";
import axios from "axios";

function BusRoute() {
  const [RouteData, setRouteData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shift, setShift] = useState([]);
  const [shiftMap, setShiftMap] = useState({});

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  const DateHandleChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    setDate(formattedDate);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year.slice(2)}`; // Extracting last 2 digits of the year
  };

  const [RouteDate, setDate] = useState(formattedToday);
  const apiEndpoint = `${BaseURL}/tmember/addTmember`;

  document.title = "Bus Route";

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: apiEndpoint,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response?.transports) {
        setRouteData(response?.transports);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchShift = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/shift/getShift`);
      const data = await response.data;
      if (data && data.shift) {
        setShift(data.shift);

        // Create a map of shiftID to shift_name
        const shiftMapping = data.shift.reduce((map, shift) => {
          map[shift.shiftID] = shift.shift_name;
          return map;
        }, {});
        setShiftMap(shiftMapping);
      }
    } catch (error) {
      console.error("Error fetching shift data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchShift();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div
                    className="col-sm-12 add"
                    style={{ marginTop: "11px", marginLeft: "-14px" }}
                  >
                    <form className="form-horizontal" role="form" method="post">
                      <div className="">
                        <div className="row">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div className="col-sm-2">
                              <label
                                htmlFor="routedate"
                                className="control-label mt-2"
                              >
                                <h5> Route Date</h5>
                              </label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                gap: "10px",
                                justifyContent:"flex-end"
                              }}
                            >
                              <div className="form-group col-sm-3">
                                <input
                                  type="date"
                                  placeholder="Select Date"
                                  className="form-control"
                                  id="routedate"
                                  name="routedate"
                                  onChange={DateHandleChange}
                                  style={{ marginTop: "-16px" }}
                                />
                              </div>
                              <div className="">
                                <button
                                  id="get_attendancereport"
                                  className="btn btn-success"
                                  style={{}}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="">
                                <Link
                                  to={"/transport/busroute/monthly"}
                                  style={{ textDecoration: "none" }}
                                >
                                  <button
                                    className="btn btn-success"
                                    style={{}}
                                  >
                                    Monthly Route
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>All Bus Route</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Bus Name</th>
                        <th>Device ID</th>
                        <th>Route Name</th>
                        <th>Route Distance</th>
                        <th>Shift</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {RouteData &&
                        RouteData.map((u, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{u?.bus_name}</td>
                            <td>{u?.deviceid}</td>
                            <td>{u?.routename}</td>
                            <td></td>
                            <td>
                              {shiftMap[u?.shiftID]
                                ? shiftMap[u.shiftID]
                                : "None"}
                            </td>{" "}
                            {/* Render shift name */}
                            <td className="text-center">
                              <Link
                                to={`/transport/busroute/view/${u?.transportID}/${u?.deviceid}/${RouteDate}`}
                                state={{ BusRouteViewData: u, RouteDate }}
                              >
                                <i className="fa fa-eye view"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusRoute;
