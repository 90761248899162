import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";

function Edit(props) {
  document.title = "Event | Edit";
  let navigate = useNavigate();
  const redirect = () => {
    // let path = `/advance`;
  };
  let location = useLocation ();
  let  GalleryEditData = location.state.GalleryEditData;
  console.log("GalleryEditData", GalleryEditData);


  
  useEffect(() => {
    setTitleName(GalleryEditData?.title);
    setDate(GalleryEditData?.date);
    setPhoto(GalleryEditData?.photo);
    setDetails(GalleryEditData?.des);
    // setSelectClass(HolidayData?.classesID);
    // setStudents(NoticeEditData?.routename);
    
   
    // setToDate(HolidayData?.tdate)
   
    // setDetails(HolidayData?.details);
    }, []);

  const [TitleName, setTitleName] = useState('');
  const [loading, setLoading] = useState(false);
  const [Date, setDate] = useState('');
  const [Photo, setPhoto] = useState('');
  const [Details, setDetails] = useState('');

  const HandleTitleChange = (e) => {
    setTitleName(e.target.value);
  }

  const handleDateChange = (e) => {
    setDate(e.target.value);
  }

  const handlePhotoChange = (e) => {
    setPhoto(e.target.value);
  }

  const handleDetailchange = (value) => {
    setDetails(value);
  }

  console.log("eventdetails", Details);

  const EditGallery = async () => {
    let response = await request({
      url: `${BaseURL}/gallery/galleryEdit/${GalleryEditData?.pres_ID}/${schoolyearID}`,
      method: "POST",
      data: JSON.stringify({
        "title": TitleName,
        "date": Date,
        "file": Photo,
        "notice": Details,
        "usertypeID" : "13",
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });

    if (response.status == "200") {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Gallery Updated Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate(`/annoucement/gallery`);
    } 
  }

  useEffect(() => {
    // If you intend to call AddEvent() on component mount, ensure the necessary data is ready
    // AddEvent();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Gallery Edit
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      <button className="btn btn-success" onClick={redirect}>
                        Back
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{marginTop:"-10px"}} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{marginTop:"-20px"}}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Title
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={HandleTitleChange}
                                className="form-control"
                                id="name_id"
                                name="name"
                                value={TitleName}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Date
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="date"
                                onChange={handleDateChange}
                                className="form-control"
                                id="hostelType"
                                name="HostelType"
                                value={Date}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Photo
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="file"
                                onChange={handlePhotoChange}
                                value={Photo}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Details
                            </label>
                            <div className="col-sm-6">
                              <ReactQuill
                                value={Details}
                                onChange={handleDetailchange}
                        
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick={EditGallery}
                              value="Update Gallery"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
