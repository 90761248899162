import React, { useEffect, useState } from "react";
import "./Add.css"; // Import the CSS file for styling
import { BaseURL, uniqueToken, schoolyearID } from "../../api/DataApis";
import request from "../../Apis/request";
import { Link } from "react-router-dom";
import Breadcrumb from "../../Components/BreadCrumb/Breadcrumb";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Add = () => {
  const [step, setStep] = useState(1);
  const [classes, setClasses] = useState([]);
  const [staff, setStaff] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [BoardName, setBoardName] = useState([]);
  const [State, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    state: "",
    mobile_number: "",
    contact_person: "",
    relation: "",
    father_number: "",
    m_number: "",
    previous_sname: "",
    previous_class: "",
    percentage: "",
    medium: "",
    board: "",
    reference_name: "",
    reference_remark: "",
    hostel: "",
    transport: "",
    gender: "",
    email: "",
    enquiry_type: "",
    dob: "",
    remark: "",
    fee_details: "",
    course_fee: "",
    Hostel_fee: "",
    transport_fee: "",
    total_fee: "",
    scholarship: "",
    extra_scholarship: "",
    scholarship_reason: "",
    hostel_facility: "",
    transport_stoppage: "",
    enquiry_status: "",
    close_reason: "",
    reminder_date: "",
    enquiry_class: "",
    schoolyearID: schoolyearID,
    userID : "1",
    usertype :"Admin",
    username : "test",
    loginuserID : "3",
    sectionID : 123,
    religion : "hindu",
  bloodgroup : "A+",
     aadhar :  "544444444444444",
     classesID : "63",
     registerNO : "9870"
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${BaseURL}/enquiry/addEnquiries`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (response.status === 200 && data.status === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: data.message || "Enquiry submitted successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
  
        // Navigate to the desired page
        navigate(`/enquire/enquire`);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: data.message || "Failed to submit enquiry.",
          showConfirmButton: true,
        });
  
        console.error("Failed to submit enquiry:", response.statusText);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "An error occurred while submitting the enquiry.",
        showConfirmButton: true,
      });
  
      console.error("An error occurred:", error);
    }
  };

  
  
  const apiEndpoint = `${BaseURL}/classes/getClasses`;
  const AddApi = `${BaseURL}/enquiry/addEnquiries`;
  const fetchData = async () => {
    var url = apiEndpoint;

    let res = await request({
      url: url,
      // url: 'posts',
      method: "GET",

      headers: {
        "Content-type": "application/json; charset=UTF-8",
        // Authorization: `Bearer ${uniqueToken}`,
      },
    });
    console.log("res", res);
    if (res && res.classes) {
      setClasses(res.classes);
    }

    return [];
  };
  useEffect(() => {
    fetchData();
  }, []);
  const apiCountryApi = `${BaseURL}/dashboard/getDash`;

  const fetchDataaa = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiCountryApi);
      const data = response.data;

      if (data && data.country) {
        const countryNames = data.country.map((countryObj) => {
          return Object.values(countryObj)[0];
        });
        setCountryName(countryNames);
      }

      if (data && data?.state) {
        const stateNames = data.state.map((stateObj) => {
          return Object.values(stateObj)[0];
        });
        setState(stateNames);
      }

      // Assuming board names are available in the same data structure as country names
      if (data && data.board) {
        const boardNames = data.board.map((boardObj) => {
          return Object.values(boardObj)[0];
        });
        setBoardName(boardNames);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error (e.g., show error message to the user)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataaa();
  }, []);
  const stepLabels = [
    "Basic Details",
    "Address and Contact",
    "Activity Log",
    "Review & Submit",
  ];

  return (
    <div className="main">
      <div className="multi-step-form">
        <div className="progress-bar">
          {stepLabels.map((label, index) => (
            <div
              key={index + 1}
              className={`step ${step === index + 1 ? "active" : ""} ${
                step > index + 1 ? "completed" : ""
              }`}
              onClick={() => setStep(index + 1)}
            >
              <div className={`circle ${step > index + 1 ? "completed" : ""}`}>
                {step > index + 1 ? "✔" : index + 1}
              </div>
              <div className="label">{label}</div>
              {index < stepLabels.length - 1 && (
                <div
                  className={` ${step > index + 1 ? "completed" : ""}`}
                ></div>
              )}
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit}>
        <div className="form-content">
          {step === 1 && (
            <>
              {/* Step 1 fields */}
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Form/Token No <span className="text-red"></span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="token_number" value={formData.token_number}
                    name="token_number"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Name <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="name" value={formData.name}
                    name="name"
                  />

                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="Gender">
                    Gender <span className="text-red"></span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="gender" value={formData.gender}
                    name="gender"
                  >
                    <option value="0">select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option>others</option>
                  </select>
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Date of Birth <span className="text-red">*</span>
                  </label>
                  <input
                    type="date"
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="dob" value={formData.dob}
                    name="dob"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">Father's Name</label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="father_name" value={formData.father_name}
                    name="father_name"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Mother Name <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="mother_name" value={formData.mother_name}
                    name="mother_name"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Reference <span className="text-red"></span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="reference_name" value={formData.reference_name}
                    name="reference_name"
                  >
                    <option>select reference</option>
                    <option>staff</option>
                    <option>student</option>
                    <option>other</option>
                  </select>
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Reference Remark <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="reference_remark" value={formData.reference_remark}
                    name="reference_remark"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label htmlFor="name_id">
                      Enquiry for Class <span className="text-red"></span>
                    </label>
                    <select
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      id="enquiry_class" value={formData.enquiry_class}
                      name="enquiry_class"
                    >
                      <option value="">Select Class</option>

                      {classes.map((classes) => {
                        return (
                          <option
                            key={classes.classesID}
                            value={classes.classesID}
                          >
                            {classes.classes}
                          </option>
                        );
                      })}
                    </select>
                    <span className="col-sm-4 control-label"></span>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name_id">
                      Enquire Type <span className="text-red">*</span>
                    </label>
                    <select
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      id="enquiry_type" value={formData.enquiry_type}
                      name="enquiry_type"
                    >
                      <option value="">select enquire type</option>
                      <option value="new_enquiry">new enquiry</option>
                      <option value="regular_enquiry">regular enquiry</option>
                    </select>
                    <span className="col-sm-4 control-label"></span>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name_id">
                      Is Hostel Required? <span className="text-red">*</span>
                    </label>
                    <select
                      onChange={(e) => handleChange(e)}
                      
                      className="form-control"
                      id="hostel" value={formData.hostel}
                      name="hostel"
                    >
                      <option value="">select option</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <span className="col-sm-4 control-label"></span>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="name_id">Is Transport Required?</label>
                    <select
                      onChange={(e) => handleChange(e)}
                      type="text"
                      className="form-control"
                      id="transport" value={formData.transport}
                      name="transport"
                    >
                      <option value="">select option</option>
                      <option value="1">yes</option>
                      <option value="0">no</option>
                    </select>
                    <span className="col-sm-4 control-label"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">Previous School Name</label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="previous_sname" value={formData.previous_sname}
                    name="previous_sname"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Previous Class <span className="text-red"></span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="previous_class" value={formData.previous_class}
                    name="previous_class"
                  >
                    <option>select class</option>
                    {classes.map((classes) => {
                      return (
                        <option
                          key={classes.classesID}
                          value={classes.classesID}
                        >
                          {classes.classes}
                        </option>
                      );
                    })}
                  </select>
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="percentage">
                    Percentage <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="percentage" value={formData.percentage}
                    name="percentage"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="medium">
                    Medium <span className="text-red">*</span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="medium" value={formData.medium}
                    name="medium"
                  >
                    <option>select medium</option>
                    <option>Hindi Medium</option>
                    <option>English Medium</option>
                    <option>Punjabi Medium</option>
                  </select>
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              {/* Add more fields as needed */}
            </>
          )}
          {step === 2 && (
            <>
              {/* Step 2 fields */}
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Address <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    District <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="district"
                    name="district"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="locality/tehsil">
                    Locality/Tehsil <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="locality"
                    name="locality"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="Area/village">
                    Area/Village <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="area"
                    name="area"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="pin">Pin</label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="number"
                    className="form-control"
                    id="pin"
                    name="pin"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
           

                <div className="col-md-3">
                  <label htmlFor="country">
                    Country <span className="text-red"></span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="country"
                    name="country"
                  >
                    <option key="default" value="">
                      Select country
                    </option>
                    {countryName?.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>

                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="state">
                    State <span className="text-red"></span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="state"
                    name="state"
                  >
                    <option>select state</option>
                    {State?.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="mobile">
                    Mobile <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="mobile_number"
                    name="mobile_number"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="name_id">Contact Person</label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="contact_person"
                    name="contact_person"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="f_number">
                    Father's Mobile No <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="father_number"
                    name="father_number"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Mother's Mobile No <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="number"
                    className="form-control"
                    id="m_number"
                    name="m_number"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Email <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Relation <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="relation"
                    name="relation"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">Board/College</label>

                  <select
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="board"
                    name="board"
                  >
                    <option key="default" value="">
                      Select board
                    </option>
                    {BoardName?.map((board, index) => (
                      <option key={index} value={board}>
                        {board}
                      </option>
                    ))}
                  </select>
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Enquiry Status <span className="text-red"></span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="enquiry_status"
                    name="enquiry_status"
                  >
                    <option>select enquiry status</option>
                    <option value="open">OPEN</option>
                    <option value="intrested">INTRESTED</option>
                    <option value="progress">IN PROGRESS</option>
                    <option value="short_listed">SHORT LISTED</option>
                    <option value="enrolled">ENROLLED</option>
                    <option></option>
                  </select>
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              {/* Add more fields as needed */}

            </>
          )}
          {step === 3 && (
            <>
              {/* Step 3 fields */}
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name_id">
                    Remark* <span className="text-red"></span>
                  </label>
                  <textarea
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="remark"
                    name="remark"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-6">
                  <label htmlFor="pin">Fees Detail</label>
                  <textarea
                    onChange={(e) => handleChange(e)}
                    type="number"
                    className="form-control"
                    id="fee_details"
                    name="fee_details"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Course Fees <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="course_fee"
                    name="course_fee"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="locality/tehsil">
                    Hostel Fees <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="Hostel_fee"
                    name="Hostel_fee"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="Area/village">
                    Transport Fees<span className="text-red">*</span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="transport_fee"
                    name="transport_fee"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="pin">Total Fees</label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="number"
                    className="form-control"
                    id="total_fee"
                    name="total_fee"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Scholarship(if any) <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="scholarship"
                    name="scholarship"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="locality/tehsil">
                    Extra Scholarship (if any){" "}
                    <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="extra_scholarship"
                    name="extra_scholarship"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="Area/village">
                    Extra Scholarship Reason <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="scholarship_reason"
                    name="scholarship_reason"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="locality/tehsil">
                    Assign To<span className="text-red"></span>
                  </label>
                  <select
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    id="assign_to"
                    name="assign_to"
                  >
                    <option value="">Select staff</option>

                    {staff.map((i) => {
                      return (
                        <option key={i.teacherID} value={i.teacherID}>
                          {i.name}
                        </option>
                      );
                    })}
                  </select>

                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="name_id">
                    Hostel Facility <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="hostel_facility"
                    name="hostel_facility"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>

                <div className="col-md-3">
                  <label htmlFor="locality/tehsil">
                    Transport Stoppage
                    <span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="transport_stoppage"
                    name="transport_stoppage"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="locality/tehsil">
                    Reminder Date<span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="date"
                    className="form-control"
                    id="reminder_date"
                    name="reminder_date"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
                <div className="col-md-3">
                  <label htmlFor="locality/tehsil">
                    Close Reason<span className="text-red"></span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control"
                    id="close_reason"
                    name="close_reason"
                  />
                  <span className="col-sm-4 control-label"></span>
                </div>
              </div>
              {/* Add more fields as needed */}

            </>
          )}
          {step === 4 && (
            <>
             <div className="review-section">
  <h3>Review Your Details</h3>
  <div className="row">
    <div className="col-md-6">
      <p><strong>Name:</strong> {formData?.name}</p>
      <p><strong>Father Name:</strong> {formData?.father_name}</p>
      <p><strong>Mother Name:</strong> {formData?.mother_name}</p>
      <p><strong>Gender:</strong> {formData?.gender}</p>
      
      <p><strong>Father contact:</strong> {formData?.father_number}</p>
      <p><strong>Mother contact:</strong> {formData?.m_number}</p>
      <p><strong>Previous School:</strong> {formData?.previous_sname}</p>
      <p><strong>Previous Class:</strong> {formData?.previous_classs}</p>
      <p><strong>Address:</strong> {formData?.address}</p>
      <p><strong>Locality:</strong> {formData?.locality}</p>
      <p><strong>District:</strong> {formData?.district}</p>
      <p><strong>State:</strong> {formData?.state}</p>
      <p><strong>Country:</strong> {formData?.country}</p>
      <p><strong>Pin:</strong> {formData?.pin}</p>
      <p><strong>Religion:</strong> {formData?.religion}</p>
      <p><strong>Percentage:</strong> {formData?.percentage}</p>
      <p><strong>Reference Name:</strong> {formData?.reference_name}</p>
      <p><strong>Reference Remark:</strong> {formData?.reference_remark}</p>
    </div>
    <div className="col-md-6">
      <p><strong>Remark:</strong> {formData?.remark}</p>
      <p><strong>Hostel Facility:</strong> {formData?.hostel_facility}</p>
      <p><strong>Assign to:</strong> {formData?.assign_toss}</p>
      <p><strong>Board:</strong> {formData?.board}</p>
      <p><strong>Course Fee:</strong> {formData?.course_fee}</p>
      <p><strong>Email:</strong> {formData?.email}</p>
      <p><strong>Transport Fee:</strong> {formData?.transport_fee}</p>
      <p><strong>Transport Stoppage:</strong> {formData?.transport_stoppage}</p>
      <p><strong>Scholarship:</strong> {formData?.scholarship}</p>
      <p><strong>Scholarship Reason:</strong> {formData?.scholarship_reason}</p>
      <p><strong>Medium:</strong> {formData?.medium}</p>
      <p><strong>Mobile:</strong> {formData?.mobile_number}</p>
      <p><strong>Contact Person:</strong> {formData?.contact_person}</p>
      <p><strong>Relation:</strong> {formData?.relation}</p>
      <p><strong>Fees Detail:</strong> {formData?.fee_details}</p>
      <p><strong>Enquiry:</strong> {formData?.enquiry_type}</p>
      <p><strong>Extra scholarship:</strong> {formData?.extra_scholarship}</p>
    </div>
  </div>
</div>

            </>
          )}

          <div className="form-buttons">
            {step > 1 && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleBack}
              >
                Back
              </button>
            )}
            {step < 4 ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNext}
              >
                Next
              </button>
            ) : (
              <input
                type="submit"
                className="btn btn-success"
                
              />
               
            )}
          </div>
        </div>
        </form>
      </div>
    </div>
  );
};

export default Add;
