import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import request from "../../../Apis/request";
// import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";

function AcademicAdd(props) {
  document.title = "Academic Year | Add";
  let navigate = useNavigate();
  


  //   const redirect = () => {
  //     let path = `/advance`;
  //     navigate(path);
  //   };

  const [options, setOptions] = useState([""]);
  const [schoolyear, setSchoolyear] = useState([]);
  const [schoolyeartitle, setschoolyeartitle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryListing, setCategoryListing] = useState([]);

  const handleSchoolyearTitle = (e) => {
    setschoolyeartitle(e.target.value)
  };
    const handleSchoolYear = (e) => {
      setSchoolyear(e.target.value)
    };



  const AddAcademicYear = async () => {

    let response = await request({
      url: `${BaseURL}/schoolyear/addSchoolYear`,
      // url: 'posts',
      method: "POST",
      data: JSON.stringify({
        // "feetype_name" : FeeType,
        "school_type" : "classbase",
        "schoolyear" : schoolyear,
        "schoolyeartitle" : schoolyeartitle,
        "loginuserID" : "1",
        "username" : "ryan_admin",
        "usertype" : ""
        // "schoolyearID" : Address,
        // "note": Note
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });

    if (response.status == "200") {
      Swal.fire({
        icon: "success",
        title: "success!",
        text: "Academic Year Added Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/Administrator/academicyear');
   
    }
    

  }






  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Academic Add
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      {/* <button className="btn btn-success" onClick={redirect}> */}
                      {/* Back */}
                      {/* </button> */}
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{ marginTop: "-10px" }} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{ marginTop: "-20px" }}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Year
                            </label>
                            <div className="col-sm-6">
                              <input
            
                                className="form-control"
                                id="name_id"
                                name="name"
                                onChange = {handleSchoolYear}
                            
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Year Title
                            </label>
                            <div className="col-sm-6">
                              {/* <input
                                className="form-control"
                                type="text"
                              /> */}
                              <input
                                type="text"
                                className="form-control"
                                onChange={handleSchoolyearTitle}
                               

                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              // onClick={EditHostel}
                              value="Add  Year"
                              onClick = {AddAcademicYear}
                            
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AcademicAdd;
