import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseURL, schoolyearID, uniqueToken } from "../../api/DataApis";
import request from "../../Apis/request";
import Swal from "sweetalert2";

function Reset_password() {
  document.title = "Password || Reset";
  const [loading, setLoading] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState("0");
  const [UserData, setUserData] = useState([]);
  const [Usernames, setUsernames] = useState([]);
  const [selectedUsernames, setselectedusernames] = useState([]);
  const [Password, setPassword] = useState([]);
  const [ConfirmPassword, setConfirmPassword] = useState([]);
  const [tableID, setTableID] = useState("");

  let navigate = useNavigate();

  const apiUserpoint = `${BaseURL}/resetpassword/userList`;

  const fetchUserData = async () => {
    setLoading(true);
    let res = await request({
      url: apiUserpoint,
      method: "POST",
      data: JSON.stringify({ schoolyearID: schoolyearID }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });
    if (res && res?.usertypes) {
      setUserData(res?.usertypes);
    }
    setLoading(false);
  };

  const fetchUsernames = async (userID) => {
    setLoading(true);
    let res = await request({
      url: `${BaseURL}/resetpassword/usernameList`,
      method: "POST",
      data: JSON.stringify({
        
        users: userID }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });
    if (res && res?.usernames && res?.tableID) {
      setUsernames(res?.usernames);
      setTableID(res?.tableID); // Set the tableID state
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleUserChange = (e) => {
    const selectedUserID = e.target.value;
    setSelectedUserID(selectedUserID);
    if (selectedUserID !== "0") {
      fetchUsernames(selectedUserID);
    } else {
      setUsernames([]);
    }
  };
const handleuserListing = (e) => {
  setselectedusernames(e.target.value);
}

const handlePassword = (e) => {
  setPassword(e.target.value);
}

const handleConfirmPassword = (e) => {
  setConfirmPassword(e.target.value);
}

const   AddPassword = async () => {

  let response = await request({
    url: `${BaseURL}/resetpassword/changePassword`,
    // url: 'posts',
    method:"POST",
    data: JSON.stringify({
      // "feetype_name" : FeeType,
      "users" : selectedUserID,
      "username" : selectedUsernames, // Enter userID
      "new_password" : Password,
      "re_password" : ConfirmPassword,
      //  "classesID" : selectClass
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${uniqueToken}`,
    }
  });

   if( response.status == "200" ) {
    Swal.fire({
      icon: "success",
      title: "success!",
      text: "Password Updated Successfully...",
      showConfirmButton: false,
      timer: 1500,
    });
    // var receiptID = response.receiptID;
    // var schoolyearID = response.schoolyearID;
    // var urll = `${BaseURL}/feecollection/printPreview/${schoolyearID}/${receiptID}`;
navigate(`/`);
    // window.location.href = urll;
    // fetchData();
  } 
      
   
}



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Reset-password
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3"></h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{ marginTop: "-12px" }} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        role="form"
                        method="post"
                        style={{ marginTop: "-23px" }}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="userID"
                              className="col-sm-2 control-label"
                            >
                              User
                            </label>
                            <div className="col-sm-6">
                              <div className="input-group">
                                <select
                                  name="userID"
                                  id="userID"
                                  className="form-control select2"
                                  onChange={handleUserChange}
                                  value={selectedUserID}
                                >
                                  <option value="0">Select user</option>
                                  {UserData &&
                                    UserData.map((user) => (
                                      <option
                                        key={user?.usertypeID}
                                        value={user?.usertypeID}
                                      >
                                        {user?.usertype}
                                      </option>
                                    ))}
                                </select>
                                <span className="btn btn-success">
                                  <Link
                                    to="/shift/add"
                                    type="button"
                                    className="add-button"
                                  >
                                    <i className="fa fa-plus"> </i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="username"
                              className="col-sm-2 control-label"
                            >
                              User Name
                            </label>
                            <div className="col-sm-6">
                              <select
                                name="username"
                                id="username"
                                className="form-control select2"
                                onChange={handleuserListing}
                              >
                                <option value="0">Select username</option>
                                {Usernames &&
                                  Usernames.map((username, index) => (
                                    <option key={index} value={username[tableID]}>
                                      {username?.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="new_password"
                              className="col-sm-2 control-label"
                            >
                              New password
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="password"
                                className="form-control"
                                id="new_password"
                                name="new_password"
                                onChange={handlePassword}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="re_password"
                              className="col-sm-2 control-label"
                            >
                              Re-Password
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="password"
                                className="form-control"
                                id="re_password"
                                name="re_password"
                                onChange={handleConfirmPassword}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick ={AddPassword}
                              value="submit"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reset_password;
