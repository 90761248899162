import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../Components/BreadCrumb/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {getStView} from "../../Apis/StView";
import axios from "axios";
import request from "../../Apis/request";
import { useParams  } from "react-router-dom";
import image from "../../assets/images/dummy_pic.jpeg";

const StaffView = (props) => {
  document.title = "Staff |  View";
  const [studentss, setStudentss] = useState({});
  const params = useParams();
  let teacherID = params.tid;
  console.log("teacher_id", teacherID );
  let location = useLocation()
  let staffs = location?.state?.staffData
   console.log("staffs", staffs);

  return (
    <>
    {/* <Breadcrumb title="Staff" path="/staff" subtitle="View" /> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div id="printablediv">
                <section className="panel">
                  {/* <h4 className="mx-3">Student Details</h4>
                    <hr /> */}
                  <div className="profile-view-head">
                    <a href="#">
                      <img
                        className="imagev"
                        src={image}
                        alt=""
                      />
                    </a>

                    
                    <p><span> <b>{staffs?.name}</b></span></p>
                  </div>
                  <div className="panel-body profile-view-dis">
                    <h3>Personal Information</h3>
                    <div className="row">
                    <div className="profile-view-tab">
                        <p>
                          <span>Designation</span>:<b> {staffs?.designation}</b>
                        </p>
                      </div>

                      <div className="profile-view-tab">
                        <p>
                          <span>Date of Birth</span>: <b>{staffs?.dob}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Date of joining </span>: <b>{staffs?.jod}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Date of Leaving </span>: <b>{staffs?.dol}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Gender </span>:<b>{staffs?.sex}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Religion </span>:<b> {staffs?.religion}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Email </span>:<b>{staffs?.email}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Phone</span>:<b>{staffs?.phone}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Address </span>:<b>{staffs?.address}</b>
                        </p>
                      </div>
                      {/* <div className="profile-view-tab">
                      <p>
                          <span>State </span>:{studentss?.adddress}
                        </p>
                      </div> */}
                      <div className="profile-view-tab">
                      <p>
                      <span>Username </span>:<b> {staffs?.username}</b></p>
                      </div>
                     
                  
                   
                      {/* <!--<div className="profile-view-tab">-->
<!--                        <p><span>Date of Admission </span>: </p>-->
<!--                    </div>-->
<!--<div className="profile-view-tab">-->
<!--                        <p><span>Date of Leaving </span>: </p>-->
<!--                    </div>-->
<!--<div className="profile-view-tab">-->
<!--                        <p><span>Remarks </span>: </p>-->
<!--                    </div>--> */}
                      {/* </div> */}

                      <h3>Account Details</h3>

                      <div className="row">
                        <div className="profile-view-tab">
                          <p>
                            <span>Bank Name </span>: <b>{staffs?.bankname}</b>
                          </p>
                        </div>
                        <div className="profile-view-tab">
                          <p>
                            <span>Bank Account </span>:<b> {staffs?.bankaccount}</b>
                          </p>
                        </div>
                        <div className="profile-view-tab">
                          <p>
                            <span>IFSC Code </span>:
                          </p>
                        </div>
                        <div className="profile-view-tab">
                          <p>
                            <span>PF Number </span>: <b>{staffs?.pfnumber}</b>
                          </p>
                        </div>
                        <div className="profile-view-tab">
                          <p>
                            <span>ESI - IP/REG. No </span>:
                          </p>
                        </div>
                        <div className="profile-view-tab">
                          <p>
                            <span>Aadhar Number </span>: <b>{staffs?.aadharnumber}</b>
                          </p>
                        </div>
                      
                      <div className="profile-view-tab">
                          <p>
                            <span>policy number </span>:<b>{staffs?.policynumber}</b>
                          </p>
                        </div>
                        
                        <div className="profile-view-tab">
                          <p>
                            <span>Date of Increment/Appraisal</span>:<b>{staffs?.doi}</b>
                          </p>
                        </div>
                        <div className="profile-view-tab">
                          <p>
                            <span>Increment</span>:<b>{staffs?.ssmid}</b>
                          </p>
                        </div>
                        </div>
                        </div>
                        <h3>Earning Details:</h3>
                        <h3>Deduction Details:</h3>
                      {/* <h3>Account Details </h3>
                      <div className="row">
                        <div className="profile-view-tab">
                          <p>
                            <span>Aadhar number </span>:{studentss?.aadhar}
                          </p>
                        </div>
                        <div className="row">
                       
                        <div className="profile-view-tab">
                          <p>
                            <span>Family SSSM ID </span>:112
                          </p>
                        </div>
                        <div className="profile-view-tab">
                          <p>
                            <span>Family SSSM ID </span>:112
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffView;
