import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";


function Edit(props) {
  document.title = "Notice | Add";
  let navigate = useNavigate();
  let location = useLocation();
  const redirect = () => {
    // let path = `/advance`;

  };

let NoticeEditData = location.state.NoticeEditData;

console.log("NoticeEditData", NoticeEditData);





  const [Title, setTitle] = useState("");
  const [classs, setClasss] = useState([]);
  const [selectClass, setSelectClass] = useState([]);
  const [Students, setStudents] = useState([]);
  const [Date, setDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Notice, setNotice] = useState([]);
  const [File, setFile] = useState([]);
  // const [selectedHostelType, setSelect edhoste] = useState("");
 
  
  // const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState("");
  
  useEffect(() => {
    setTitle(NoticeEditData?.title);
    setSelectClass(NoticeEditData?.classesID);
    // setStudents(NoticeEditData?.routename);
    setFile(NoticeEditData?.file);
    setDate(NoticeEditData?.date);
    setNotice(NoticeEditData?.notice);
    }, []);

  

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/classes/getClasses`);
      
      setClasss(response.data.classes);
      // setClassID(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const HandleTitleChange = (e) => {
    setTitle(e.target.value);

  }
  const handleClasschange = (e) => {
    setSelectClass(e.target.value);
}

const HandleStudentChange = (e) => {
  setStudents(e.target.value);
}
const handleFileChange = (e) => {
  setFile(e.target.value);
}

const handleDateChange = (e) => {
  setDate(e.target.value);
}

const handleNoticeChange = (e) => {
  setNotice(e.target.value);
}



  

  const   EditNotice = async () => {

    let response = await request({
      url: `${BaseURL}/notice/noticeEdit/${NoticeEditData?.noticeID}/${schoolyearID}`,
      // url: 'posts',
      method:"POST",
      data: JSON.stringify({
        // "feetype_name" : FeeType,
        "title" : Title,
        "date" : Date,
         "file" : File,
         "notice" : Notice,
         "classesID" : selectClass
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });
  
     if( response.status == "200" ) {
      Swal.fire({
        icon: "success",
        title: "success!",
        text: "Notice Updated Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      // var receiptID = response.receiptID;
      // var schoolyearID = response.schoolyearID;
      // var urll = `${BaseURL}/feecollection/printPreview/${schoolyearID}/${receiptID}`;
navigate(`/annoucement/notice`);
      // window.location.href = urll;
      // fetchData();
    } 
          //  alert("Data submitted successfully!!");fee_list
          // let feetypenamedata = response.fee_list
          // console.log(feetypenamedata, "feetypenamedata");
          // setFeeNameListing(response?.fee_list)
                      //  console.log("response", response.fee_list);
          //  fetch("http://www.swarnkarsamajindia.com/api/add-karyakarini");
    // } else {
        // alert("data entry failed");
     
}

useEffect(() => {
    EditNotice();
}, []);

console.log("classs", classs);
return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                    Notice Add
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      <button className="btn btn-success" onClick={redirect}>
                        Back
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{marginTop:"-10px"}} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{marginTop:"-20px"}}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Title
                            </label>
                            <div className="col-sm-6">
                            <input type="text" onChange= {HandleTitleChange} value={Title} className="form-control" id="name_id" name="name"/>
                                  
                                  {/* { classes && classes.map((classes) => { */}
                      
                        
                      
                      {/* })} */}
                                                                
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Class
                            </label>
                            <div className="col-sm-6">
                            <select  onChange = {handleClasschange}
                                    type="text"
                                    className="form-control"
                                    id="hostelType"
                                    name="HostelType"
                                    value={selectClass}
                                    
                                  >
                                  <option value="0" >Select Class</option>
                                  <option value="-1" >Select All</option>
                            {classs &&
                      classs?.map((classItem) => {
                        return (
                          <option
                            key={classItem?.classesID}
                            value={classItem?.classesID}
                          >
                            {classItem?.classes}
                          </option>
                        );
                      })}

</select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Students
                            </label>
                            <div className="col-sm-6">
                              <select
                                className="form-control"
                              
                                onChange = {HandleStudentChange}
                                
                              >
                                <option value="0">Select student</option>
                                <option value="1">Permanand </option>
                                <option value="2">Abhishek</option>
                                <option value="3">Hitesh</option>
                                </select>

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              File
                            </label>
                            <div className="col-sm-6">
                              <input type="file"
                                className="form-control"
                              
                                onChange= {handleFileChange}
                                
                             />
                               
                              

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Date
                            </label>
                            <div className="col-sm-6">
                              <input type="date"
                                className="form-control"
                                value={Date}
                              
                                onChange= {handleDateChange}
                                
                             />
                               
                              

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                            Notice
                            </label>
                            <div className="col-sm-6">
                              {/* <input
                                className="form-control"
                                type="text"
                              /> */}
                              <textarea  className="form-control"  value={Notice} onChange={handleNoticeChange} />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick={EditNotice}
                              value="Update Notice"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
