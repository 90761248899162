import React from 'react'
import "./Payslip.css";

const View = () => {
  return (
   <>
   <div className='container-fluid'>
    <div className='row'>
      <div className='col-sm-12'>
        <div className='card'>
         <div className='main_table123_main'>
         <h4 className='CENTRAL_text'>THE CENTRAL..</h4>
         <div>
         <h6 className='Payslip_1text'>Payslip For 05-2022</h6>

         <div className='payslip_table'>
          <table className='payslip_table_main'>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
             
            </tr>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>

             <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>
          </table>
         </div>
         </div>
         <div>

         <h6 className='Payslip_1text'>Payslip For 05-2022</h6>
         <div className='payslip_table'>
          <table className='payslip_table_main'>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
             
            </tr>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>

             <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>

            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>
          </table>
         </div>


         <h6 className='Payslip_1text'>Payslip For 05-2022</h6>
         <div className='payslip_table'>
          <table className='payslip_table_main'>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
             
            </tr>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>
          </table>
         </div>

         <div>
         <h6 className='Payslip_1text'>Payslip For 05-2022</h6>

         <div className='payslip_table'>
          <table className='payslip_table_main'>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
             
            </tr>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>

             <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>
          </table>
         </div>
         </div>

         <h6 className='Payslip_1text'>Payslip For 05-2022</h6>
         <div className='payslip_table'>
          <table className='payslip_table_main'>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
             
            </tr>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>
          </table>
         </div>

         <div>
         <h6 className='Payslip_1text'>Payslip For 05-2022</h6>

         <div className='payslip_table'>
          <table className='payslip_table_main'>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
             
            </tr>
            <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>

             <tr>
            <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
              <th>Teacher Name</th>
              <td>AVINASH BAIRAGI</td>
            </tr>
          </table>
         </div>
         </div>

         </div>
         </div>
        </div>
      </div>
    </div>
   </div>
   </>
  )
}

export default View