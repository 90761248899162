import React from 'react'
import Photo from "../../../assets/images/dummy_pic.jpeg";

const View = () => {
  return (
   <div className='container-fluid'>
    <div className='row'>
        <div className='col-sm-12'>
           <div className='card'>
           <div className='main_notice_img' style={{width:"100%",height:"300px",marginTop:"50px"}}>
            <img src={Photo} alt="" srcset="" style={{width:"100%",height:"300px"}} />
           </div>

           <div className='main_notice_text d-flex' style={{gap:"200px"}}>
           <div>
           <h5 className='mt-3 ml-3'>keendroid_event</h5>
           </div>
           <div>
           <h5 className='mt-3 ml-3 '>11 jan 2024</h5>
           </div>
           </div>
           <h5 className='mt-3 ml-3 mb-5'>hello</h5>
           </div>
        </div>
    </div>
   </div>
  )
}

export default View;