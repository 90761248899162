import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import "./Monthy_report.css"

function Monthly_Report() {
  const [AttendanceType, setAttendanceType] = useState('');
  const [classs, setClasss] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [Sections, setSections] = useState([]);
  const [selectedSection, setSelectedSections] = useState('');
  const [loading, setLoading] = useState(false);
  const [Month, setSelectedMonth] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const handleError = () => {
    alert("Please select the essential options");
  };

  const handleAttendanceTypeChange = (event) => {
    setAttendanceType(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSections(event.target.value);
  };

  const handleMonthChange = (event) => {
    const value = event.target.value;
    const [year, month] = value.split("-");
    setSelectedMonth(`${month}-${year}`);
  };

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/classes/getClasses`);
      setClasss(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchSections = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/section/getSections`);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const url = `${BaseURL}/report/getmonthlyreportdata`;
    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          "classID": "63",
          "sectionID": "",
          "type": AttendanceType,
          "date": Month,
          "schoolyearID": schoolyearID
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });
      if (res && res.attendence) {
        const uniqueData = Array.from(new Set(res.attendence.map(a => a.studentID)))
          .map(studentID => res.attendence.find(a => a.studentID === studentID));
        setStudentData(uniqueData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRenderList = async () => {
    if (AttendanceType && selectedClass && selectedSection && Month) {
      await fetchData();
      setShowTable(true);
    } else {
      handleError();
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-12">
                    <h4>Monthly Report</h4>
                    <div>
                      <hr style={{ marginTop: "2px" }} />
                    </div>
                    <div className="main_part_blood12">
                    <div>
                      <select onChange={handleAttendanceTypeChange} className="select_new_12" placeholder="select type"style={{position:"relative",top:"7px"}}>
                        <option value="Attendance-Type">Attendance Type</option>
                        <option value="P">Present</option>
                        <option value="A">Absent</option>
                        <option value="-1">Both</option>
                      </select>
                    </div>
                    <div>
                      <select onChange={handleClassChange} className="select_new_12" placeholder="Class">
                        <option value="">Class </option>
                        <option value="-1">select all</option>
                        {classs.map((classes) => (
                          <option
                            key={classes.classesID}
                            value={classes.classesID}
                          >
                            {classes.classes}
                          </option>
                        ))}
                      </select>
                      <select onChange={handleSectionChange} className="select_new_12" placeholder="Section" style={{marginLeft:"10px"}}>
                        <option value="Section"> Select Section</option>
                        {Sections.map((u) => (
                          <option key={u.sectionID}
                            value={u.sectionID}
                          >{u.section}</option>
                        ))}
                      </select>
                      <input
                        type="month"
                        onChange={handleMonthChange}
                        className="select_new_123"
                        placeholder="Select Month"
                        style={{marginLeft:"10px"}}
                      />
                      <input className="mt-4 btn btn-success" type="button" value="View Report" onClick={handleRenderList} style={{marginLeft:"14px",position:"relative",top:"-11px"}}/>
                    </div>
                   </div>
                  </div>
                </div>

                {showTable && (
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="display dataTable" id="advance-1">
                        <thead>
                          <tr>
                            <th>Name</th>
                            {Array.from({ length: 31 }, (_, i) => (
                              <th key={i}>{i + 1}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {studentData.map((student) => (
                            <tr key={student.studentID}>
                              <td>{student.studentName}</td>
                              {Array.from({ length: 31 }, (_, i) => (
                                <td key={i}>{student[`a${i + 1}`] || ''}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Monthly_Report;
