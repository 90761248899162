import React, {useState, useEffect} from "react";
import {Link ,useNavigate, useLocation, useParams} from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
import axios from "axios";

function Edit(props) {
  const [loading, setLoading] = useState(false);
  const [DeviceID, setDeviceID] = useState('');
  const [BusName, setBusName] = useState('');
  const [RouteName, setRouteName] = useState('');
  const[BusShift, setBusShift] = useState('');
  const [shift, setShift] = useState([]);


  let location = useLocation();
  // console.log('students',location.state.students);
  const BusEditData  =  location.state.BusAttendanceEditData;
  document.title = "Transport | Edit";

  let navigate = useNavigate();


  // const redirect = () => {
  //   let path = `/transport`;
  //   navigate(path);
  // };

  useEffect(() => {
    setDeviceID(BusEditData?.deviceid);
    setBusName(BusEditData?.bus_name);
    setRouteName(BusEditData?.routename);
    setBusShift(BusEditData?.shiftID);
    }, []);


  const HandleDeviceid = (e) => {
    setDeviceID(e.target.value);

  }
  const handleBusName = (e) => {
    setBusName(e.target.value);
}

const handleRouteName = (e) => {
  setRouteName(e.target.value);
}
const handleBusShift = (e) => {
  setBusShift(e.target.value);
}

const   EditBus = async () => {

  let response = await request({
    url: `${BaseURL}/transport/busEdit/${BusEditData?.transportID}`,
    // url: 'posts',
    method:"POST",
    data: JSON.stringify({
      // "feetype_name" : FeeType,
      "deviceid" : DeviceID,
      "bus_name": BusName,
      "routename" : RouteName,
      "shiftID": BusShift,
      
    
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${uniqueToken}`,
    }
  });

   if( response.status == "200" ) {
    Swal.fire({
      icon: "success",
      title: "success!",
      text: "Bus Updated Successfully...",
      showConfirmButton: false,
      timer: 1500,
    });
    
    navigate(`/transport`);
 
  } 
   
}

useEffect(() => {
EditBus();
}, []);
const fetchShift = async () => {
  // props.setProgress(10);
  setLoading(true);
  await axios
    .get(`${BaseURL}/shift/getShift`)
    .then(async (response) => {
      // props.setProgress(30);
      const data = await response.data;
      // props.setProgress(80);
      setTimeout(() => {
        if (data) {
          setLoading(false);
          setShift(data?.shift);
        }
        // props.setProgress(100);
      }, 1000);
    });
};

useEffect(() => {
  fetchShift();
}, []);



  console.log("busEditdata",BusEditData);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
              <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                    Edit Bus Details
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      <button className="btn btn-success">
                        Back
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr />
                  <div className="row">
                    <div className="col-sm-12">
                      <form class="form-horizontal" role="form" method="post">
                        <div class="form-group">
                        <div className="row">
                          <label for="deviceid" class="col-sm-2 control-label">
                            Device Id
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="deviceid"
                              name="deviceid"
                              value={DeviceID}
                              onChange={HandleDeviceid}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                        <div className="row">
                          <label for="bus_name" class="col-sm-2 control-label">
                            Bus Name
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="bus_name"
                              name="bus_name"
                              value={BusName}
                              onChange={handleBusName}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label for="routename" class="col-sm-2 control-label">
                            Route Name
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="routename"
                              name="routename"
                              value={RouteName}
                              onChange={handleRouteName}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                          <div className="row">
                          <label for="shiftID" class="col-sm-2 control-label">
                            Bus shift
                          </label>
                          <div class="col-sm-6">
                            <div class="input-group">
                            <select className="form-control"  onChange={handleBusShift} >
                                <option value="0" >select shift</option>
                                {shift &&
                      shift?.map((classItem) => (
                        <option
                          key={classItem?.shiftID}
                          value={classItem?.shiftID}
                        >
                          {classItem?.shift_name}
                        </option>
                      ))}
                                </select>
                              <span class="btn btn-success">
                              <Link
                              to="/shift/add"
                                type="button"
                                className="add-button"
                              >
                                <i className="fa fa-plus"> </i>
                              </Link>
                              </span>
                            </div>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              class="btn btn-success"
                              value="Update transport"
                              onClick={EditBus}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
