import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../Components/BreadCrumb/Breadcrumb";
import Loader from "../../../Components/Loader/Loader";
import Swal from "sweetalert2";
import axios from "axios";
import { BaseURL, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";

function Busattendance(props) {
  const [RouteData, setRouteData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shift, setShift] = useState([]);
  const [shiftMap, setShiftMap] = useState({});
  const formatDateToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Get the current date and format it
  const getCurrentFormattedDate = () => {
    const currentDate = new Date();
    return formatDateToDDMMYYYY(currentDate);
  };

  // Initialize the state with the current date
  const [selectedDate, setSelectedDate] = useState(getCurrentFormattedDate());
  
  const fetchShift = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/shift/getShift`);
      const data = await response.data;
      if (data && data.shift) {
        setShift(data.shift);

        // Create a map of shiftID to shift_name
        const shiftMapping = data.shift.reduce((map, shift) => {
          map[shift.shiftID] = shift.shift_name;
          return map;
        }, {});
        setShiftMap(shiftMapping);
      }
    } catch (error) {
      console.error("Error fetching shift data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchShift();
  }, []);
 ///transport/add
  const deleteBusApi = `${BaseURL}/transport/busDelete`;
  const apiEndpoint = `${BaseURL}/transport/busList`;

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: apiEndpoint,
        method: "GET",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (response && response?.transports) {
        setRouteData(response?.transports);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteBusAttendanceApi = `${BaseURL}/transport/busDelete`;
      

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteBusAttendanceApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              //  schoolyearID: schoolyearID
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            fetchData();
          } else {
            alert("Error");
          }
        } catch (error) {
          console.error("Error deleting class:", error);
        }
      }
    });
  };  

    useEffect(() => {
    fetchData();
  }, []);


  console.log("RouteData", RouteData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                <div
                    className="add"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}>
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{
                        width: "auto",
                        marginLeft: "30px",
                        marginBottom: "-4px",
                      }}
                      to= "/transport/add"
                    >
                      <i className="fa fa-plus"></i> Add Transport{" "}
                    </Link>
                  <hr />
                </div>
                </div>
              </div>
              <div className="card-body">
                {loading && <Loader />}
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Bus  Names</th>
                        <th>Device ID</th>
                        <th>Route Name</th>
                        <th>Shift Name</th>
                        
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    { RouteData && RouteData?.map((u, index) => {
     
     return (
       <tr key={index}>
         <td>{index + 1}</td>
         <td>{u?.bus_name}</td>
         <td>{u?.deviceid}</td>
        
         <td>{u?.routename}</td>
         <td>{shiftMap[u?.shiftID] ? shiftMap[u.shiftID] : 'None'}</td>

       
         <td>
           <Link to={`/transport/edit/${u?.transportID}`}
            state={{ BusAttendanceEditData : u }} 
           >
             <i className="fa fa-edit edit"></i>
           </Link>
           <Link to={`/transport/view/${u?.transportID}/${u?.deviceid}/${selectedDate}`}
            state={{ BusAttendanceViewData : u,
              currentDate : selectedDate
             }} 
           >
             <i className="fa fa-eye view"></i>
           </Link>
           <Link onClick={(e) => { e.preventDefault(); handleDelete(u?.transportID); }}>
             <i className="fa fa-trash delete"></i>
           </Link>
         </td>
       </tr>
     );
   })};
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Busattendance;
