import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import { BaseURL, schoolyearID, uniqueToken } from "../../../../api/DataApis";
import request from "../../../../Apis/request";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";

function Add() {
  const [Feedata, setFeeData] = useState([]);
  const [students, setStudents] = useState([]);
  const [RegistrationNumber, SetRegistrationNumber] = useState("");
  const [FeeRecord, setFeeRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classesdata, setClasses] = useState([]);
  const [single, setsingle] = useState([]);

  const [sessionRecord, setSessionRecord] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [selectedStudentName, setSelectedStudentName] = useState([]);
  const [invoiceId, setInvoiceID] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [totaldueAmount, setTotalDueAmount] = useState();
  const [DepositAmount, SetDepositAmount] = useState([]);
  const [Discount, setDiscount] = useState([]);
  const [TotalDiscount, setTotalDiscount] = useState([]);
  const [Remark, setRemark] = useState([]);
  const [PaymentMethod, setPaymentMethod] = useState([]);
  const [studentID, setStudentID] = useState([]);
  const [classID, setclassID] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [ParentID, setParentID] = useState([]);
  const [ParentData, setParentData] = useState([]);
  const [FatherName, setFatherName] = useState("");
  const [chequeNumber, setChequeNumber] = useState([]);
  const [chequeDate, setChequeDate] = useState([]);
  const [bankName, setbankName] = useState([]);
  const [cardType, setCardType] = useState([]);
  const [ModeType, setModeType] = useState([]);
  const [responseData, SetresponseData] = useState([]);
  const [PaymentData, setPaymentData] = useState({});
  const [matchedClass, setMatchedClass] = useState();

  let data;
  //  const [showPopup, setShowPopup] = useState(false);

  const handleChequeNumberChange = (e) => {
    setChequeNumber(e.target.value);
  };
  const handleChequeDateChange = (e) => {
    setChequeDate(e.target.value);
  };
  const handleBankNameChange = (e) => {
    setbankName(e.target.value);
  };

  const handleCardTypeChange = (e) => {
    setCardType(e.target.value);
  };
  const handleModeTypeChange = (e) => {
    setModeType(e.target.value);
  };
  //setModeTypehandleModeTypeChange
  const AddApi = `${BaseURL}/feecollection/depositFee`;
  // const BaseURL = `https://demo.keendroid.in`;
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const [PaymentDate, SetPaymentDate] = useState(currentDate);
  document.title = "Feecollection | Add";
  let navigate = useNavigate();
  useEffect(() => {
    SetPaymentDate(currentDate);
  }, []);

  const redirect = () => {
    let path = `/feecollection`;
    navigate(path);
  };
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  // const handleCheckboxChange = (invoiceID) => {
  //   console.log("invoiceID", invoiceID);
  //   // Toggle the selection of the invoiceID
  //   setSelectedInvoices((prevSelected) => {
  //     if (prevSelected.includes(invoiceID)) {
  //       setInvoiceID((prevInvoiceId) =>
  //         prevInvoiceId.filter((id) => id !== invoiceID)
  //       );
  //       return prevSelected.filter((id) => id !== invoiceID);
  //     } else {
  //       setInvoiceID((prevInvoiceId) => [...prevInvoiceId, invoiceID]);
  //       return [...prevSelected, invoiceID];
  //     }
  //   });
  // };
  ///account/feehistory
  const handleSelectAllChange = () => {
    setSelectedInvoices((prevSelected) => {
      if (prevSelected.length === invoices.length) {
        // If all are selected, deselect all
        setInvoiceID([]);
        setTotalDueAmount(0); // Set total due amount to 0 when all are deselected
        return [];
      } else {
        // If not all are selected, select all
        const allInvoiceIds = invoices.map((invoice) => invoice.invoiceID);
        setInvoiceID(allInvoiceIds);
        const totalDue = mergedArray.reduce(
          (total, invoice) => total + invoice.remainingAmount,
          0
        );
        setTotalDueAmount(totalDue); // Set total due amount to the sum of all invoice due amounts
        return allInvoiceIds;
      }
    });
  };

  const fetchData = async (registrationNumber) => {
    try {
      const apiEndpoint = `${BaseURL}/feecollection/searchFeeRecord`;
      setLoading(true);

      const response = await request({
        url: apiEndpoint,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
          search: registrationNumber,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      // console.log("responseeeeeee", response);

      SetresponseData(response);
      if (response && response.payments) {
        // Convert keys to integers if needed
        const payments = Object.entries(response.payments).reduce(
          (acc, [key, value]) => {
            acc[parseInt(key)] = value;
            return acc;
          },
          {}
        );
        setPaymentData(payments);
      } else {
        console.error("No payments data found in the response");
        // Optionally, handle the case where no payments data is found in the response
      }
      // console.log("paymentDATAAAAAAAAAAAAAAA", PaymentData);
      // setTotalDueAmount(response.total_due);
      // console.log(totaldueAmount);
      // setClasses(response.classes);
      data = response.total_due;

      // setInvoices(response.invoices);

      if (response.invoices) {
        setInvoices(response.invoices);
        // let totalAmount = 0;
        // response.invoices.forEach(invoice => {
        //   totalAmount += invoice.totaldue;
        // console.log(totalAmount,"233333333333333333333");

        // });
        // setTotalDueAmount(totalAmount)
      }
    } catch (error) {
      console.error("Error fetching fee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStudentChange = (selectedValue) => {
    const [
      registerNumber,
      studentName,
      studentID,
      parentID,
      classesID,
      father_name,
    ] = selectedValue.split(",");
    console.log(selectedValue);
    // Now you can use registerNumber, studentName, and studentID separately as needed

    console.log("studentclassssssss", classesID);

    // Update your state or perform other actions as needed
    SetRegistrationNumber(registerNumber);
    setSelectedStudentName(studentName);
    setStudentID(studentID); // Assuming you have a state variable for studentID
    setParentID(parentID);
    setclassID(classesID);

    setFatherName(father_name);

    // Fetch data based on the selected student if needed
    fetchData(registerNumber);
  };

  const handleTotalDueAmountChange = (e) => {
    setTotalDueAmount(e.target.value);
  };
  const handleDepositAmountChange = (e) => {
    SetDepositAmount(e.target.value);
    if (DepositAmount <= totaldueAmount) {
    } else {
      alert("deposit amount can not be more than total due amount");
    }
  };
  const handleDiscountChange = (event) => {
    // alert("ook");
    event.preventDefault();

    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => ({
        value: option.value,
        text: option.text,
      })
    );

    setDiscount(selectedOptions);
    //setDiscount(selectedOptions.map(option => option.value));
  };

  const handleTotalDiscountChange = () => {
    // Assuming Discount is an array of objects like [{ value: 1, text: "1000" }, { value: 2, text: "2000" }, ...]

    const valuesArray = Discount.map((item) => parseInt(item.value)); // Parse the value to integer

    const sum = valuesArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    ); // Calculating the sum

    setTotalDiscount(sum);
  };

  useEffect(() => {
    // Call handleTotalDiscountChange whenever discounts change
    handleTotalDiscountChange();
  }, [Discount]);

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };
  const handlePaymentDateChange = (e) => {
    // Handle date changes if needed
    SetPaymentDate(e.target.value);
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.post(`${BaseURL}/student/getStudents/-1`, {
          schoolyearID: schoolyearID,
        });

        const { students, parents } = response.data;

        const studentsWithParentData = students.map((student) => {
          const parent = parents.find(
            (parent) => parent.parentsID === student.parentID
          );

          return {
            ...student,
            father_name: parent ? parent.father_name : null,
          };
        });

        // console.log("Students with Class Data:", studentsWithClassData);

        // Now studentsWithClassData contains students data along with their parent and class information
        setStudents(studentsWithParentData);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    getData();
  }, []);

  // useEffect(() => {
  //   function findStudentClass(studentId) {
  //     const student = students.find((student) => student.id === studentId);
  //     if (!student) {
  //       return "Student not found";
  //     }

  //     const selectedClass = classes.find(
  //       (cls) => cls.classId === student.classId
  //     );
  //     if (!selectedClass) {
  //       return "Class not found";
  //     }

  //     return selectedClass.className;
  //   }
  // }, []);

  useEffect(() => {
    // console.log("ParentID", parentID);
  }, [FeeRecord, invoices, selectedStudentName]); // Include FeeRecord, invoices, and selectedStudentName as dependencies

  const saveData = async (e) => {
    e.preventDefault();
    const dAmount = TotalDiscount;
    

    const valuesArray = Discount.map((item) => item.value);

    const dataToSave = {
      usertypeID: 1,
      username: "admin",
      schoolyearID: schoolyearID,
      loginuserID: 5,
      fdAmount: DepositAmount,
      dAmount: dAmount,
      invoices: invoiceId,
      discounts: valuesArray,
      payment_date: PaymentDate,
      registerNO: RegistrationNumber,
      studentID: studentID,
      chq_date: "2023-11-28",
      fchqBank: "sbi",
      paymenttype: PaymentMethod,
      chq_no: 123,
    };
    // const schoolyearID = dataToSave.schoolyearID;

    try {
      let response = await request({
        url: AddApi,
        method: "POST",
        data: JSON.stringify(dataToSave),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response.status === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Fee Submitted Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });

        var fcID = response.fcID;
        var urll = `${BaseURL}/feecollection/printPreview/${schoolyearID}/${fcID}`;
        // https://demo.keendroid.in/feecollection/printPreview/${schoolyearID}${fcID}

        // Navigate to the print preview URL with fcID in the body

        // Optionally, you can navigate using window.location.href if you don't have access to history or useNavigate
        window.location.href = urll;
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle error
    }
  };

  const discountOptions = [
    { value: "500", label: "500" },
    { value: "1000", label: "1000" },
    { value: "5000", label: "5000" },
  ];

  const handleNavigateToFeeHistory = () => {
    navigate(`/account/feehistory/academic/${RegistrationNumber}`, {
      state: { StudentRegistrationNumber: RegistrationNumber },
    });

    handleClosePopup(); // Close the modal after navigation
  };

  const handleCheckboxChange = (invoiceID, amount) => {
    // Ensure amount is parsed as a decimal number
    amount = parseFloat(amount);

    setSelectedInvoices((prevSelected) => {
      if (prevSelected.includes(invoiceID)) {
        setInvoiceID((prevInvoiceId) =>
          prevInvoiceId.filter((id) => id !== invoiceID)
        );
        setTotalDueAmount((prevTotal) => {
          // Subtract amount when unchecked
          const newTotal = (prevTotal || 0) - amount; // If prevTotal is undefined, set it to 0
          return newTotal;
        });
        return prevSelected.filter((id) => id !== invoiceID);
      } else {
        setInvoiceID((prevInvoiceId) => [...prevInvoiceId, invoiceID]);
        setTotalDueAmount((prevTotal) => {
          // Add amount when checked
          const newTotal = (prevTotal || 0) + amount; // If prevTotal is undefined, set it to 0
          return newTotal;
        });
        return [...prevSelected, invoiceID];
      }
    });
  };

  useEffect(() => {
    // Fetch discount options from the API
    const fetchDiscountOptions = async () => {
      try {
        const response = await axios.get(`${BaseURL}/discount/getDiscounts`);
        const { discount } = response.data;
        const options = discount.map((item) => ({
          id: item.discountID,
          text: item.type || "N/A", // If type is null, use "N/A" as default
        }));
        setDiscount(options);
      } catch (error) {
        console.error("Error fetching discount options:", error);
        // Handle error
      }
    };

    fetchDiscountOptions(); // Call the function to fetch discount options
  }, []);
  // console.log("paymentDATAAAAAAAAAAAAAAA", PaymentData);

  let mergedArray = invoices.map((invoice) => {
    const invoiceID = invoice.invoiceID;
    const paidAmount = PaymentData[invoiceID] || 0; // If payment not found, default to 0
    const remainingAmount = invoice.amount - paidAmount;
    return { ...invoice, paidAmount, remainingAmount };
  });

  console.log("classID:", classID);

  const apiEndpoint = `${BaseURL}/classes/getClasses`;

  const fetchDatae = async () => {
    setLoading(true);
  
    try {
      const url = apiEndpoint;
      const res = await request({
        url: url,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });
  
      if (res && res.classes) {
        setClasses(res.classes);
        console.log("response class", res.classes);
  
        const classdata = res.classes.find(
          (classs) => classs.classes_numeric === classID
        );
  
        if (classdata) {
          console.log("Class:", classdata);
          setMatchedClass(classdata);
        } else {
          console.log(`No class found for ID ${classID}.`);
          setMatchedClass(null);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  
    console.log("matchedClass", matchedClass); // Moved outside if-else block to log updated value
    return null;
  };
   
  useEffect(() => {
    console.log("matchedClass", matchedClass);
  }, [matchedClass]);

  useEffect(() => {
    fetchDatae();
  }, [classID]); // Add classID to the dependency array to re-run fetchDatae when classID changes

  console.log("vvvvvvv", classesdata);
  console.log("response class", classesdata);

  // console.log("dat11111111111", classesdata);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Fee collection
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3"></h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{ marginTop: "-10px" }} />
                  <div className="row">
                    {RegistrationNumber == 0 ? (
                      <div className="col-sm-12">
                        <form
                          className="form-horizontal"
                          encType="multipart/form-data"
                          role="form"
                          method="post"
                        >
                          <div className="form-group">
                            <div className="row">
                              <label
                                htmlFor="s2id_autogen1"
                                className="col-sm-2 control-label"
                              >
                                Student Bar Code
                              </label>
                              <div className="col-sm-3">
                                <Select2
                                  className="form-control"
                                  name="studentID"
                                  data={students.map(
                                    (student) =>
                                      `${student.registerNO},${student.name},${student.studentID},${student.parentID},${student.classesID}, ${student.father_name}`
                                  )}
                                  value={RegistrationNumber}
                                  onSelect={(event) =>
                                    handleStudentChange(event.target.value)
                                  }
                                />
                              </div>

                              <input
                                type="submit"
                                className="col-sm-2 btn btn-success"
                                value="Search"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="col-sm-12">
                        <form onSubmit={saveData}>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <label
                                      htmlFor="s2id_autogen1"
                                      className="control-label"
                                    >
                                      Name
                                    </label>
                                  </div>
                                  <div className="col-sm-2">:</div>
                                  <div className="col-sm-6">
                                    <p>
                                      <b>{selectedStudentName}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <label
                                      htmlFor="s2id_autogen1"
                                      className="control-label"
                                    >
                                      Father`s Name
                                    </label>
                                  </div>
                                  <div className="col-sm-2">:</div>
                                  <div className="col-sm-6">
                                    <p>
                                      <b>{FatherName}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <label
                                      htmlFor="s2id_autogen1"
                                      className="control-label"
                                    >
                                      Student Bar Code
                                    </label>
                                  </div>
                                  <div className="col-sm-2">:</div>
                                  <div className="col-sm-6">
                                    <p>
                                      <b>{RegistrationNumber}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <label
                                      htmlFor="s2id_autogen1"
                                      className="control-label"
                                    >
                                      Class
                                    </label>
                                  </div>
                                  <div className="col-sm-2">:</div>
                                  <div className="col-sm-6">
                                    <p>
                                      <b>{matchedClass?.classes}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              {/* <h5>Invoice List : (Total Due Amount:₹{responseData?.total_due})</h5> */}
                              <div className="col-sm-9">
                                <table className="table">
                                  <thead>
                                    <tr className="headback">
                                      <th>Sr. No.</th>
                                      <th>Period</th>
                                      <th>Fee Types</th>
                                      <th>Amount</th>
                                      <th>Paid</th>
                                      <th>Discount</th>
                                      <th>Due Date</th>
                                      <th>Total Due Amount</th>

                                      <th>
                                        <input
                                          type="checkbox"
                                          onChange={handleSelectAllChange}
                                          checked={
                                            selectedInvoices.length ===
                                              invoices.length &&
                                            invoices.length !== 0
                                          }
                                        />
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {invoices &&
                                      invoices.map((invoice, index) => {
                                        const mergedInvoice = mergedArray.find(
                                          (item) =>
                                            item.invoiceID === invoice.invoiceID
                                        );
                                        return (
                                          <tr key={index} className="backone">
                                            <td>{index + 1}</td>
                                            <td>{invoice?.tenure}</td>
                                            <td>{invoice?.feetype}</td>
                                            <td>{invoice?.amount}</td>
                                            <td>
                                              {mergedInvoice
                                                ? mergedInvoice.paidAmount
                                                : "N/A"}
                                            </td>
                                            <td>{invoice?.discount}</td>
                                            <td>{invoice?.due_date}</td>
                                            <td>
                                              {mergedInvoice.remainingAmount !==
                                              0
                                                ? mergedInvoice.remainingAmount
                                                : "Paid"}
                                            </td>
                                            <td>
                                              {mergedInvoice.remainingAmount !==
                                              0 ? (
                                                <input
                                                  type="checkbox"
                                                  onChange={() =>
                                                    handleCheckboxChange(
                                                      mergedInvoice.invoiceID,
                                                      mergedInvoice.remainingAmount
                                                    )
                                                  }
                                                  checked={selectedInvoices.includes(
                                                    mergedInvoice.invoiceID
                                                  )}
                                                />
                                              ) : (
                                                <span>Totally Paid</span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2">
                                <label>Total Due Amount</label>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  onChange={handleTotalDueAmountChange}
                                  className="form-control"
                                  type="text"
                                  name="fdAmount"
                                  id="fdAmount"
                                  value={totaldueAmount}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2">
                                <label>Deposit Amount</label>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  onChange={handleDepositAmountChange}
                                  className="form-control"
                                  type="text"
                                  name="fdamount"
                                  id="fdamount"
                                  value={DepositAmount}
                                  // fdamount
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2">
                                <label>Discount</label>
                              </div>
                              <div className="col-sm-4">
                                <Select2
                                  className="form-control"
                                  multiple
                                  //value={Discount}
                                  value={Discount.map((option) => option.value)}
                                  onChange={handleDiscountChange}
                                  options={{
                                    placeholder: "Select Discount Type",
                                    data: Discount,
                                  }}
                                  data={[
                                    { id: "500", text: "500" },
                                    { id: "1000", text: "1000" },
                                    { id: "5000", text: "5000" },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2">
                                <label>Total Discount</label>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  name="total_discount"
                                  id="total_discount"
                                  type="text"
                                  value={TotalDiscount} // Set the value to TotalDiscount state variable
                                  readOnly // Set readOnly attribute to make it readonly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2">
                                <label>Remark</label>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  name="remark"
                                  id="remark"
                                  type="text"
                                  value={Remark}
                                  onChange={handleRemarkChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2">
                                <label>Date</label>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  name="payment_date"
                                  id="payment_date"
                                  type="date"
                                  value={PaymentDate}
                                  onChange={handlePaymentDateChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2">
                                <label>Payment Method</label>
                              </div>
                              <div className="col-sm-4">
                                <select
                                  className="form-control"
                                  name="paymenttype"
                                  id="paymenttype"
                                  value={PaymentMethod}
                                  onChange={handlePaymentMethodChange}
                                >
                                  <option value="0">
                                    select payment method
                                  </option>
                                  <option value="cash">Cash</option>
                                  <option value="online">Online</option>
                                  <option value="cheque">Cheque</option>
                                  <option value="DD">DD</option>
                                  <option value="bharat_qr">Bharat QR</option>
                                  <option value="upi">UPI</option>
                                  <option value="merchant">Merchant</option>
                                  <option value="pos">POS</option>
                                </select>
                              </div>
                            </div>
                            {PaymentMethod === "cheque" && (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Cheque Number</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="chequeNumber"
                                        id="chequeNumber"
                                        value={chequeNumber}
                                        onChange={handleChequeNumberChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Cheque Date</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="date"
                                        className="form-control"
                                        name="chequeDate"
                                        id="chequeDate"
                                        value={chequeDate}
                                        onChange={handleChequeDateChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Bank Name</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="bankName"
                                        id="bankName"
                                        value={bankName}
                                        onChange={handleBankNameChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {PaymentMethod === "DD" && (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Chq/DD number</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="chequeNumber"
                                        id="chequeNumber"
                                        value={chequeNumber}
                                        onChange={handleChequeNumberChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label> Date</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="date"
                                        className="form-control"
                                        name="chequeDate"
                                        id="chequeDate"
                                        value={chequeDate}
                                        onChange={handleChequeDateChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Bank Name</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="bankName"
                                        id="bankName"
                                        value={bankName}
                                        onChange={handleBankNameChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {PaymentMethod === "pos" && (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label> Date</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="date"
                                        className="form-control"
                                        name="chequeDate"
                                        id="chequeDate"
                                        value={chequeDate}
                                        onChange={handleChequeDateChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Card Type</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <select
                                        className="form-control"
                                        name="cardtype"
                                        id="cardtype"
                                        value={cardType}
                                        onChange={handleCardTypeChange}
                                      >
                                        <option value="0">
                                          {" "}
                                          select card type
                                        </option>
                                        <option value="credit_card">
                                          credit card
                                        </option>
                                        <option value="debit_card">
                                          debit card
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Bank Name</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="bankName"
                                        id="bankName"
                                        value={bankName}
                                        onChange={handleBankNameChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {PaymentMethod === "merchant" && (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label> Date</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="date"
                                        className="form-control"
                                        name="chequeDate"
                                        id="chequeDate"
                                        value={chequeDate}
                                        onChange={handleChequeDateChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Mode</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="cardtype"
                                        id="cardtype"
                                        value={ModeType}
                                        onChange={handleModeTypeChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {PaymentMethod === "upi" && (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label> Date</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="date"
                                        className="form-control"
                                        name="chequeDate"
                                        id="chequeDate"
                                        value={chequeDate}
                                        onChange={handleChequeDateChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label>Mode</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="cardtype"
                                        id="cardtype"
                                        value={ModeType}
                                        onChange={handleModeTypeChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {PaymentMethod === "bharat_qr" && (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-sm-2">
                                      <label> Date</label>
                                    </div>
                                    <div className="col-sm-4">
                                      <input
                                        type="date"
                                        className="form-control"
                                        name="chequeDate"
                                        id="chequeDate"
                                        value={chequeDate}
                                        onChange={handleChequeDateChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-4">
                                <input
                                  type="button"
                                  className="btn btn-success"
                                  value="Add Fee"
                                  onClick={saveData}
                                />
                              </div>
                              <div className="col-sm-4">
                                <input
                                  type="button"
                                  className="btn btn-success"
                                  onClick={handleNavigateToFeeHistory}
                                  value="view history"
                                  style={{ marginLeft: "-165px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
