import React from 'react';

function Teachertimetable() {
     document.title = "Routine | Listing";
  return (
    <div>
      
    </div>
  );
}

export default Teachertimetable;
