import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import "react-select2-wrapper/css/select2.css";
import "react-datepicker/dist/react-datepicker.css";

function Edit(props) {
  document.title = "Academic Year | Edit";
  let navigate = useNavigate();
  let location = useLocation();
  let AcademicEditData = location.state.AcademicEditData;

  console.log("AcademicEditData", AcademicEditData);

  const [schoolyear, setSchoolyear] = useState([]);
  const [schoolyeartitle, setschoolyeartitle] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSchoolyear(AcademicEditData?.schoolyear);
    setschoolyeartitle(AcademicEditData?.schoolyeartitle);
  }, [AcademicEditData]);

  const handleSchoolyearTitle = (e) => {
    setschoolyeartitle(e.target.value);
  };
  
  const handleSchoolYear = (e) => {
    setSchoolyear(e.target.value);
  };

  const EditAcademicYear = async () => {
    setLoading(true);

    let response = await request({
      url: `${BaseURL}/schoolyear/editSchoolYear/${AcademicEditData?.schoolyearID}`,
      method: "POST",
      data: JSON.stringify({
        // school_type: "classbase",
        schoolyear: schoolyear,
        schoolyeartitle: schoolyeartitle,
        // loginuserID: "1",
        // username: "ryan_admin",
        // usertype: ""
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });

    setLoading(false);

    if (response.status === "200") {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Academic Year Edited Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/Administrator/academicyear');
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an error editing the academic year.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Academic Edit
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{ marginTop: "-10px" }} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{ marginTop: "-20px" }}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="s2id_autogen1" className="col-sm-2 control-label">
                              Year
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                id="name_id"
                                name="name"
                                value={schoolyear}
                                onChange={handleSchoolYear}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="s2id_autogen2" className="col-sm-2 control-label">
                              Year Title
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                onChange={handleSchoolyearTitle}
                                value={schoolyeartitle}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              value="Update Year"
                              onClick={EditAcademicYear}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
