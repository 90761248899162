import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import request from '../../../Apis/request';
import { BaseURL, uniqueToken } from '../../../api/DataApis';
import Swal from 'sweetalert2';
import axios from 'axios';

const Edit = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const BusMasterEditData = location?.state?.BusMasterEditData;

  const [loading, setLoading] = useState(false);
  const [BusName, setBusName] = useState("");
  const [BusStart, setBusStart] = useState("");
  const [BusEnd, setBusEnd] = useState("");
  const [DriverName, setDriverName] = useState("");
  const [DriverNumber, setDriverNumber] = useState("");
  const [BusNumber, setBusNumber] = useState("");
  const [RouteName, setRouteName] = useState("");
  const [RoutingListing, setRoutingListing] = useState([]);

  useEffect(() => {
    setBusName(BusMasterEditData?.busName);
    setBusStart(BusMasterEditData?.busStart);
    setBusEnd(BusMasterEditData?.busEnd);
    setDriverName(BusMasterEditData?.busDriver);
    setBusNumber(BusMasterEditData?.busNo);
    setDriverNumber(BusMasterEditData?.driverMobile);
    setRouteName(BusMasterEditData?.transport);
  }, [BusMasterEditData]);

  const handleInputChange = (setter) => (e) => setter(e.target.value);

  const fetchRoute = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/tmember/addTmember`);
      setRoutingListing(response?.data?.transports);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoute();
  }, []);

  const EditBusMaster = async (e) => {
    e.preventDefault(); // Prevent default form submission

    let response = await request({
      url: `${BaseURL}/transport/busrecord/busMasterEdit/${BusMasterEditData?.busId}`,
      method: "POST",
      data: JSON.stringify({
        name: BusName,
        start: BusStart,
        end: BusEnd,
        driver: DriverName,
        bus_no: BusNumber,
        driverMobile: DriverNumber,
        transport: RouteName,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Bus Updated Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate(`/transport/busmaster/busmaster`);
    } else {
      alert("Error updating bus master");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h3 className="caption-subject bold uppercase mx-3">
                      Edit Bus Attendance Details
                    </h3>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr />
                  <div className="row">
                    <div className="col-sm-12">
                      <form className="form-horizontal" role="form" onSubmit={EditBusMaster}>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="deviceid" className="col-sm-2 control-label">
                              Bus Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="deviceid"
                                name="deviceid"
                                value={BusName}
                                onChange={handleInputChange(setBusName)}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="bus_name" className="col-sm-2 control-label">
                              Bus Start
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="bus_name"
                                name="bus_name"
                                value={BusStart}
                                onChange={handleInputChange(setBusStart)}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="routename" className="col-sm-2 control-label">
                              Bus End
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="routename"
                                name="routename"
                                value={BusEnd}
                                onChange={handleInputChange(setBusEnd)}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="routename" className="col-sm-2 control-label">
                              Driver Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="routename"
                                name="routename"
                                value={DriverName}
                                onChange={handleInputChange(setDriverName)}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="routename" className="col-sm-2 control-label">
                              Bus No
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="routename"
                                name="routename"
                                value={BusNumber}
                                onChange={handleInputChange(setBusNumber)}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="shiftID" className="col-sm-2 control-label">
                              Driver Phone NO
                            </label>
                            <div className="col-sm-6">
                              <div className="input-group">
                                <input
                                  name="shiftID"
                                  id="shiftID"
                                  className="form-control select2"
                                  value={DriverNumber}
                                  onChange={handleInputChange(setDriverNumber)}
                                />
                              </div>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="s2id_autogen2" className="col-sm-2 control-label">
                              Bus Route
                            </label>
                            <div className="col-sm-6">
                              <select className="form-control" value={RouteName} onChange={handleInputChange(setRouteName)}>
                                <option value="0">Select Route</option>
                                {RoutingListing.map((route, index) => (
                                  <option key={index} value={route.routename}>{route.routename}</option>
                                ))}
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="submit"
                              className="btn btn-success"
                              value="Update Bus Master"
                              onClick={EditBusMaster}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
