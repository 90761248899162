import React, {useEffect, useState} from 'react'
import { BaseURL, schoolyearID, uniqueToken } from '../../../api/DataApis';
import request from '../../../Apis/request';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';


const Holiday = () => {
  const [HolidayData, setHolidayData] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState("");
  // const [selectedHostelType, setSelectedhoste] = useState("");
  
                 
  // const deleteBusApi = `${BaseURL}/transport/busDelete`;
  const apiHolidaypoint = `${BaseURL}/holiday/holidayList`;

  
  const fetchHolidayData = async (classesID) => {
    const apiEndpoint = apiHolidaypoint;

    setLoading(true);

    var url = apiEndpoint;
  

    let res = await request({
      url: url,
      // url: 'posts',
      method: "POST",
      data: JSON.stringify({
        schoolyearID: schoolyearID,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });
    // setClasses(res.classes);

    console.log("res", res);
    // console.log("enquiry_status", EnquiryStatus);
    if (res && res?.holidays) {
      setHolidayData(res?.holidays);

      // console.log("student_list", studentss);
      setHolidayData(res?.holidays);
    }

    return [];
  };
  useEffect(() => {
    fetchHolidayData();
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteHolidayApi = `${BaseURL}/holiday/holidayDelete`;
      

      if (results?.value) {
        try {
          const res = await request({
            url: `${DeleteHolidayApi}/${id}/${schoolyearID}`,
            method: "POST",
            data: JSON.stringify({
              //  schoolyearID: schoolyearID
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            fetchHolidayData();
          } else {
            alert("Error");
          }
        } catch (error) {
          console.error("Error deleting Data:", error);
        }
      }
    });
  }; 



 
  console.log("HolidayData", HolidayData );

  return (
    <>
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
                  <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to={"/annoucement/holiday/add"}
                    >
                      <i className="fa fa-plus"></i> Add Holiday{" "}
                    </Link>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>All Holidays</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Details</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                       {HolidayData &&
                        HolidayData?.map((u, i) => { 
                       return ( 
                      <tr>
                        <td>{i+1}</td>
                        <td>{u?.title}</td>
                        <td>{u?.fdate}</td>
                        <td>{u?.tdate}</td>
                        <td>{u?.details}</td>
                        <td>
                       
                          <Link to={`/annoucement/holiday/view/${u?.holidayID}`}
                          state = {{HolidayViewData : u}}
                          >
                            <i
                              className="fa fa-check-square-o"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                          <Link to={`/annoucement/holiday/edit/${u?.holidayID}`}
                          state = {{HolidayData: u}}
                          >
                            <i
                              className="fa fa-edit"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                          <Link onClick={(e) => { e.preventDefault(); handleDelete(u?.holidayID); }}>
              <i className="fa fa-trash delete"></i>
            </Link>
                        </td>
                      </tr>
                       ); 
                     })} 
                    </tbody>
                   </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Holiday
