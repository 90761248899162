import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseURL,uniqueToken, schoolyearID } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from 'react-router-dom';
 

function Edit(props) {



  document.title = "Lost| Edit";
 let location = useLocation();
  let navigate = useNavigate();
  let BooksLostEditData = location?.state?.BooksLostEditData;
  console.log("BooksLostEditData", BooksLostEditData);
  useEffect(() => {
    if (BooksLostEditData) {
      setQuantity(BooksLostEditData?.lost_book);
     
      
      
     
    }
  }, [BooksLostEditData]);



  
const [BooksNames, setBooksNames] = useState([]);
const [AuthorName, setAuthorNames] = useState([]);
const [SubjectCode, setSubjectCode] = useState([]);
const [Price, setPrice] = useState([]);
const [Quantity, setQuantity] = useState([]);
const [RackNo, setRackNo] = useState([]);

const handleBooksNamesChange = (event) => {
  setBooksNames(event.target.value);
};

const handleAuthorNameChange = (event) => {
  setAuthorNames(event.target.value);
};

const handleSubjectCodeChange = (event) => {
  setSubjectCode(event.target.value);
};

const handlePriceChange = (event) => {
  setPrice(event.target.value);
};

const handleQuantityChange = (event) => {
  setQuantity(event.target.value);
};

const handleRackNoChange = (event) => {
  setRackNo(event.target.value);
};

const   UpdateLostBooks = async () => {

  let response = await request({
    url: `${BaseURL}/lost/editLostTorn/${BooksLostEditData?.bookID}`,
    // url: 'posts',
    method:"POST",
    data: JSON.stringify({
      // "feetype_name" : FeeType,
      "book": BooksLostEditData?.book,
      "book_number": BooksLostEditData?.book_number, 
      "subject_code": BooksLostEditData?.subject_code, 
      "author": BooksLostEditData?.author, 
      "lost_book_quantity": Quantity
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${uniqueToken    }`,
    }
  });

   if( response.Status == "200" ) {
    Swal.fire({
      icon: "success",
      title: "success!",
      text: "Data Updated Successfully...",
      showConfirmButton: false,
      timer: 1500,
    });
   
navigate(`/library/books/lost`);
    
  } 
    
   
}





  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Books Details
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{marginTop:"-10px"}} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form class="form-horizontal" role="form" method="post" style={{marginTop:"-23px"}}>
                        <div class="form-group">
                        <div className="row">
                          <label for="book" class="col-sm-2 control-label">
                            Name{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              id="book"
                              name="book"
                              readOnly
                              value={BooksLostEditData?.book}
                               
                              onChange={handleBooksNamesChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                        <div className="row">
                          <label for="book" class="col-sm-2 control-label">
                            Book Number{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              id="book"
                              name="book"
                              readOnly

                              value={BooksLostEditData?.book_number}
                               
                              onChange={handleBooksNamesChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label for="author" class="col-sm-2 control-label">
                            Author{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              id="author"
                              name="author"
                              readOnly
                              value={BooksLostEditData?.author}
                              
                              onChange={handleAuthorNameChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label
                            for="subject_code"
                            class="col-sm-2 control-label"
                          >
                            Subject code{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              id="subject_code"
                              name="subject_code"
                              value={BooksLostEditData?.subject_code}
                            readOnly
                              onChange={handleSubjectCodeChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                          <label for="rack" class="col-sm-2 control-label">
                            Rack No{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="rack"
                              name="rack"
                              readOnly
                              value={BooksLostEditData?.rack}
                            
                              onChange={handleRackNoChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                     

                        <div class="form-group">
                        <div className="row">
                          <label for="quantity" class="col-sm-2 control-label">
                            Quantity{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              id="quantity"
                              name="quantity"
                              value={Quantity}
                              
                              onChange={handleQuantityChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                     

                        <div class="form-group">
                          <div class="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              onClick={UpdateLostBooks}

                              class="btn btn-success"
                              value="Add Book"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
