import React, { useState, useEffect } from "react";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2";

function Add() {
  const [HostelData, setHostelData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classChange, setClassChange] = useState([]);
  const [HostelFee, setHostelfee] = useState([]);
  const [Note, setNote] = useState([]);
  const [HostelName, setHostelname] = useState([]);

  useEffect(() => {
    const FetchHostelData = async () => {
      setLoading(true);

      const url = `${BaseURL}/hostel/getHostels`;

      try {
        const res = await request({
          url: url,
          method: "POST",
          data: JSON.stringify({
            schoolyearID: schoolyearID,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${uniqueToken}`,
          },
        });

        console.log("res", res);

        if (res && res.hostels) {
          setHostelData(res.hostels);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    FetchHostelData();
  }, []);

  const handleClassType = (e) => {
    setClassChange(e.target.value);
  };

  const handleHostelFeeType = (e) => {
    setHostelfee(e.target.value);
  };

  const handleHostelNameType = (e) => {
    setHostelname(e.target.value);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const AddCategory = async () => {
    try {
      let response = await request({
        url: `${BaseURL}/category/addCategory`,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
          hname: HostelName,
          class_type: classChange,
          hbalance: HostelFee,
          note: Note,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response.status === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Category Added Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });
        // Handle success as needed
      } else {
        // Handle other response statuses or errors
      }
    } catch (error) {
      console.error("Error while adding category:", error);
      // Handle error as needed
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Hostel Category Details
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr />
                  <div className="row">
                    <div className="col-sm-12">
                      <form class="form-horizontal" role="form" method="post">
                        <div class="form-group">
                          <div className="row">
                            <label
                              for="s2id_autogen1"
                              class="col-sm-2 control-label"
                            >
                              Hostel Name{" "}
                            </label>
                            <div class="col-sm-6">
                              <select
                                name="hostelID"
                                id="HostelID"
                                className="form-control select2 select2-offscreen"
                                tabIndex="-1"
                                onChange={handleHostelNameType}
                              >
                                <option value="0">Select Hostel</option>
                                {HostelData &&
                                  HostelData.map((h, i) => (
                                    <option key={i} value={h.hostelID}>
                                      {h.name}
                                    </option>
                                  ))}
                              </select>{" "}
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                          <div className="row">
                            <label
                              for="tbalance"
                              class="col-sm-2 control-label"
                            >
                              Class Type{" "}
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="tbalance"
                                name="tbalance"
                                onChange={handleClassType}
                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                          <div className="row">
                            <label
                              for="tbalance"
                              class="col-sm-2 control-label"
                            >
                              Hostel Fee{" "}
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="tbalance"
                                name="tbalance"
                                onChange={handleHostelFeeType}
                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <label
                              for="tbalance"
                              class="col-sm-2 control-label"
                            >
                              Note{" "}
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="tbalance"
                                name="tbalance"
                                onChange={handleNoteChange}
                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              class="btn btn-success"
                              value="Add Category"
                              onClick={AddCategory}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
