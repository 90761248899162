import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import "../../assets/css/datatables.css";
import "../../assets/css/style_my.css";
import { getUserList } from "../../Apis/employee";
import Loader from "../../Components/Loader/Loader";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Swal from "sweetalert2";
import request from "../../Apis/request";
import { BaseURL, uniqueToken, schoolyearID } from "../../api/DataApis";
import _, { transform } from "lodash";


function Students(props) {
  document.title = "Students";
  const { classesID } = useParams();
  
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [paginatedStudents, setPaginatedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");
  const [parents, setParents] = useState([]);
  const [sections, setSections] = useState([]);

  const requestData = async (class_id) => {
    setLoading(true);
    const data = await getUserList(class_id);
    setLoading(false);
    if (data) {
      setStudents(data);
      setPaginatedStudents(_(data).slice(0).take(pageSize).value());
    }
  };

  const studentList = (e) => {
    if (e.target.value == students.length) {
      setPaginatedStudents(students);
    } else if (e.target.value != students.length) {
      requestData(e.target.value);
      setPageSize(pageSize);
      setPaginatedStudents(_(students).slice(0).take(pageSize).value());
    }
  };

  const onchange = (e) => {
    setPageSize(e.target.value);
  };

  const apiEndpoint = `${BaseURL}/classes/getClasses`;
  const deleteApi = `${BaseURL}/student/delStudent`;


  const fetchData = async () => {
    setLoading(true);
    const res = await request({
      url: apiEndpoint,
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });
    if (res && res.classes) {
      setClasses(res.classes);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const pageCount = students ? Math.ceil(students.length / pageSize) : 0;

  const pages = _.range(1, pageCount + 1);
  const pagination = (pageNO) => {
    setCurrentPage(pageNO);
    const startIndex = (pageNO - 1) * pageSize;
    const paginatedStudents = _(students)
      .slice(startIndex)
      .take(pageSize)
      .value();
    setPaginatedStudents(paginatedStudents);
  };

  const handlePageClick = (pageNO) => {
    let currentPage = pageNO.selected + 1;
    pagination(currentPage);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredStudents = !searchTerm
    ? paginatedStudents
    : students.filter((student) => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
          student.name.toLowerCase().includes(searchTermLower) ||
          student.registerNO.includes(searchTermLower)
        );
      });

  const handleDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((results) => {
      if (results.value == true) {
        axios.delete(`${deleteApi}/${id}`, {
          body: {
            schoolyearID: schoolyearID,
          },
        });
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Data Deleted Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });
        studentList();
      }
    });
  };

  const apiEndpointt = `${BaseURL}/parents/getParents`;

  const fetchParents = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: apiEndpointt,
        method: "GET",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (response && response.parents) {
        setParents(response.parents);
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParents();
  }, []);

  const getFatherName = (parentID) => {
    const parent = parents.find((p) => p.parentsID === parentID);
    return parent ? parent.father_name : 'Loading....';
  };
  const getClassName = (classID) => {
    const classItem = classes.find((c) => c.classesID === classID);
    return classItem ? classItem?.classes : 'Loading....';
  };

  const fetchSection = async () => {
    setLoading(true);
    const response = await axios.get(`${BaseURL}/section/getSections`);
    const data = await response.data;
    setTimeout(() => {
      if (data) {
        setLoading(false);
        setSections(data.sections);
      }
    }, 1000);
  };

  useEffect(() => {
    fetchSection();
  }, []);

  const getSectionName = (sectionID) => {
    const section = sections.find((s) => s.sectionID === sectionID);
    return section ? section.section : "Loading....";
  };

console.log("parents", parents);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div
                    className="add"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{
                        width: "auto",
                        marginLeft: "30px",
                        marginBottom: "-4px",
                      }}
                      to="/students/add"
                    >
                      <i className="fa fa-plus"></i> Add Student{" "}
                    </Link>
                    <select
                      className="form-control text-center"
                      style={{
                         borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                      border: "-1px ", position: "relative", top: "5px"
                        
                      }}
                      onChange={studentList}
                    >
                      <option value="">Select Class</option>
                      <option value="-1">View All</option>
                      {classes.map((classes) => {
                        return (
                          <option
                            key={classes.classesID}
                            value={classes.classesID}
                          >
                            {classes.classes}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <hr style={{ marginTop: "12px" }} />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex">
                      <div
                        className="add"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <select
                          name="selectcount"
                          className="drop-marg12"
                          onChange={onchange}
                          style={{ marginLeft: "30px", height: "32px" }}
                        >
                          <option value="show">Show</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value={students.length}>All</option>
                        </select>
                        <input
                          className="drop-marg_searchs12"
                          type="text"
                          placeholder="search here"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          style={{ width: "133px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <hr style={{ marginTop: "2px" }} />
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>sr no.</th>
                        <th>Name</th>
                        <th>Father's Name</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Register No</th>
                        <th>Phone</th>
                        <th>Photo</th>
                        <th>.Action.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredStudents.length > 0 ? (
                        filteredStudents.map((u, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{u && u?.name}</td>
                              <td>{getFatherName(u?.parentID)}</td>
                              <td>{getClassName(u?.classesID)}</td>
                              <td>{getSectionName(u?.sectionID)}</td>
                              <td>{u && u?.registerNO}</td>
                              <td>{u && u?.phone}</td>
                              <td>
                              <img style={{width:"24px"}} src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png" />

                              </td>
                              <td>
                                <Link
                                  to={`/students/edit/${u.studentID}/${u.classesID}`}
                                  state={{ students: u }} 
                                >
                                  <i className="fa fa-edit edit"></i>
                                </Link>
                                <Link
                                  to={`/students/view/${u?.studentID}/${u?.classesID}`}
                                  state={{ students: u }}
                                >
                                  <i className="fa fa-eye view"></i>
                                </Link>
                                <Link onClick={() => handleDelete(u.studentID)}>
                                  <i className="fa fa-trash delete"></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr style={{ backgroundColor: "#f5f7fb" }}>
                          <td colSpan="8" className="text-center mt-3">
                            <h5 style={{ marginTop: "8px" }}>No Record Found</h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {searchTerm ? (
                    ""
                  ) : (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  )}
                  <nav
                    className="d-flex justify-content-center"
                    style={{ cursor: "pointer" }}
                  >
                    <ul className="pagination" style={{ display: "none" }}>
                      {pages.map((page) => (
                        <li
                          key={page}
                          className={
                            page === currentPage
                              ? "page-item active"
                              : "page-item"
                          }
                        >
                          <p
                            className="page-link"
                            onClick={() => pagination(page)}
                          >
                            {page}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Students;
