import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "../../../Apis/request";
import axios from "axios";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import { User } from "react-feather";

function Add() {
  const [ExamState, setexamState] = useState([]);
  const [ClassState, setClassSet] = useState([]);
  const [SectionState, setSectionState] = useState([]);
  const [SubjectState, setSubjectState] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Marks, setMarks] = useState({});
  const [Students, setStudents] = useState([]);
  const [Term, setTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [inputValue, setInputValue] = useState(''); 
  const [saveMark, setSaveMark] = useState({}); 
  const [Classes, setClasses] = useState([]);
  const [AllSections, setAllSections] = useState([]);
  const [AllSubjects, setAllSubjects] = useState([]);

  const fetchData = async (ClassState) => {
    const examEndpoint = `${BaseURL}/exam/getExams/${ClassState}`;
    setLoading(true);

    try {
      const res = await request({
        url: examEndpoint,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
          classesID: ClassState,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });
      if (res) {
        if (res.exam_list) {
          setUsers((prevState) => ({
            ...prevState,
            exam_list: res.exam_list,
          }));
        }
        if (res.classes) {
          setClasses(res.classes);
        }
       
      }
    } catch (error) {
      console.error("Error fetching exams:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ClassState !== "") {
      fetchData(ClassState);
    }
  }, [ClassState, ExamState]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue(value);
    setSaveMark((prevSaveMark) => ({
      ...prevSaveMark,
      [name]: value,
    }));  
  };

  useEffect(() => { 
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/mark/addMarks`);
        console.log("api data", response.data);
        if (response?.data?.sections) {
          setAllSections(response?.data?.sections);
         }
         if (response?.data?.subjects) {
          setAllSubjects(response?.data?.subjects);
         }


      } catch (error) {
        console.error('Error fetching Add api data:', error);
      }
    };
    fetchData();
  }, []);

  const handleError = () => {
    alert("Please select the essential options");
  };

  const handleOption1Change = (event) => {
    setexamState(event.target.value);
  };
  
  const handleOption2Change = (event) => {
    setClassSet(event.target.value);
  };
  
  const handleOption3Change = (event) => {
    setSectionState(event.target.value);
  };
  
  const handleOption4Change = (event) => {
    setSubjectState(event.target.value);
  };

  const AddMarkData = async (e) => {
    const saveMarkObj = Object.entries(saveMark).map(([mark, value]) => ({
      mark: mark,
      value: value,
    }));

    const data = {
      schoolyearID: schoolyearID,
      classesID: ClassState,
      examID: ExamState,
      sectionID: 0,
      inputs: saveMarkObj,
      subjectID: "42",
    };

    e.preventDefault();

    try {
      let response = await request({
        url: `${BaseURL}/mark/updateMarks`,
        method: 'POST',
        data: JSON.stringify(data),
        headers: {
          'Accept': 'application/json, text/plain, */*',
          Authorization: `Bearer ${uniqueToken}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      alert("Success");
      console.log("update api response", response);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const generateListItems = async (event) => {
    const data = {
      schoolyearID: schoolyearID,
      classesID: ClassState,
      examID: ExamState,
      sectionID: "0",
    };

    try {
      const responseData = await axios.post(`${BaseURL}/mark/addMarks`, data);
      const student = responseData.data.students;
      setStudents(student);
      const mark = responseData.data.marks;
      const resultArray = {};
      for (const key1 in mark) {
        for (const key2 in mark[key1]) {
          const markValue = mark[key1][key2];
          if (markValue !== null && markValue !== "") {
            resultArray[`mark-1-${key1}-${key2}`] = markValue;
          }
        }
      }
      setSaveMark(resultArray);
    } catch (error) {
      console.error('Error generating list items:', error);
    }
  };

  const handleRenderList = (event) => {
    if (ExamState && ClassState && SectionState) {
      generateListItems(event);
    } else {
      handleError();
    }
  };

  console.log("userData", users);
  console.log("students", Students);
  console.log("classSet", ClassState);
  console.log("AllSections", AllSections);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase ml-4">
                      Marks Details
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{ marginTop: "-10px", width: "97%", marginLeft: "16px" }} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form className="form-horizontal" role="form" method="post" style={{ marginTop: "-23px" }}>
                        <div className="row px-3">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="s2id_autogen2" className="control-label">
                                    Class{" "}
                                  </label>
                                  <select value={ClassState} onChange={handleOption2Change}
                                    name="classesID"
                                    id="classesID"
                                    className="form-control select2 select2-offscreen"
                                    tabIndex="-1">
                                    <option value="">Select class</option>
                                    {Classes.map((item) => (
                                      <option key={item.classesID} value={item.classesID}>{item.classes}</option>
                                    ))}
                                  </select>{" "}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="s2id_autogen1" className="control-label">
                                    Exam{" "}
                                  </label>
                                  <select value={ExamState} onChange={handleOption1Change}
                                    name="examID"
                                    id="examID"
                                    className="form-control select2 select2-offscreen"
                                    tabIndex="-1">
                                    <option value="">Select Exam</option>
                                    <option value="-1">View All</option>
                                    {users?.exam_list?.map((item) => (
                                      <option key={item.examID} value={item.examID}>{item.exam}</option>
                                    ))}
                                  </select>{" "}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="control-label">Section</label>
                                  <select value={SectionState} onChange={handleOption3Change}
                                    name="sectionID"
                                    id="sectionID"
                                    className="form-control select2 select2-offscreen"
                                    tabIndex="-1">
                                    <option value="">Select Section</option>
                                    {AllSections.map((item) => (
                                      <option key={item.sectionID} value={item.sectionID}>{item.section}</option>
                                    ))}
                                  </select>{" "}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="s2id_autogen4" className="control-label">
                                    Subject{" "}
                                  </label>
                                  <select value={SubjectState} onChange={handleOption4Change}
                                    name="subjectID"
                                    id="subjectID"
                                    className="form-control select2 select2-offscreen"
                                    tabIndex="-1">
                                    <option value="">Select Subject</option>
                                    {AllSubjects?.map((item) => (
                                      <option key={item.subjectID} value={item.subjectID}>{item.subject}</option>
                                    ))}
                                  </select>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 col-xs-12">
                            <div className="row">
                              <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                  <input
                                    type="button"
                                    value="View"
                                    className="btn btn-success col-md-12 col-xs-12"
                                    style={{ marginTop: "28px" }}
                                    onClick={handleRenderList}>
                                  </input>
                                </div>
                              </div>
                            </div>
                          </div>
                          {Students.length > 0 ? (
                            <div className="card-body" style={{ marginLeft: "-10px" }}>
                              <div className="table-responsive">
                                <table className="table display dataTable" id="advance-1">
                                  <thead>
                                    <tr>
                                      <th>sr. no</th>
                                      <th>Name</th>
                                      <th>Roll</th>
                                      {users?.subjects?.map((item) => (
                                        <th key={item.subjectID}>{item.subject}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Students.map((item, index) => (
                                      <tr key={item.studentID}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.studentID}</td>
                                        {users?.subjects?.map((subject_item) => (
                                          <td key={subject_item.subjectID}>
                                            <input
                                              onChange={handleChange}
                                              name={`mark-1-${item.studentID}-${subject_item.subjectID}`}
                                              value={saveMark[`mark-1-${item.studentID}-${subject_item.subjectID}`] || ''}
                                              className="form-control"
                                              style={{ width: "60px" }}
                                            />
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="form-group">
                                <input
                                  type="button"
                                  onClick={AddMarkData}
                                  value="Add"
                                  className="btn btn-success col-md-3 col-xs-12"
                                  style={{ marginTop: "20px" }}>
                                </input>
                              </div>
                            </div>
                          ) : (
                            <div style={{ textAlign: "center" }} className="form-group">
                              <ul><li>No Record Found</li></ul>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
