import React, { useState, useEffect } from 'react';
import request from '../../Apis/request';
import { BaseURL, schoolyearID, uniqueToken } from '../../api/DataApis';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const General_settings = () => {
  const [LogoPic, setLogoPic] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [SettingData, setSettingData] = useState({});
  const [formData, setFormData] = useState({
    sname: "",
    phone: "",
    email: "",
    automation: "",
    auto_invoice_generate: "",
    note: "",
    google_analytics: "",
    currency_code: "",
    photo: "",
    currency_symbol: "",
    footer: "",
    address: "",
    language: "",
    attendance: "",
    school_year: "",
    fontend_theme: ""
  });

  const FetchSettingData = async () => {
    setLoading(true);

    const url = `${BaseURL}/setting/get_settings_data`;

    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({}),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res?.settings) {
        setSettingData(res?.settings);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchSettingData();
  }, []);

  useEffect(() => {
    if (SettingData) {
      setFormData(prevFormData => ({
        ...prevFormData,
        sname: SettingData?.sname || "",
        phone: SettingData?.phone || "",
        email: SettingData?.email || "",
        automation: SettingData?.automation || "",
        auto_invoice_generate: SettingData?.auto_invoice_generate || "",
        note: SettingData?.note || "",
        google_analytics: SettingData?.google_analytics || "",
        currency_code: SettingData?.currency_code || "",
        photo: SettingData?.photo || "",
        currency_symbol: SettingData?.currency_symbol || "",
        footer: SettingData?.footer || "",
        address: SettingData?.address || "",
        language: SettingData?.language || "",
        attendance: SettingData?.attendance || "",
        school_year: SettingData?.school_year || "",
        fontend_theme: SettingData?.fontend_theme || ""
      }));
    }
  }, [SettingData]);

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData?.sname || formData?.sname.trim() === "") {
      newErrors.sname = "Name is required";
      isValid = false;
    }

    if (!formData?.reference_name || formData?.reference_name.trim() === "select reference") {
      newErrors.reference_name = "Reference is required";
      isValid = false;
    }

    if (!formData?.reference_remark || formData?.reference_remark.trim() === "") {
      newErrors.reference_remark = "Reference remark is required";
      isValid = false;
    }

    // Add more validation logic for other fields

    setErrors(newErrors);
    return isValid;
  };

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleLogoChange = (e) => {
    console.log('File selected:', e.target.files[0]);
    setLogoPic(e.target.files[0]);
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      let response = await request({
        url: `${BaseURL}/setting/settings`,
        method: "POST",
        data: JSON.stringify(formData),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (response.status == "200") {
        Swal.fire({
          icon: "success",
          title: "success!",
          text: "Setting updated Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate('/setting/generalsetting');
      } else {
        alert("data entry failed");
      }

    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  console.log("SettingData", SettingData);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <h1 className="page-title"></h1>
            <div className="portlet light bordered">
              <div className="portlet-title row">
                <div className="caption font-dark col-md-10">
                  <h4 className="caption-subject bold uppercase mx-3">
                    General Setting
                  </h4>
                </div>
                <div className="caption font-dark col-md-2">
                  <h5 className="caption-subject bold uppercase mx-3">
                    {/* <button className="btn btn-success" onTouchMove={redirect}>
                    Back
                  </button> */}
                  </h5>
                </div>
              </div>
              <div className="portlet-body mx-3">
                <hr style={{ marginTop: "-10px" }} />
                <div className="row">
                  <div className="col-sm-12">
                    <form
                      className="form-horizontal"
                      role="form"
                      method="post"
                      style={{ marginTop: "-23px" }}
                    >
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Site Title{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="exam"
                              name="sname"
                              value={formData.sname}
                              onChange={handleChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Phone no.{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            System Email{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              class="form-control"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div className="row">
                          <label htmlFor="note" class="col-sm-2 control-label">
                            Auto Invoice Generate
                          </label>
                          <div class="col-sm-6">
                            <select
                              name="auto_invoice_generate"
                              className="form-control"
                              value={formData.auto_invoice_generate}
                              onChange={handleChange}
                              style={{ resize: "none" }}
                            >
                              <option value="">Select options</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Currency Code{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              name="currency_code"
                              value={formData.currency_code}
                              onChange={handleChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Currency Symbol{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              name="currency_symbol"
                              value={formData.currency_symbol}
                              onChange={handleChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div className="row">
                          <label htmlFor="note" class="col-sm-2 control-label">
                            Note
                          </label>
                          <div class="col-sm-6">
                            <select
                              name="note"
                              className="form-control"
                              rows="3"
                              value={formData.note}
                              onChange={handleChange}
                              style={{ resize: "none" }}
                            >
                              <option value= "">select option</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div className="row">
                          <label htmlFor="note" class="col-sm-2 control-label">
                            Google Analytics
                          </label>
                          <div class="col-sm-6">
                            <textarea
                              name="google_analytics"
                              className="form-control"
                              rows="3"
                              value={formData.google_analytics}
                              onChange={handleChange}
                              style={{ resize: "none" }}
                            ></textarea>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label
                            htmlFor="exam"
                            class="col-sm-2 control-label"
                          ></label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              class="form-control"
                              onChange={handleLogoChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Address
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              name="address"
                              className="form-control"
                              rows="3"
                              value={formData.address}
                              onChange={handleChange}
                              style={{ resize: "none" }}
                            ></textarea>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div class="form-group">
                        <div className="row">
                          <label htmlFor="note" class="col-sm-2 control-label">
                            Language
                          </label>
                          <div class="col-sm-6">
                            <select
                              name="language"
                              className="form-control"
                              value={formData.language}
                              onChange={handleChange}
                              style={{ resize: "none" }}
                            >
                              <option value="">Select language</option>
                              <option value="1">English</option>
                              <option value="2">French</option>
                              <option value="3">Spanish</option>
                              {/* Add other language options */}
                            </select>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            School Year{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              name="school_year"
                              value={formData.school_year}
                              onChange={handleChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Attendance{" "}
                          </label>
                          <div className="col-sm-6">
                            <select
                              name="attendance"
                              className="form-control"
                              value={formData.attendance}
                              onChange={handleChange}
                            >
                              <option value="">Select attendance</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Frontend Theme{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              name="fontend_theme"
                              value={formData.fontend_theme}
                              onChange={handleChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="exam" class="col-sm-2 control-label">
                            Footer{" "}
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              name="footer"
                              className="form-control"
                              rows="3"
                              value={formData.footer}
                              onChange={handleChange}
                              style={{ resize: "none" }}
                            ></textarea>
                          </div>
                          <span class="col-sm-4 control-label"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-offset-2 col-sm-6">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={SubmitForm}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {loading && <p>Loading...</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General_settings;
