import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../../Components/BreadCrumb/Breadcrumb";
import axios from "axios";
import request from "../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../api/DataApis";
import Swal from "sweetalert2";
// important Swal

const Edit = (props) => {
  document.title = "Students | Edit";

  const [saveData, setData] = useState({});
  const [StudentName, setStudentName] = useState('');
  const [FatherName, setFatherName] = useState('');
  const [MotherName, setMotherName] = useState('');
  const [dob, setDob] = useState('');
  const [doa, setDoa] = useState('');
  const [dol, setDol] = useState('');
  const [Gender, setGender] = useState('');
  const [BloodGroup, setBloodGroup] = useState('');
  const [Religion, setReligion] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [classes, setClasses] = useState([]);
  const [section, setSection] = useState('');
  const [Registration, setRegistration] = useState('');
  const [Roll, setRoll] = useState('');
  const [Remark, setRemark] = useState('');
  const [adhar, setAdhar] = useState('');
  const [sssmID, setsssmID] = useState('');
  const [FamilysssmID, setFamilysssmID] = useState('');
  const [parents, setParents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [bpl, setBPL] = useState([]);
  const [rte, setRte] = useState([]);
  const [Hostel, setHostel] = useState([]);

  const params = useParams();
  let studentID = params.sid;
  let classesID = params.cid;
  let navigate = useNavigate();
  let location = useLocation();
  const i = location.state.students;
  console.log("propsData", i);

  const handleStudentNameChange = (e) => setStudentName(e.target.value);
  const handleFatherNameChange = (e) => setFatherName(e.target.value);
  const handleMotherNameChange = (e) => setMotherName(e.target.value);
  const handleDobChange = (e) => setDob(e.target.value);
  const handleDoaChange = (e) => setDoa(e.target.value);
  const handleDolChange = (e) => setDol(e.target.value);
  const handleGenderChange = (e) => setGender(e.target.value);
  const handleBloodGroupChange = (e) => setBloodGroup(e.target.value);
  const handleReligionChange = (e) => setReligion(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleAddressChange = (e) => setAddress(e.target.value);
  const handleStateChange = (e) => setState(e.target.value);
  const handleCountryChange = (e) => setCountry(e.target.value);
   const handleClassesChange = (e) => setSelectedClass(e.target.value);
  const handleSectionChange = (e) => setSelectedSection(e.target.value);
  const handleRegistrationChange = (e) => setRegistration(e.target.value);
  const handleRollChange = (e) => setRoll(e.target.value);
  const handleRemarkChange = (e) => setRemark(e.target.value);
  const handleAdharChange = (e) => setAdhar(e.target.value);
  const handlesssmIDChange = (e) => setsssmID(e.target.value);
  const handleFamilysssmIDChange = (e) => setFamilysssmID(e.target.value);
  const handleBplChange =  (e) => setBPL(e.target.value);
  const handleRteChange =  (e) => setRte(e.target.value);
  const handleHostelChange =  (e) => setHostel(e.target.value);

  useEffect(() => {
    setStudentName(i?.name);
    setFatherName('');
    setMotherName('');
    setDob(i?.dob);
    setDoa(i?.doj);
    setDol(i?.dol);
    setGender(i?.sex);
    setBloodGroup(i?.bloodgroup);
    setReligion(i?.religion);
    setEmail(i?.email);
    setPhone(i?.phone);
    setAddress(i?.address);
    setState(i?.state);
    setCountry(i?.country);
    setSelectedClass(i?.classesID);
    setBPL(i?.bpl);
    setRte(i?.rte);
    setSection(i?.sectionID);
    setRegistration(i?.registerNO);
    setRoll(i?.roll);
    setRemark('');
    setAdhar(i?.aadhar);
    setsssmID(i?.ssmid);
    setFamilysssmID('');
  }, [i]);



  const savaeData = async (e) => {
    saveData.create_userID = "1";
    saveData.loginuserID = "3";
    saveData.schoolyearID = schoolyearID;
    saveData.username = "admin";
    saveData.usertype = "Admin";

    setLoading(true);
    const apiEndpoint = `${BaseURL}/student/editStudent`;
    let res = await request({
      url: `${apiEndpoint}/${studentID}/${classesID}`,
      method: "POST",
      data: JSON.stringify({
        
          "studentID": "9",
              "name": StudentName,
              "dob": dob,
              "sex": Gender,
              "religion": Religion,
              "email": email,
              "phone": phone,
              "address": address,
              "classesID": selectedClass,
              "sectionID": "1",
              "roll": null,
              "bpl": bpl,
              "rte": rte,
              "bloodgroup": BloodGroup,
              "country": country,
              "registerNO": Registration,
              "state": state,
              "library": "0",
              "hostel": "1",
              "transport": "1",
              "photo": "defualt.png",
              "parentID": "",
              "doj": doa,
              "dol": dol,
              "teacherID": null,
              "lat": null,
              "lng": null,
              "createschoolyearID": "7",
              "schoolyearID": schoolyearID,
              "username": phone,
              "password": "d296869378cd1f137aefb51c0849f8df131837be35092c443cbd2739ee061a13c3edae9c9c3fbac92927c147776a6177a64532dd68291ccd9f31b2e7f11d4045",
              "usertypeID": "3", 
              "aadhar": adhar,
              "policy": null,
              "ssmid": null,
              "create_date": "2023-06-20 07:19:02",
              "modify_date": "2023-06-20 07:19:02",
              "create_userID": "1",
              "create_username": "admin",
              "create_usertype": "Admin",
              "active": "0",
              "house": "",
      

      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });
    if( res.status == "200" ) {
      //  alert("Data submitted successfully!!");
      Swal.fire({
        icon: "success",
        title: "success!",
        text: "Student updated Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/students/students');
       console.log(res);
  
     } else {
       alert("data entry failed");
     }
  };

  const apiEndpointt = `${BaseURL}/parents/getParents`;

  const fetchParents = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: apiEndpointt,
        method: "GET",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (response && response.parents) {
        setParents(response.parents);
        const parent = response.parents.find((p) => p.parentsID === i.parentID);
        if (parent) {
          setFatherName(parent.father_name);
        } else {
          setFatherName('Not found');
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParents();
  }, []);

  const apiEndpoint = `${BaseURL}/classes/getClasses`;
  const fetchData = async ( ) => {
    // console.log("classID", classesID);
    //https://demo.keendroid.in/student/viewStudent/${u.studentID}/${u.classesID}
    // props.setProgress(10);
    setLoading(true);

    var url = apiEndpoint;
 
    let res = await request({
      
      url:url,
      // url: 'posts',
      method:"GET",
   
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
     }
    });
    console.log("res",res)
    if (res && res.classes) {
      //const data = res.data.student;
       //console.log("res",res.students)
      // setExamState(res.exam_list);
      setClasses(res.classes); 
      console.log("dataaaaaaaaaaaaaaaaaaaaaa",classes) 
      // console.log("exam state", ExamState);
      //sreturn res.student;
      }

  
    return [];
}

useEffect(() => {
  fetchData ();
}, []);




console.log("fatherName:", FatherName);
  return (
    <>
    <Breadcrumb title="Student" path="/students" subtitle="Edit" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Edit Details
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr />
                  <div className="row">
                    <div className="col-sm-10">
                      <form
                        className="form-horizontal"
                        role="form"
                        method="post"
                        // encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="name_id"
                              className="col-sm-2 control-label"
                            >
                              Name <span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input
                               onChange={handleStudentNameChange}
                               value={StudentName}

                                type="text"
                                className="form-control"
                                id="name_id"
                                name="name"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="father_name"
                              className="col-sm-2 control-label"
                            >
                              Father Name <span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input
                              onChange={handleFatherNameChange}
                              value={FatherName}
                                type="text"
                                className="form-control"
                                id="father_name"
                                name="father_name"
                                
                              />
                              <span className="col-sm-4 control-label"> </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="name_id"
                              className="col-sm-2 control-label"
                            >
                              Mother's Name
                            </label>
                            <div className="col-sm-6">
                              <input
                              onChange={handleMotherNameChange}
                              value={MotherName}
                                type="text"
                                className="form-control"
                                id="mother_name"
                                name="mother_name"
                                
                              />
                              <span className="col-sm-4 control-label"> </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="dob" className="col-sm-2 control-label">
                              Date of Birth
                            </label>
                            <div className="col-sm-6">
                              <input
                              onChange={handleDobChange}
                              value={dob}
                                type="date"
                                className="form-control"
                                id="dob"
                                name="dob"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="doj" className="col-sm-2 control-label">
                              Date of Admission
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handleDoaChange}
                              value={doa}
                                type="date"
                                className="form-control"
                                id="doa"
                                name="doa"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="dol" className="col-sm-2 control-label">
                              Date of Leaving
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handleDolChange}
                              value={dol}
                                type="date"
                                className="form-control"
                                id="dol"
                                name="dol"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="sex"
                              className="col-sm-2 control-label"
                            >
                              Gender<span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <select onChange={handleGenderChange} 
                                name="sex"
                                id="sex"
                                value={Gender}
                                className="form-control">
                               <option value="select gender">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="sex"
                              className="col-sm-2 control-label"
                            >
                              BPL<span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <select onChange={handleBplChange}
                                name="bpl"
                                id="bpl"
                                value={bpl}
                                className="form-control">
                               <option value="select bpl">Select Option</option>
                               <option value="1">Yes</option>
                                <option value="0">No</option>
                               
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="sex"
                              className="col-sm-2 control-label"
                            >
                              RTE<span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <select onChange={handleRteChange} 
                              value={rte}
                                name="rte"
                                id="rte"
                                className="form-control">
                               <option value="select rte">Select Option</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="sex" className="col-sm-2 control-label">
                              Gender
                            </label>
                            <div className="col-sm-6">
                              <select 
                              onChange={handleGenderChange}
                              value={Gender}
                                name="sex"
                                id="sex"
                                className="form-control"
                              >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="bloodgroup"
                              className="col-sm-2 control-label"
                            >
                              Blood Group
                            </label>
                            <div className="col-sm-6">
                              <select
                              onChange={handleBloodGroupChange}
                              value={BloodGroup}
                                name="bloodgroup"
                                id="bloodgroup"
                                className="form-control select2"
                              >
                                <option value="0">Select Blood Gorup</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="religion"
                              className="col-sm-2 control-label"
                            >
                              Religion
                            </label>
                            <div className="col-sm-6">
                              <input
                              onChange={handleReligionChange}
                              value={Religion}
                                type="text"
                                className="form-control"
                                id="religion"
                                name="religion"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="email"
                              className="col-sm-2 control-label"
                            >
                              Email
                            </label>
                            <div className="col-sm-6">
                              <input
                              onChange={handleEmailChange}
                              value={email}
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="phone"
                              className="col-sm-2 control-label"
                            >
                              Phone <span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handlePhoneChange}
                              value={phone}
                                type="text"
                                className="form-control"
                                id="phone"
                                name="phone"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="address"
                              className="col-sm-2 control-label"
                            >
                              Address
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handleAddressChange}
                              value={address}
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="state" className="col-sm-2 control-label">
                              State
                            </label>
                            <div className="col-sm-6">
                              <input
                              onChange={handleStateChange}
                              value={state}
                                type="text"
                                className="form-control"
                                id="state"
                                name="state"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="country" className="col-sm-2 control-label">
                              Country
                            </label>
                            <div className="col-sm-6">
                              <select 
                              onChange={handleCountryChange}
                              value={country}
                                name="country"
                                id="country"
                                className="form-control select2"
                              >
                                <option value="0">Select Country</option>
                                <option value="AF">Afghanistan</option>
                                <option value="AL">Albania</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="classesID"
                              className="col-sm-2 control-label"
                            >
                              Class<span className="text-red"> *</span>
                            </label>
                            <div className="col-sm-6">
                              <div className="input-group">
                                        <select  style={{marginLeft: "-10px"}}
                                onChange={handleClassesChange}
                                value={selectedClass}
                                  name="classesID"
                                  id="classesID"
                                    className="form-control select2 select2-offscreen"
                                    tabindex="-1"
                                  >
                                    <option value="0" selected="selected">
                                      Select Class
                                    </option>
                                    {classes?.map((classes) => {
                        return (
                          <option
                            key={classes.classesID}
                            value={classes.classesID}
                          >
                            {classes.classes}
                          </option>
                        );
                      })}

                                  </select>{" "}
                                <span className="input-group-addon add-btn">
                                  <Link to="/class/add" className="add-button">
                                    <i className="fa fa-plus"></i>
                                    Quick Add
                                  </Link>
                                </span>
                              </div>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="sectionID"
                              className="col-sm-2 control-label"
                            >
                              Section
                            </label>
                            <div className="col-sm-6">
                              <div className="input-group">
                                <select 
                                onChange={handleSectionChange}
                                value={section}
                                  name="sectionID"
                                  id="sectionID"
                                  className="form-control select2"
                                >
                                  <option value="0" selected="selected">
                                    Select Section
                                  </option>
                                  <option value="2">A</option>
                                  <option value="3">B</option>
                                  <option value="4">C</option>
                                  <option value="5">D</option>
                                </select>
                                <span className="input-group-addon add-btn">
                                  <Link to="/section/add" className="add-button">
                                    <i className="fa fa-plus"></i>
                                    Quick Add
                                  </Link>
                                </span>
                              </div>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="registerNO"
                              className="col-sm-2 control-label"
                            >
                              Register NO <span className="text-red">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handleRegistrationChange}
                              value={Registration}
                                type="text"
                                className="form-control"
                                id="registerNO"
                                name="registerNO"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="registerNO"
                              className="col-sm-2 control-label"
                            >
                              Library <span className="text-red"></span>
                            </label>
                            <div className="col-sm-6">
                              <select
                              onChange=""
                              value=""
                                type="text"
                                className="form-control"
                                id="library"
                                name="library">
                                  <option >select option</option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                  </select>

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="roll" className="col-sm-2 control-label">
                              Roll
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handleRollChange}
                              value={Roll}
                                type="text"
                                className="form-control"
                                id="roll"
                                name="roll"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        {/* <div className="form-group">
                          <div className="row">
                            <label htmlFor="photo" className="col-sm-2 control-label">
                              Photo
                            </label>
                            <div className="col-sm-6">
                              <div className="input-group image-preview">
                                <input
                                  type="text"
                                  className="form-control image-preview-filename"
                                  disabled="disabled"
                                />
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    className="btn btn-default image-preview-clear"
                                    style={{ display: "none" }}
                                  >
                                    <span className="fa fa-remove"></span>
                                    Clear
                                  </button>
                                  <div className="btn btn-success image-preview-input" style={{width:"355px"}}>
                                    <span className="fa fa-repeat"></span>
                                    <span className="image-preview-input-title">
                                      File Browse
                                    </span>
                                    <input
                                      type="File"
                                      id="image"
                                      accept="image/png, image/jpeg, image/gif"
                                      name="photo"
                                    />
                                  </div>
                                </span>
                              </div>
                            </div>
                            <span className="col-sm-4"></span>
                          </div>
                        </div> */}
                        {/* <div className="form-group">
                          <div className="row">
                          <label htmlFor="photo" className="col-sm-2 control-label">
                            Photo
                          </label>
                          <div className="col-sm-6">
                            <div
                              className="input-group image-preview"
                              data-original-title=""
                              title=""
                            >
                              <input
                                type="text"
                                className="form-control image-preview-filename"
                                disabled="disabled"
                              />
                              <span className="input-group-btn">
                                <button
                                  type="button"
                                  className="btn btn-default image-preview-clear"
                                  style={{display:"none"}}
                                >
                                  <span className="fa fa-remove"></span>
                                  Clear
                                </button>
                                <div className="btn btn-success image-preview-input">
                                  <span className="fa fa-repeat"></span>
                                  <span className="image-preview-input-title">
                                    File Browse
                                  </span>
                                  <input onChange= ""
                                    type="file"
                                    id="image"
                                    accept="image/png, image/jpeg, image/gif"
                                    name="photo"
                                  />
                                </div>
                              </span>
                            </div>
                          </div>
                          <span className="col-sm-4"></span>
                          </div>
                        </div> */}
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="remarks" className="col-sm-2 control-label">
                              Remarks
                            </label>
                            <div className="col-sm-6">
                              <input
                              
                            
                                type="text"
                                className="form-control"
                                id="remarks"
                                name="remarks"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <br />
                        <h4>Account Details </h4>
                        <br />
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="aadhar" className="col-sm-2 control-label">
                              Aadhar number
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handleAdharChange}
                              value={adhar}
                                type="text"
                                className="form-control"
                                id="aadhar"
                                name="aadhar"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="ssmid" className="col-sm-2 control-label">
                              SSSM ID
                            </label>
                            <div className="col-sm-6">
                              <input 
                              onChange={handlesssmIDChange}
                              value={sssmID}
                                type="text"
                                className="form-control"
                                id="ssmid"
                                name="ssmid"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="policy" className="col-sm-2 control-label">
                              Family SSSM ID
                            </label>
                            <div className="col-sm-6">
                              <input
                              onChange={handleFamilysssmIDChange}
                              value={FamilysssmID}
                                type="text"
                                className="form-control"
                                id="policy"
                                name="policy"
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8">
                            <input
                              type="button"
                              className="btn btn-success add-student"
                              value="Update Student"
                              onClick= {savaeData}
                           />
                          
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
