import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import axios from "axios";
import { BaseURL, schoolyearID, uniqueToken } from "../../../../api/DataApis";
import request from "../../../../Apis/request";

function Discount(props) {
  const [loading, setLoading] = useState(true);
  const [discounts, setDiscounts] = useState([]);
  const [DiscountData, setDiscountData] = useState([]);
  

  useEffect(() => {
    const FetchHostelData = async () => {
      setLoading(true);
  
      const url  = `${BaseURL}/discount/getDiscounts`;
      
  
      try {
        const res = await request({
          url: url,
          method: "POST",
          data: JSON.stringify({
            schoolyearID: schoolyearID,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${uniqueToken}`,
          },
        });
  
        console.log("res", res);
  
        if (res && res.discount) {
          setDiscountData(res.discount);
  }
  
      
  
        
      
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
  
    FetchHostelData();
    }, []);
  
    console.log("discount", DiscountData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/discount/add"
                    >
                      <i className="fa fa-plus"></i> Add a Discount
                    </Link>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>All Discount</h5>
              </div>
              <div className="card-body">
                {loading && <Loader />} {/* Show loader if loading is true */}
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>School Year</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DiscountData && DiscountData.map((discount, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{discount?.type}</td>
                          <td>{discount?.amount}</td>
                          <td>{discount?.schoolyearID}</td>
                          <td>{discount?.note}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Discount;
