import React, { useState, useEffect } from "react";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Loader from "../../../Components/Loader/Loader";
import axios from "axios";
import "./Student_Report.css";

function StudentReport() {
  const [selectedReportFor, setSelectedReportFor] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedcountry, setSelectedcountry] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedTransport, setSelectedTransport] = useState('');
  const [selectedHostel, setSelectedHostel] = useState('');
  const [schoolorclassTypes, setschoolorclassTypes] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedclasschange, setSelectedClassChange] = useState('');
  const [loading, setLoading] = useState(false);
  const [StudentData, setstudentData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [section, setSection] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [HostelData, setHostelData] = useState([]);

  const apiClasspoint = `${BaseURL}/classes/getClasses`;

  const fetchClasses = async () => {
    setLoading(true);
    var url = apiClasspoint;
    let res = await request({
      url: url,
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });
    if (res && res.classes) {
      setClasses(res.classes);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchSection = async () => {
    setLoading(true);
    const response = await axios.get(
      `${BaseURL}/section/getSections`
    );
    const data = await response.data;
    if (data) {
      setSection(data.sections);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSection();
  }, []);

  const handleReportChange = (event) => {
    setSelectedReportFor(event.target.value);
  };

  const handleValueChanges = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSchoolorclassTypes = (event) => {
    setschoolorclassTypes(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClassChange(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const fetchData = async () => {
    setLoading(true);
    const url = `${BaseURL}/report/getStudentReportData`;
    let res = await request({
      url: url,
      method: "POST",
      data: JSON.stringify({
        "schoolorclass": schoolorclassTypes,
        "reportfor": selectedReportFor,
        "schoolyearID": schoolyearID,
        "value": selectedValue,
        "classID": selectedclasschange,
        "sectionID": selectedSection
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });
    if (res && res.students) {
      setstudentData(res?.students);
    }
    setLoading(false);
  };

  const handleViewClick = () => {
    if (selectedReportFor && selectedValue && schoolorclassTypes && selectedclasschange) {
      fetchData();
    } else {
      alert("Please fill all the fields before viewing the report.");
    }
  };

  const apiCountryApi = `${BaseURL}/dashboard/getDash`;

  const fetchDataaa = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiCountryApi);
      const data = response.data;

      if (data && data?.country) {
        const countryNames = data?.country?.map(countryObj => {
          return Object.values(countryObj)[0];
        });
        setCountryName(countryNames);
      }

      if (data && data?.state) {
        const stateNames = data.state.map(stateObj => {
          return Object.values(stateObj)[0];
        });
        // setState(stateNames);
      }

      // Assuming board names are available in the same data structure as country names
      if (data && data.board) {
        const boardNames = data.board.map(boardObj => {
          return Object.values(boardObj)[0];
        });
        // setBoardName(boardNames)
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error (e.g., show error message to the user)
      setLoading(false);
    }
  };



  useEffect(() => {

    fetchDataaa();
  }, []);
  useEffect(() => {
    const FetchHostelData = async () => {
      setLoading(true);

      const url = `${BaseURL}/hostel/getHostels`


      try {
        const res = await request({
          url: url,
          method: "POST",
          data: JSON.stringify({
            schoolyearID: schoolyearID,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${uniqueToken}`,
          },
        });

        console.log("res", res);

        if (res && res.hostels) {
          setHostelData(res.hostels);
        }





      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };


    FetchHostelData();
  }, []);

  console.log("HostelData", HostelData);


  console.log("studentData", StudentData);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-12 add" style={{ marginLeft: "-11px", marginTop: "11px" }}>
                    <label>Report For</label>

                    <div className="main_part_blood">

                      <div>
                        <select value={selectedReportFor} onChange={handleReportChange} className="select_new_12" placeholder="select type">
                          <option value="Report-Type">Report For</option>
                          <option value="blood">Blood Group</option>
                          <option value="country">country</option>
                          <option value="Gender">Gender</option>
                          <option value="Transport">Transport</option>
                          <option value="Hostel">Hostel</option>
                        </select>
                      </div>

                      <div>
                        {selectedReportFor === "blood" &&
                          <>
                            <select value={selectedValue} onChange={handleValueChanges} className="select_new_12" placeholder="Blood Group">
                              <option value="blood">Select Blood</option>
                              <option value="A">A</option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B">B</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                            </select>
                            <select onChange={handleSchoolorclassTypes} className="select_new_12" placeholder="Blood Group" style={{ marginLeft: "8px" }}>
                              <option value="option5">School/Class</option>
                              <option value="School">School</option>
                              <option value="Class">Class</option>
                            </select>
                          </>
                        }
                      </div>



                      <div>
                        {selectedReportFor === "blood" && schoolorclassTypes === "Class" &&
                          <>
                            <select onChange={handleClassChange} className="select_new_12" placeholder="Select Class">
                              <option value="select_class"> Select Class</option>
                              <option value="-1">select all</option>
                              {classes.map((classes) => {
                                return (
                                  <option
                                    key={classes.classesID}
                                    value={classes.classesID}
                                  >
                                    {classes.classes}
                                  </option>
                                );
                              })}
                            </select>

                            <select value={selectedSection} onChange={handleSectionChange} className="select_new_12" placeholder=" Section" style={{ marginLeft: "7px" }}>
                              <option value="select_section">Select Section</option>
                              {section.map((section) => {
                                return (
                                  <option
                                    key={section?.sectionID}
                                    value={section?.sectionID}
                                  >
                                    {section?.section}
                                  </option>
                                );
                              })}
                            </select>

                          </>

                        }
                      </div>

                    </div>


                    <div className="main_part_blood1">
                      {selectedReportFor === "country" &&
                        <div>
                          <select value={selectedValue} onChange={handleValueChanges} className="select_new_12" placeholder="Blood Group" style={{width:"33%"}}>
                            <option value="select_country">Please Select</option>

                            {countryName?.map((country, index) => (
                            <option key={index} value={country}>{country}</option>
                          ))}
                          </select>
                          <select value="" onChange={handleSchoolorclassTypes} className="select_new_12" placeholder="Blood Group"
                            style={{ marginLeft: "7px" }}>
                            <option value="option5">School/Class</option>
                            <option value="School">School</option>
                            <option value="Class">Class</option>
                          </select>
                        </div>
                      }
                      {selectedReportFor === "country" && schoolorclassTypes === "Class" &&
                        <div>
                          <select onChange={handleClassChange} className="select_new_12" placeholder="Select Class"style={{marginLeft:"-147px"}}>
                            <option value="select_class"> Select Class</option>
                            <option value="-1">select all</option>

                            {classes.map((classes) => {
                              return (
                                <option
                                  key={classes.classesID}
                                  value={classes.classesID}
                                >
                                  {classes.classes}
                                </option>
                              );
                            })}
                          </select>
                          <select value={selectedSection} onChange={handleSectionChange} className="select_new_12" placeholder=" Section" style={{ marginLeft: "7px" }}>
                            <option value="select_section">Select Section</option>
                            {section.map((section) => {
                              return (
                                <option
                                  key={section?.sectionID}
                                  value={section?.sectionID}
                                >
                                  {section?.section}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      }
                    </div>

                    <div className="main_part_blood1">
                      {selectedReportFor === "Gender" &&
                        <div>
                          <select value={selectedValue} onChange={handleValueChanges} className="select_new_12" placeholder="Blood Group">
                            <option value="select_gender">Please Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          <select value={selectedSection} onChange={handleSchoolorclassTypes} className="select_new_12" placeholder="Blood Group" style={{ marginLeft: "7px" }}>
                            <option value="option5">School/Class</option>
                            <option value="School">School</option>
                            <option value="Class">Class</option>
                          </select>
                        </div>
                      }
                      {selectedReportFor === "Gender" && schoolorclassTypes === "Class" &&
                        <div>
                          <select onChange={handleClassChange} className="select_new_12" placeholder="Select Class">
                            <option value="select_class"> Select Class</option>
                            <option value="-1">select all</option>
                            {classes.map((classes) => {
                              return (
                                <option
                                  key={classes.classesID}
                                  value={classes.classesID}
                                >
                                  {classes.classes}
                                </option>
                              );
                            })}
                          </select>
                          <select value={selectedSection} onChange={handleSectionChange} className="select_new_12" placeholder=" Section" style={{ marginLeft: "7px" }}>
                            <option value="select_section">Select Section</option>
                            {section.map((section) => {
                              return (
                                <option
                                  key={section?.sectionID}
                                  value={section?.sectionID}
                                >
                                  {section?.section}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      }

                    </div>
                    <div className="main_part_blood1">
                      {selectedReportFor === "Transport" &&
                        <div>
                          <select value={selectedValue} onChange={handleValueChanges} className="select_new_12" placeholder="Blood Group">
                            <option value="select_transport">Please Select</option>
                            <option value="Own">Own</option>
                            <option value="Bus">Bus</option>
                            <option value="walking">walking</option>
                          </select>
                          <select value="" onChange={handleSchoolorclassTypes} className="select_new_12" placeholder="Blood Group" style={{ marginLeft: "7px" }}>
                            <option value="option5">School/Class</option>
                            <option value="School">School</option>
                            <option value="Class">Class</option>
                          </select>
                        </div>
                      }
                      {selectedReportFor === "Transport" && schoolorclassTypes === "Class" &&
                        <div>
                          <select onChange={handleClassChange} className="select_new_12" placeholder="Select Class">
                            <option value="select_class"> Select Class</option>
                            <option value="-1">select all</option>
                            {classes.map((classes) => {
                              return (
                                <option
                                  key={classes.classesID}
                                  value={classes.classesID}
                                >
                                  {classes.classes}
                                </option>
                              );
                            })}
                          </select>
                          <select value={selectedSection} onChange={handleSectionChange} className="select_new_12" placeholder=" Section" style={{ marginLeft: "7px" }}>
                            <option value="select_section">Select Section</option>
                            {section.map((section) => {
                              return (
                                <option
                                  key={section?.sectionID}
                                  value={section?.sectionID}
                                >
                                  {section?.section}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      }
                    </div>

                    <div className="main_part_blood1">
                    {selectedReportFor === "Hostel" &&
                      <div>
                        <select value={selectedValue} onChange={handleValueChanges} className="select_new_12" placeholder="Blood Group">
                          <option value="select_hostel">Please Select</option>
                          <option value="yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <select value={selectedSection} onChange={handleSchoolorclassTypes} className="select_new_12" placeholder="Blood Group" style={{marginLeft:"7px"}}>
                          <option value="option5">School/Class</option>
                          <option value="School">School</option>
                          <option value="Class">Class</option>
                        </select>
                      </div>
                    }

                     
                    {selectedReportFor === "Hostel" && schoolorclassTypes === "Class" &&
                      <div>
                        <select onChange={handleClassChange} className="select_new_12" placeholder="Select Class">
                          <option value="select_class"> Select Class</option>
                          <option value="-1">select all</option>
                          {classes.map((classes) => {
                            return (
                              <option
                                key={classes.classesID}
                                value={classes.classesID}
                              >
                                {classes.classes}
                              </option>
                            );
                          })}
                        </select>
                        <select value={selectedSection} onChange={handleSectionChange} className="select_new_12" placeholder=" Section"style={{marginLeft:"7px"}}>
                          <option value="select_section">Select Section</option>
                          {section.map((section) => {
                            return (
                              <option
                                key={section?.sectionID}
                                value={section?.sectionID}
                              >
                                {section?.section}
                              </option>
                            );
                          })}
                        </select>

                      </div>
                    }

                  </div>
                  </div>

                </div>
              </div>
              <hr style={{marginTop:"-10px"}}/>
              <button
                onClick={handleViewClick}
                className="btn btn-success"
                style={{
                  padding: "6px 20px",
                  borderRadius: "40px",
                  marginLeft: "18px",
                  marginBottom: "22px",
                  marginTop: "50px",
                  width: "15%"
                }}
              >
                View
              </button>
               


              {loading && <p>Loading...</p>}
            {!loading && StudentData && StudentData?.length > 0 && (
              <div className="card-body">
                {loading && <Loader />}
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Photo</th>
                        {/* <th>Shift Id</th> */}
                        <th>Name</th>
                        <th>Roll</th>
                        <th>Phone</th>

                      </tr>
                    </thead>
                    <tbody>
                      {StudentData &&
                        StudentData?.map((u, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <img style={{ width: "24px" }} src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png" />

                              </td>

                              <td>{u?.name}</td>
                              <td>{u?.registerNO}</td>
                              <td>{u?.phone}</td>
                              {/* <td>{u.shiftID}</td> */}


                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            </div>
          
          </div>

        </div>
      </div>


    </>
  )
}

export default StudentReport;

