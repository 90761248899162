import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import request from "../../../Apis/request";
import { Link } from "react-router-dom";

import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import "./view.css";
import Loader from "../../../Components/Loader/Loader";

function View(props) {
  let location = useLocation();
  const ViewData = location.state?.BusListmemberData;
  console.log("BusListmemberData", ViewData);

  const [ListViewData, setListViewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [BusStandData, setBusStandData] = useState([]);

  const FetchBusStandData = async () => {
    setLoading(true);
    const url = `${BaseURL}/busstand/getBusStands`;

    try {
      const res = await request({
        url: url,
        method: 'POST',
        data: JSON.stringify({ schoolyearID }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log('res', res);

      if (res && res.busStands) {
        setBusStandData(res.busStands);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchBusStandData();
  }, []);

  const fetchData = async (ViewData) => {
    const apiEndpoint = `${BaseURL}/listtmember/tMemberview`;

    console.log("function call");
    setLoading(true);

    let res = await request({
      url: `${apiEndpoint}/${ViewData.transportID}/${ViewData.deviceid}`,
      method: "POST",
      data: JSON.stringify({
        schoolyearID: schoolyearID,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });

    console.log("res", res);
    if (res && res?.transportData) {
      setListViewData(res?.transportData);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (ViewData) {
      fetchData(ViewData);
    }
  }, [ViewData]);

  const getBusStandName = (standID) => {
    const busStand = BusStandData.find((stand) => stand.busstandID === standID);
    return busStand ? busStand.standname : "Unknown Stand";
  };

  console.log("ListViewData", ListViewData);
  console.log("BusStandData", BusStandData);
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header pb-0">
                  <div className="d-flex">
                    <div className="col-sm-4 add">
                      {/* <Link
                        className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                        style={{ width: "auto" }}
                        to="/staff/add/:sid"
                      > */}
                      {/* <Link
                        className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                        to="/staff/add"
                      >
                        <i className="fa fa-plus"></i> Add Staff{" "}
                      </Link> */}
                      {/* </Link> */}
                    </div>
                  </div>
                  <div>
                    <h5>List Members</h5>
                    <hr style={{ marginTop: "20px" }} />
                  </div>
                </div>
                <div className="card-body">
                  {loading && <Loader />}
                  <div className="table-responsive">
                    <table className="display dataTable" id="advance-1">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Enroll No</th>
                          <th>Photo</th>
                          <th>Name</th>
                          <th>Bus Stand</th>
                          <th>Transport Fees</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ListViewData?.map((u, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{u?.studentID}</td>
                              <td>
                                <img
                                  style={{ width: "24px" }}
                                  src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png"
                                  alt="student"
                                />
                              </td>
                              <td>{u?.name}</td>
                              <td>{getBusStandName(u?.standID)}</td>
                              <td>{u?.tbalance}</td>
                              <td>{u?.phone}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default View;
