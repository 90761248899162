import React, { useState, useEffect } from "react";
import request from "../../../Apis/request";
import { BaseURL, uniqueToken, schoolyearID } from "../../../api/DataApis";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import BusStand from "./BusStand";
import { Navigate, useNavigate } from "react-router-dom";

function Add() {
  const [standName, setStandName] = useState([]);
  const [BusName, setBusName] = useState([]);
  const [TransportAmount, setTransportAmount] = useState([0.00]);
  // const [BusStandData, setBusStandData] = useState([]);

  // const [selectedRoute, setSelectedRoute] = useState([]);


  const [loading, setLoading] = useState(false);


  let navigate = useNavigate();


  const HandleTransportAmount = (e) => {
    setTransportAmount(e.target.value);
  }

  const HandleBusName = (e) => {
    setBusName(e.target.value);
  }

  const HandleStandName = (e) => {
    setStandName(e.target.value);
  }
  // const schoolyearID = schoolyearID;

  const AddBusStand = async () => {

    let response = await request({
      url: `${BaseURL}/busstand/addBusStand`,
      // url: 'posts',
      method: "POST",
      data: JSON.stringify({
        // "feetype_name" : FeeType,
        "schoolyearID": schoolyearID,
        "new_route": standName,
        "amount": TransportAmount,
        // "schoolyearID" : Address,
        // "note": Note
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });

    if (response.status == "200") {
      Swal.fire({
        icon: "success",
        title: "success!",
        text: "bus stand Added Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/transport/busstand');
      // var receiptID = response.receiptID;
      // var schoolyearID = response.schoolyearID;
      // var urll = `${BaseURL}/feecollection/printPreview/${schoolyearID}/${receiptID}`;
      // navigate(`/transport/busstand/busstand`);
      // window.location.href = urll;
      // fetchData();
    }
    //  alert("Data submitted successfully!!");fee_list
    // let feetypenamedata = response.fee_list
    // console.log(feetypenamedata, "feetypenamedata");
    // setFeeNameListing(response?.fee_list)
    //  console.log("response", response.fee_list);
    //  fetch("http://www.swarnkarsamajindia.com/api/add-karyakarini");
    // } else {
    // alert("data entry failed");

  }




  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Bus Stand
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{ marginTop: "-10px" }} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form class="form-horizontal" role="form" method="post" style={{ marginTop: "-23px" }}>
                        <div class="form-group">
                          <div className="row">
                            <label for="deviceid" class="col-sm-2 control-label">
                              Stand Name
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="deviceid"
                                name="deviceid"
                                onChange={HandleStandName}

                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                          <div className="row">
                            <label for="bus_name" class="col-sm-2 control-label">
                              Bus Name
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="bus_name"
                                name="bus_name"
                                onChange={BusName}

                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <label for="routename" class="col-sm-2 control-label">
                              Monthly Fees
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="routename"
                                name="routename"
                                onChange={HandleTransportAmount}

                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        {/* <div class="form-group">
                        <div className="row">
                        <label for="shiftID" class="col-sm-2 control-label">
                          Bus shift
                        </label>
                        <div class="col-sm-6">
                          <div class="input-group">
                            <select
                              name="shiftID"
                              id="shiftID"
                              class="form-control select2"
                            >
                              <option value="0">Select Bus Shift</option>
                              <option value="1">First</option>
                            </select>{" "}
                            <span class="input-group-addon btn btn-danger">
                              <a href="http://localhost/schoolcode/shift/add/class">
                                <i class="fa fa-plus"></i>
                              </a>
                            </span>
                          </div>
                        </div>
                        <span class="col-sm-4 control-label"></span>
                        </div>
                      </div> */}
                        <div class="form-group">
                          <div class="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              class="btn btn-success"
                              value="Add Bus Stand"
                              onClick={AddBusStand}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
