import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2";

    
function NewAdmissions() {
  const [NewAdmissionData, setNewAdmissionlData] = useState([]);
  
  const [loading, setLoading] = useState(false);
  // const [selectedHostelType, setSelectedhoste] = useState("");
  
                 


 
  
  const FetchNewAdmissionlData = async () => {
    setLoading(true);

    const url = `${BaseURL}/dashboard/getNewAdmissionData`
                                               
 
    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res.newAdmissionsData) {
        setNewAdmissionlData(res.newAdmissionsData);
}

    
        
      
    
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  FetchNewAdmissionlData();
  }, []);


  const DeleteFunction = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteHostelApi = `${BaseURL}/hostel/delHostel`;

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteHostelApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              schoolyearID: schoolyearID,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            FetchNewAdmissionlData();
          }
           else {
            FetchNewAdmissionlData();
          }
        } catch (error) {
          console.error("Error deleting category:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while deleting the category.",
          });
        }
      }
    });
  };

  console.log("NewAdmissionData", NewAdmissionData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
               
                    {/* <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/marks/add"
                    >
                      <i className="fa fa-plus"></i> Add a Mark{" "}
                    </Link> */}
                  </div>
                </div>
                <div>
                <h5>New Students</h5>
                  <hr />
                 
                </div>
               
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Class</th>
                        <th>section</th>
                        <th>DOB</th>
                        <th>Phone</th>
                        <th>Date of Admission</th>
                        <th>Address</th>
                
                    
                      </tr>
                    </thead>
                    <tbody>
                      {NewAdmissionData &&
                        NewAdmissionData.map((u, i) => { 
                       return (
                      <tr >
                        <td>{i+1}</td>
                        <td>{u?.name}</td>
                        <td>{u?.classes}</td>
                        <td>{u?.section}</td>
                        <td>{u?.dob}</td>
                        <td>{u?.phone}</td>
                        <td>{u?.doj}</td>
                        <td>{u?.address}</td>
                    
                     
                      </tr>
                       ); 
                     })}
                    </tbody>
                   
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewAdmissions;
