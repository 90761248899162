import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "../Active-deactive/Active.css";
import axios from "axios";

function House() {
  document.title = "House | Assign";
  const { enquiryID, classesID } = useParams();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectClassid, setSelectClassid] = useState("");
  const [loading, setLoading] = useState(false);
  const [HouseData, setHouseData] = useState([]);
  const [TransportData, setTransportData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectHouse, setSelectHouse] = useState("");
  const [selectedStudentIDs, setSelectedStudentIDs] = useState([]);
  const [parents, setParents] = useState([]);
  const [sections, setSections] = useState([]);

  const SelectClassHandler = (e) => {
    setSelectClassid(e.target.value);
    fetchData(e.target.value);
  };

  const SelectHouseHandler = (e) => {
    setSelectHouse(e.target.value);
  };

  const fetchData = async (classesID) => {
    const apiEndpoint = `${BaseURL}/student/getStudents`;

    setLoading(true);

    let url = apiEndpoint;
    if (classesID) {
      url = `${apiEndpoint}/${classesID}`;
    }

    try {
      let res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });
      setClasses(res.classes);
      if (res && res.students) {
        setTransportData(res.students);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchHouse = async () => {
    const apiEndpoint = `${BaseURL}/student/getHouse`;

    setLoading(true);

    try {
      let res = await request({
        url: apiEndpoint,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });
      if (res && res.houses) {
        setHouseData(res.houses);
      }
    } catch (error) {
      console.error("Error fetching houses:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchHouse();
  }, []);

  useEffect(() => {
    const filteredStudents = TransportData.filter(
      (student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.registerNO.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setStudents(filteredStudents);
  }, [searchQuery, TransportData]);

  const handleStudentSelection = (studentID) => {
    setSelectedStudentIDs((prevSelected) =>
      prevSelected.includes(studentID)
        ? prevSelected.filter((id) => id !== studentID)
        : [...prevSelected, studentID]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudentIDs(TransportData.map((student) => student.studentID));
    } else {
      setSelectedStudentIDs([]);
    }
  };

  const assignHouse = async (studentID) => {
    if (!studentID && selectedStudentIDs.length === 0) {
      Swal.fire("Please select at least one student.");
      return;
    }

    try {
      const response = await request({
        url: `${BaseURL}/student/assignHouse`,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
          studentID: studentID || selectedStudentIDs[0], // If studentID is provided, use it, otherwise use the first selected student
          houseID: selectHouse,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      Swal.fire("Success", "House assigned successfully.", "success");

      fetchData(selectClassid);
    } catch (error) {
      console.error("Error assigning house:", error);
      Swal.fire("Error", "Failed to assign house.", "error");
    }
  };

  const apiEndpointt = `${BaseURL}/parents/getParents`;

  const fetchParents = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: apiEndpointt,
        method: "GET",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (response && response.parents) {
        setParents(response.parents);
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParents();
  }, []);

  const getFatherName = (parentID) => {
    const parent = parents.find((p) => p.parentsID === parentID);
    return parent ? parent.father_name : 'Loading....';
  };

  const getClassName = (classID) => {
    const classItem = classes.find((c) => c.classesID === classID);
    return classItem ? classItem.classes : 'Loading....';
  };


  const fetchSection = async () => {
    setLoading(true);
    const response = await axios.get(`${BaseURL}/section/getSections`);
    const data = await response.data;
    setTimeout(() => {
      if (data) {
        setLoading(false);
        setSections(data.sections);
      }
    }, 1000);
  };

  useEffect(() => {
    fetchSection();
  }, []);

  const getSectionName = (sectionID) => {
    const section = sections.find((s) => s.sectionID === sectionID);
    return section ? section.section : "Loading....";
  };


console.log("parents", parents);


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <div
                  className="add"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>

                  <h5 style={{ marginLeft: "30px", marginTop: "15px" }}>
                    Assign House
                  </h5>
                  </div>
                  <div style={ {
                    display: "flex",
                    justifyContent: "space-between",
                    gap:"8px"}}>

                 
                  <select
                    className="form-control text-center"
                    onChange={SelectClassHandler}
                    style={{
                      borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                      border: "-1px ", position: "relative", top: "5px"
                    }}
                  >
                    <option value="">Select Class</option>
                    <option value="-1">View All</option>
                    {classes &&
                      classes.map((classItem) => (
                        <option
                          key={classItem.classesID}
                          value={classItem.classesID}
                        >
                          {classItem.classes}
                        </option>
                      ))}
                  </select>
                  <select
                    className="form-control text-center"
                    onChange={SelectHouseHandler}
                    style={{
                          borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                      border: "-1px ", position: "relative", top: "5px"
                    }}
                  >
                    <option value="">Select House</option>
                    {HouseData.map((houseItem) => (
                      <option
                        key={houseItem.house_id}
                        value={houseItem.house_id}
                      >
                        {houseItem.house}
                      </option>
                    ))}
                  </select>
                  <button style={{position:"relative",top:"5px"}} className="btn btn-primary" onClick={() => assignHouse()}>
                    Assign House
                  </button>
                  </div>
                </div>
              </div>
              <div>
                <hr style={{ marginTop: "18px" }} />
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="display dataTable" id="advance-1">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={selectedStudentIDs.length === students.length && students.length > 0}
                        />
                      </th>
                      <th>#</th>
                      <th>Photo</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Registration No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.length > 0 ? (
                      students.map((u, i) => (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedStudentIDs.includes(u.studentID)}
                              onChange={() => handleStudentSelection(u.studentID)}
                            />
                          </td>
                          <td>{i + 1}</td>
                          <td>
                            <img
                              style={{ width: "24px" }}
                              src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png"
                              alt=""
                            />
                          </td>
                          <td>{u?.name}</td>
                          <td>{getFatherName(u?.parentID)}</td>
                          <td>{getClassName(u?.classesID)}</td>
                          <td>{getSectionName(u?.sectionID)}</td>
                          <td>{u?.registerNO}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8">Please Select Class</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default House;
