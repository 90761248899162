import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2";

    
function Hostel() {
  const [HostelData, setHostelData] = useState([]);
  
  const [loading, setLoading] = useState(false);
  // const [selectedHostelType, setSelectedhoste] = useState("");
  
                 


 
  
  const FetchHostelData = async () => {
    setLoading(true);

    const url = `${BaseURL}/hostel/getHostels`
                                               
 
    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res.hostels) {
        setHostelData(res.hostels);
}

    
        
      
    
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  FetchHostelData();
  }, []);


  const DeleteFunction = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteHostelApi = `${BaseURL}/hostel/delHostel`;

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteHostelApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              schoolyearID: schoolyearID,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            FetchHostelData();
          }
           else {
            FetchHostelData();
          }
        } catch (error) {
          console.error("Error deleting category:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while deleting the category.",
          });
        }
      }
    });
  };

  console.log("HostelData", HostelData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
                  <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/hostel/add"
                    >
                      <i className="fa fa-plus"></i> Add Hostel
                    </Link>
                    {/* <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/marks/add"
                    >
                      <i className="fa fa-plus"></i> Add a Mark{" "}
                    </Link> */}
                  </div>
                </div>
                <div>
              
                  <hr />
                  <h5>All Hostels</h5>
                </div>
               
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Address</th>
                        <th>Note</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {HostelData &&
                        HostelData.map((u, i) => { 
                       return (
                      <tr>
                        <td>{i+1}</td>
                        <td>{u?.name}</td>
                        <td>{u?.htype}</td>
                        <td>{u?.address}</td>
                        <td>{u?.note}</td>
                        <td>
                        {/* <Link to="/transportmember/add">
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link> */}
                          <Link to="">
                            <i
                              className="fa fa-check-square-o"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                          <Link to={`/hostel/edit/${u?.hostelID}`}
                           state={{HostelEditData : u}}
                          
                          >
                            <i
                              className="fa fa-edit"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                          <Link onClick={(e) => e.preventDefault()}>
                                  <i
                                    onClick={() => DeleteFunction(u?.hostelID)}
                                    className="fa fa-trash delete"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </Link>
                        </td>
                      </tr>
                       ); 
                     })}
                    </tbody>
                   
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hostel;
