import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Add() {
  document.title = "Event | Add";
  let navigate = useNavigate();
  const redirect = () => {
    // let path = `/advance`;
  };

  const [TitleName, setTitleName] = useState('');
  const [loading, setLoading] = useState(false);
  const [Date, setDate] = useState('');
  const [Photo, setPhoto] = useState(null);
  const [Details, setDetails] = useState('');

  const HandleTitleChange = (e) => {
    setTitleName(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleDetailchange = (value) => {
    setDetails(value);
  };

  console.log("eventdetails", Details);

  const AddGallery = async () => {
    const formData = new FormData();
    formData.append('title', TitleName);
    formData.append('date', Date);
    formData.append('file', Photo);
    formData.append('notice', Details);
    formData.append('usertypeID', '13');

    try {
      let response = await request({
        url: `${BaseURL}/gallery/galleryAdd`,
        method: "POST",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Event Added Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(`/annoucement/gallery`);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was a problem adding the event. Please try again.",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    // If you intend to call AddEvent() on component mount, ensure the necessary data is ready
    // AddEvent();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Gallery Add
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      <button className="btn btn-success" onClick={redirect}>
                        Back
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{marginTop:"-10px"}} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{marginTop:"-20px"}}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Title
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={HandleTitleChange}
                                className="form-control"
                                id="name_id"
                                name="name"
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Date
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="date"
                                onChange={handleDateChange}
                                className="form-control"
                                id="hostelType"
                                name="HostelType"
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Photo
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="file"
                                onChange={handlePhotoChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Details
                            </label>
                            <div className="col-sm-6">
                              <ReactQuill
                                value={Details}
                                onChange={handleDetailchange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick={AddGallery}
                              value="Add Event"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
