import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";


function Add() {
  document.title = "Bus Attendance| Add";
  let navigate = useNavigate();
  const redirect = () => {
    // let path = `/advance`;

  };


  const [HostelName, setHostelName] = useState("");
  const [shift, setShift] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [BusName, setBusName] = useState("");
  const [BusStart, setBusStart] = useState("");
  const [BusEnd, setBusEnd] = useState("");
  const [DriverName, setDriverName] = useState("");
  const [DriverNumber, setDriverNumber] = useState("");
  const [BusNumber, setBusNumber] = useState("");
  const [RouteName, setRouteName] = useState([]);
  const[BusShift, setBusShift] = useState("");
  const [BusMasterData, setBusMasterData] = useState([]);
  const [RoutingListing, setRoutingListing] = useState([]);
// const [loading, setLoading] = useState(false);



  

  const HandleBusStart = (e) => {
    setBusStart(e.target.value);
}
const HandleBusEnd = (e) => {
  setBusEnd(e.target.value);
}
  const handleBusName = (e) => {
    setBusName(e.target.value);
}

const handleDriverName = (e) => {
  setDriverName(e.target.value);
}
const handleDriverNumber = (e) => {
  setDriverNumber(e.target.value);
}
const handleBusNumber = (e) => {
  setBusNumber(e.target.value);
}

const handleBusRoute = (e) => setRouteName(e.target.value);
const fetchRoute = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${BaseURL}/tmember/addTmember`);
    
    setRoutingListing(response?.data?.transports);
    // setClassID(response.data.classes);
  } catch (error) {
    console.error("Error fetching classes:", error);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  fetchRoute();
}, []);


  

  const   AddBusMaster = async () => {

    let response = await request({
      url: `${BaseURL}/busrecord/busMasteradd`,
      // url: 'posts',
      method:"POST",
      data: JSON.stringify({
        // "feetype_name" : FeeType,
        "name" : BusName,
        "start" : BusStart,
      "end" : BusEnd,
      "driver" : DriverName,
      "bus_no" : BusNumber,
      "driverMobile" : DriverNumber,
      "transport" : RouteName
        
      
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken    }`,
      }
    });
  
     if( response.status == "200" ) {
      Swal.fire({
        icon: "success",
        title: "success!",
        text: "Data Added Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      // var receiptID = response.receiptID;
      // var schoolyearID = response.schoolyearID;
      // var urll = `${BaseURL}/feecollection/printPreview/${schoolyearID}/${receiptID}`;
navigate(`/transport/busmaster/busmaster`);
      // window.location.href = urll;
      // fetchData();
    } 
          //  alert("Data submitted successfully!!");fee_list
          // let feetypenamedata = response.fee_list
          // console.log(feetypenamedata, "feetypenamedata");
          // setFeeNameListing(response?.fee_list)
                      //  console.log("response", response.fee_list);
          //  fetch("http://www.swarnkarsamajindia.com/api/add-karyakarini");
    // } else {
        // alert("data entry failed");
     
}

useEffect(() => {
  AddBusMaster();
}, []);
console.log("RouteName", RouteName);
console.log("RoutingListing", RoutingListing);
return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                    Bus Master
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      <button className="btn btn-success" onClick={redirect}>
                        Back
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{marginTop:"-10px"}} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{marginTop:"-20px"}}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                                Bus Name
                            </label>
                            <div className="col-sm-6">
                            <input type="text" onChange= {handleBusName} className="form-control" id="name_id" name="name"/>
                                
                                  {/* { classes && classes.map((classes) => { */}
                      
                        
                      
                      {/* })} */}
                                
                              
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                            Bus Start
                            </label>
                            <div className="col-sm-6">
                            <input  onChange = {HandleBusStart} 
                                    type="text"
                                    className="form-control"
                                    id="hostelType"
                                    name="HostelType"
                                    
                                  />
                                 
                            
                  
                         

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                            Bus End
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                onChange= {HandleBusEnd}
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                            Driver Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                onChange= {handleDriverName}
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                          Driver Phone Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                onChange= {handleDriverNumber}
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                          Bus Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                onChange= {handleBusNumber}
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Bus Route
                            </label>
                            <div className="col-sm-6">
                              {/* <input
                                className="form-control"
                                type="text"
                              /> */}
                  <select className="form-control" onChange={handleBusRoute}>
                        <option value="0">Select Route</option>
                        {RoutingListing.map((route, index) => (
                          <option key={index} value={route.routename}>{route.routename}</option>
                        ))}
                      </select>

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick={AddBusMaster}
                              value="Add Bus"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
