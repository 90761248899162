import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import image from "../../../assets/images/dummy_pic.jpeg";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";

function View(props) {
  const [loading, setLoading] = useState(false);
  const [HostelData, setHostelData] = useState([]);
  const [HmemberData, setHmemberData] = useState({});

  let location = useLocation();
  let HostelViewData = location.state.HostelViewData;

  console.log("HostelViewData", HostelViewData);

  const FetchHostelData = async (HostelViewData) => {
    setLoading(true);

    const url = `${BaseURL}/hmember/viewHmember/${HostelViewData?.studentID}`;

    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res.hostel) {
        setHostelData(res.hostel);
      }
      if (res && res?.hmember) {
        setHmemberData(res?.hmember);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialHostelViewData = { studentID: HostelViewData?.studentID };
    FetchHostelData(initialHostelViewData);
  }, []);

  // Find the matching hostel name
  const hostelName = HostelData.find(
    (hostel) => hostel.hostelID === HmemberData?.hostelID
  )?.name;

  console.log("HmemberData", HmemberData);
  console.log("hosteldata", HostelData);

  return (
    <>
    
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div id="printablediv">
                <section class="panel">
                  <div class="profile-view-head">
                  <a href="#">
                      <img
                        className="imagev"
                        src={image}
                        alt=""
                      />
                      </a>
                      <h5>{HostelViewData?.name}</h5>

                    
                  </div>



                  <div class="panel-body profile-view-dis">
                        <h1>Personal Information</h1>
                        <div class="row">
                            <div class="profile-view-tab">
                                <p><span>Register NO </span>: <b> {HostelViewData?.registerNO}</b></p>
                            </div>

                            <div class="profile-view-tab">
                                <p><span>Roll </span>: <b>{HostelViewData?.studentID} </b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Section </span>: <b>{HostelViewData?.sectionID}</b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Hostel Name</span>: <b>{hostelName} </b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Type </span>: </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Class Type </span>: <b>{HmemberData?.categoryID}</b> </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Hostel fee </span>: <b>{HmemberData?.hbalance} </b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Hostel Address </span>: </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Join Date </span>: <b>{HmemberData?.hjoindate} </b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Date of Birth </span>: <b>{HostelViewData?.dob}</b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Gender</span>:<b> {HostelViewData?.sex}</b> </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Blood Group</span>:<b> {HostelViewData?.bloodgroup}</b> </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Religion </span>:<b>{HostelViewData?.religion}</b> </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Email </span>: <b>{HostelViewData?.email}</b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Phone</span>: <b>{HostelViewData?.phone}</b> </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Address</span>:<b>{HostelViewData?.address}</b> </p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>State</span>: <b>{HostelViewData?.state}</b></p>
                            </div>
                            <div class="profile-view-tab">
                                <p><span>Country</span>:<b>{HostelViewData?.country}</b> </p>
                            </div>

                        </div>
                    </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default View;
