import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2";

function BusMaster() {
  const [BusMasterData, setBusMasterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState("");

  const FetchBusMasterData = async () => {
    setLoading(true);
    const url = `${BaseURL}/busrecord/index`;
    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res.transports) {
        setBusMasterData(res.transports);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchBusMasterData();
  }, []);

  const handleEdit = (id) => {
    setIds(id);
  };

  console.log("BusMasterData", BusMasterData);

  const handleDelete = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteBusMasterApi = `${BaseURL}/busrecord/busMasterDelete`;
      

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteBusMasterApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
               schoolyearID: schoolyearID
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            FetchBusMasterData();
          } else {
            alert("Error");
          }
        } catch (error) {
          console.error("Error deleting class:", error);
        }
      }
    });
  };  



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/transport/busmaster/add"
                    >
                      <i className="fa fa-plus"></i> Add a Bus Master{" "}
                    </Link>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>All Member</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                      <th>Sr.No.</th>
                        <th>Bus Name</th>
                        <th>Bus Start</th>
                        <th>Bus End</th>
                        <th>Bus No</th>
                        <th>Driver Name</th>
                        <th>Driver Phone No</th>
                        {/* <th>Bus No</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {students &&
                        students.map((u, i) => { */}
                      {/* return ( */}
                      {BusMasterData && 
                      BusMasterData.map((u,i)=> {
return (
  <tr>
  <td>{i+1}</td>
  <td>{u?.busName}</td>
  <td>{u?.busStart}</td>
  <td>{u?.busEnd}</td>
  <td>{u?.busNo}</td>
  <td>{u?.busDriver}</td>
  <td>
    
 {u?.driverMobile}

  </td>
 <td>
                       
                          <Link to={`/transport/busmaster/view/${u?.busId}`}

                      state={{ BusMasterViewData : u }} 
                          >
                            <i
                              className="fa fa-check-square-o"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                          <Link to={`/transport/busmaster/edit/${u?.busId}`}
                          state={{ BusMasterEditData : u }} 
                          >
                            <i
                              className="fa fa-edit"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                          <Link onClick={(e) => { e.preventDefault(); handleDelete(u?.busId); }}>
    <i className="fa fa-trash delete"></i>
  </Link>
                        </td>
  </tr>
)

                      })
                      }
                     
                   
                    </tbody>
                    <tfoot>
                     
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusMaster;
