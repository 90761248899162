import React, { useState, useEffect } from "react";
import { BaseURL, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";

function Late_Report() {
  const [Date, setSelectedDate] = useState('');
  const [Students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleDateChange = (event) => {
    const value = event.target.value;
    setSelectedDate(value);
  };

  const formatDateForInput = (dateString) => {
    const parts = dateString.split('-');
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };

  const fetchLateReport = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await request({
        url: `${BaseURL}/report/getLateReportData`,
        method: "POST",
        data: JSON.stringify({
          "date": formatDateForInput(Date), // Format the selected date
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response.lateData) {
        setStudents(response.lateData);
      } else {
        setError('No data found.');
      }
    } catch (error) {
      console.error("Error fetching late report data:", error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleRenderList = () => {
    if (Date) {
      fetchLateReport();
    } else {
      setError('Please select a date.');
    }
  };
console.log("lateReportData",Students);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <h1 className="page-title"></h1>
            <div className="portlet light bordered">
              <div className="portlet-title">
                <div className="caption font-dark">
                  <h4 className="caption-subject bold uppercase mx-3">
                    Late Report
                  </h4>
                </div>
              </div>
              <div className="portlet-body mx-3">
                <hr style={{ marginTop: "-10px" }} />
                <div className="row">
                  <div className="col-sm-12">
                    <form
                      className="form-horizontal"
                      role="form"
                      method="post"
                      style={{ marginTop: "-23px" }}
                    >
                      <div className="row px-3">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-2">
                              <div className="form-group">
                                <label
                                  htmlFor="reportDate"
                                  className="control-label"
                                >
                                  Report Date
                                </label>
                                <input
                                  type="date"
                                  id="reportDate"
                                  value={Date}
                                  onChange={handleDateChange}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <input
                                  className="mt-4 btn btn-success"
                                  type="button"
                                  value="Get Report"
                                  onClick={handleRenderList}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="table-responsive">
                    <table className="table display dataTable" id="late-report-table">
                      <thead>
                        <tr>
                          <th>sr. no</th>
                          <th>Name</th>
                          <th>Enroll</th>
                          <th>Date</th>
                          <th>Enter Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Students.length > 0 ? (
                          Students.map((student, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{student?.Name}</td>
                              <td>{student.card_1 || student.card_2 ? `${student.card_1} ${student.card_2}` : 'No data in card_1 or card_2'}</td>
                              <td>{student?.submit_date}</td>
                              <td>{student?.submit_time}</td>
                              <td>{student?.in_out}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">No data available.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Late_Report;
