import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../Components/BreadCrumb/Breadcrumb";
import Loader from "../../../Components/Loader/Loader";
import Swal from "sweetalert2";
import axios from "axios";
import { BaseURL, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";

function BusLiveTrack() {
  const [RouteData, setRouteData] = useState([]);
  const [loading, setLoading] = useState(false);

  const apiEndpoint = `${BaseURL}/transport/busList`;

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: apiEndpoint,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response?.transports) {
        setRouteData(response?.transports);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log("RouteData", RouteData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div>
                  <hr style={{ marginTop: "12px" }} />
                </div>
                <div className="row" style={{padding:"5px"}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px",paddingLeft:"20px" }}
                    >
                      <button
                        className="drop-marg12"
                        style={{
                          marginLeft: "-8px",
                          border: "1px solid black",
                          borderRadius: "27%",
                          background: "white",
                          height: "32px",
                        }}
                      >
                        {" "}
                        Copy
                      </button>
                      <button
                        className="drop-marg12"
                        style={{
                          border: "1px solid black",
                          borderRadius: "27%",
                          background: "white",
                          height: "32px",
                        }}
                      >
                        {" "}
                        Excel
                      </button>
                      <button
                        className="drop-marg12"
                        style={{
                          border: "1px solid black",
                          borderRadius: "27%",
                          background: "white",
                          height: "32px",
                        }}
                      >
                        {" "}
                        CSV
                      </button>
                      <button
                        className="drop-marg12"
                        style={{
                          border: "1px solid black",
                          borderRadius: "27%",
                          background: "white",
                          height: "32px",
                        }}
                      >
                        {" "}
                        PDF
                      </button>
                    </div>
                <div className="">
                  <input
                    className="drop-marg_searchs12"
                    type="text"
                    placeholder="search here"
                    value=""
                    style={{ position: "relative" }}
                  />
                </div>
                  </div>
                </div>

              </div>
              <div className="card-body">
                <hr style={{ marginTop: "2px" }} />
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Bus Name</th>
                        <th>Device Id</th>
                        <th>Route Name</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {RouteData &&
                        RouteData?.map((u, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{u?.bus_name}</td>
                              <td>{u?.deviceid}</td>
                              <td>{u?.routename}</td>
                              <td>
                                <Link
                                  to={`/transport/buslivetrack/view/${u?.transportID}/${u?.deviceid}`}
                                  state={{ BusLiveTrackView: u }}
                                >
                                  <i
                                    className="fa fa-check-square-o"
                                    style={{ fontSize: "18px" }}
                                  ></i>{" "}
                                </Link>
                                {/* <Link to={`/annoucement/notice/edit/${u?.noticeID}`}
                          state = {{NoticeEditData : u}}
                          > */}
                                {/* <i
                              className="fa fa-edit"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link> */}

                                <Link>
                                  <i className="fa fa-trash delete"></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* Render pagination */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusLiveTrack;
