import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import request from '../../../Apis/request';
import { schoolyearID, uniqueToken, BaseURL } from '../../../api/DataApis';
import axios from 'axios';

// Component to render the student list
const StudentList = ({ students }) => {
  return (
    <div className="card-body" style={{ marginLeft: "-19px" }}>
      <div className="table-responsive">
        <table className="table display dataTable" id="advance-1">
          <thead>
            <tr>
              <th>sr. no</th>
              <th>Photo</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Class</th>
              <th>Enroll</th>
              <th>Date</th>
              <th>Enter</th>
              <th>Exit</th>
              <th>In Text</th>
              <th>Out Text</th>
      
          
            </tr>
          </thead>
          <tbody>
            {students && students.map((u, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
              <img style={{width:"24px"}} src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png" />
                              </td>
                <td>{u?.studentName}</td>
                <td>{u?.studentID}</td>
               <td>{u?.phone}</td>
                <td>{u?.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SMSReport = () => {
  const [selectedReportType, setselectedReportType] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [Date, setDate] = useState('');
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Students, setStudents] = useState([]);
  const [classs, setClasss] = useState([]);
  const [section, setSection] = useState([]);

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/classes/getClasses`);
      setClasss(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchStudents = async () => {
    setLoading(true);
    const apiEndpoint = `${BaseURL}/report/getSmsReportData`;

    try {
      const response = await request({
        url: apiEndpoint,
        method: "POST",
        data: JSON.stringify({
       "classID" : selectedClass,
    //  "sectionID" : selectedSection,
      "type" : selectedReportType,
      "date" : "09-07-2024",
      "schoolyearID" : schoolyearID
      }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response?.smsReport) {
        setStudents(response?.smsReport);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReportType = (event) => {
    setselectedReportType(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleDateChange = (event) => {
    const inputDate = event.target.value;
    const formattedDate = formatDate(inputDate);
    setDate(formattedDate);
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };

  const handleRenderList = async () => {
    if (selectedClass && Date) {
      await fetchStudents();
      setShowList(true);
    } else {
      alert("Please select the essential options");
    }
  };

  const fetchSection = async () => {
    // props.setProgress(10)
    setLoading(true);
    const response = await axios.get(
      `${BaseURL}/section/getSections`
    );
    // props.setProgress(30)
    const data = await response.data;
    // props.setProgress(80)
    setTimeout(() => {
      if (data) {  
        setLoading(false);
        setSection(data.sections); 
      }
      // props.setProgress(100)
    }, 1000);
  };

  useEffect(() => {
    fetchSection();
  }, []);

console.log("students", Students );
console.log("sections", section);
  return (
    <>
    
    <div className='container-fluid'>
      <div className='row'>
         <div className='col-sm-12'>
         <div className='card'>
      <div className="portlet-body mx-3">
        <h4>SMS  Report</h4>
        <hr style={{ marginTop: "0px" }} />
        <div className="row">
          <div className="col-sm-12">
            <form
              className="form-horizontal"
              role="form"
              method="post"
              style={{ marginTop: "-20px" }}
            >
              <div className="row px-3">
                <div className="col-md-12">
                  <div className="row ">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label
                          style={{ marginLeft: "-15px", fontSize: "14px" }}
                          htmlFor="s2id_autogen1"
                          className="control-label"
                        >
                          Attendance Type{" "}
                        </label>
                        <select
                        
                          onChange={handleReportType}
                          style={{ marginLeft: "-20px" }}
                          name="examID"
                          id="examID"
                          className="form-control select2 select2-offscreen"
                          tabIndex="-1"
                        >
                          <option value="0" defaultValue="selected">
                            Select Report Type
                          </option>
                          <option value="campusReport">Campus Report </option>
                          <option value="BusReport">Bus Report</option>
                      
                        </select>{" "}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label
                          htmlFor="s2id_autogen2"
                          className="control-label"
                          style={{ marginLeft: "20px" }}
                        >
                          Class{" "}
                        </label>
                        <select
                          value={selectedClass}
                          onChange={handleClassChange}
                          style={{ marginLeft: "-10px" }}
                          name="classesID"
                          id="classesID"
                          className="form-control select2 select2-offscreen"
                          tabIndex="-1"
                        >
                            <option value="0" defaultValue="selected">
                            Select class
                          </option>
                          <option value="-1" >
                            Select All
                          </option>
                          {classs?.map((classes) => (
                            <option
                              key={classes.classesID}
                              value={classes.classesID}
                            >
                              {classes.classes}
                            </option>
                          ))}
                        </select>{" "}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label
                          style={{ marginLeft: "10px" }}
                          className="controlhhh-label"
                        >
                          Section
                        </label>
                        <select
                          value={selectedSection}
                          onChange={handleSectionChange}
                          style={{ marginLeft: "-10px" }}
                          name="sectionID"
                          id="sectionID"
                          className="form-control select2 select2-offscreen"
                          tabIndex="-1"
                        >
                          <option value="0" defaultValue="selected">Select Section</option>
                          {section && section?.map((u) =>  ( 
                        
                          <option key ={u?.sectionID}
                          value={u.sectionID}
                          >{u?.section}</option>
                         ))} 

                        </select>{" "}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label
                          style={{ marginLeft: "10px" }}
                          className="control-label"
                        >
                          Date
                        </label>
                        <input
                          type="date"
                        
                          onChange={handleDateChange}
                          style={{ marginLeft: "-10px" }}
                          name="Date"
                          id="Date"
                          className="form-control select2 select2-offscreen"
                          tabIndex="-1"
                        ></input>{" "}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label
                          htmlFor="s2id_autogen4"
                          className="control-label"
                        ></label>
                        <input
                          className="mt-4 btn btn-success"
                          type="button"
                          value="View Report"
                          aria-hidden="true"
                          onClick={handleRenderList}
                          
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showList && <StudentList students={Students} />}
                <div className="col-md-2 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        {/* <button
                          type="submit"
                          className="btn btn-success col-md-12 col-xs-12"
                          style={{ marginTop: "20px" }}
                        >
                          Mark
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
         </div>
      </div>
    </div>

    </>
  );
};

export default SMSReport;
