import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";


function Add() {
  document.title = "Bus Attendance| Add";
  let navigate = useNavigate();
  const redirect = () => {
    // let path = `/advance`;

  };


  const [HostelName, setHostelName] = useState("");
  const [shift, setShift] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [DeviceID, setDeviceID] = useState("");
  const [BusName, setBusName] = useState("");
  const [RouteName, setRouteName] = useState([]);
  const[BusShift, setBusShift] = useState("");
  

  const HandleDeviceid = (e) => {
    setDeviceID(e.target.value);

  }
  const handleBusName = (e) => {
    setBusName(e.target.value);
}

const handleRouteName = (e) => {
  setRouteName(e.target.value);
}
const handleBusShift = (e) => {
  setBusShift(e.target.value);
}

const fetchShift = async () => {
  // props.setProgress(10);
  setLoading(true);
  await axios
    .get(`${BaseURL}/shift/getShift`)
    .then(async (response) => {
      // props.setProgress(30);
      const data = await response.data;
      // props.setProgress(80);
      setTimeout(() => {
        if (data) {
          setLoading(false);
          setShift(data?.shift);
        }
        // props.setProgress(100);
      }, 1000);
    });
};

useEffect(() => {
  fetchShift();
}, []);


  

  const   AddBus = async () => {

    let response = await request({
      url: `${BaseURL}/transport/busadd`,
      // url: 'posts',
      method:"POST",
      data: JSON.stringify({
        // "feetype_name" : FeeType,
        "deviceid" : DeviceID,
        "bus_name": BusName,
        "routename" : RouteName,
        "shiftID": BusShift,
        
      
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken    }`,
      }
    });
  
     if( response.status == "200" ) {
      Swal.fire({
        icon: "success",
        title: "success!",
        text: "Bus Added Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      // var receiptID = response.receiptID;
      // var schoolyearID = response.schoolyearID;
      // var urll = `${BaseURL}/feecollection/printPreview/${schoolyearID}/${receiptID}`;
navigate(`/transport`);
      // window.location.href = urll;
      // fetchData();
    } 
          //  alert("Data submitted successfully!!");fee_list
          // let feetypenamedata = response.fee_list
          // console.log(feetypenamedata, "feetypenamedata");
          // setFeeNameListing(response?.fee_list)
                      //  console.log("response", response.fee_list);
          //  fetch("http://www.swarnkarsamajindia.com/api/add-karyakarini");
    // } else {
        // alert("data entry failed");
     
}

useEffect(() => {
  AddBus();
}, []);

return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                    Bus Details
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      <button className="btn btn-success" onClick={redirect}>
                        Back
                      </button>
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{marginTop:"-10px"}} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{marginTop:"-20px"}}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                              Device ID
                            </label>
                            <div className="col-sm-6">
                            <input type="text" onChange= {HandleDeviceid} className="form-control" id="name_id" name="name"/>
                                
                                  {/* { classes && classes.map((classes) => { */}
                      
                        
                      
                      {/* })} */}
                                
                              
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen1"
                              className="col-sm-2 control-label"
                            >
                            Bus Name
                            </label>
                            <div className="col-sm-6">
                            <input  onChange = {handleBusName} 
                                    type="text"
                                    className="form-control"
                                    id="hostelType"
                                    name="HostelType"
                                    
                                  />
                                 
                            
                  
                         

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                            Route Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                onChange= {handleRouteName}
                                
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="s2id_autogen2"
                              className="col-sm-2 control-label"
                            >
                              Bus Shift
                            </label>
                            <div className="col-sm-6">
                              {/* <input
                                className="form-control"
                                type="text"
                              /> */}
                              <select className="form-control"  onChange={handleBusShift} >
                                <option value="0" >select shift</option>
                                {shift &&
                      shift?.map((classItem) => (
                        <option
                          key={classItem?.shiftID}
                          value={classItem?.shiftID}
                        >
                          {classItem?.shift_name}
                        </option>
                      ))}
                                </select>

                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick={AddBus}
                              value="Add Bus"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
