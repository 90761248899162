import React, { Fragment, useEffect, useState } from 'react';
import { Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import { H6, Image, LI, UL, P } from '../../AbstractElements';
import man from '../../assets/images/dashboard/1.png';
import axios from 'axios';
import { BaseURL, uniqueToken } from '../../api/DataApis';

const Profile = () => {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
        setName(localStorage.getItem('Name'));
    }, [setProfile, setName]);

    const [adminData, setAdminData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [singleAdmin, setSingleAdmin] = useState(null);
  
    const fetchAdmin = async () => {
      // props.setProgress(10);
      setLoading(true);
      await axios
        .get(`${BaseURL}/systemadmin/listSystemAdmin`)
        .then(async (response) => {
          // props.setProgress(30);
          const data = await response.data;
          // props.setProgress(80);
          setTimeout(() => {
            if (data) {
              setLoading(false);
              setAdminData(data?.systemadmins);
            }
            // props.setProgress(100);
          }, 1000);
        });
    };
  
    useEffect(() => {
      fetchAdmin();
    }, []);
    useEffect(() => {
        if (adminData.length > 0) {
          // Set a specific object from the adminData array
          // For example, setting the first admin object
          setSingleAdmin(adminData[0]);
        }
      }, [adminData]);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <a className="setting-primary" href="#javascript">
                    <Settings />
                </a>
                <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>
                <Image attrImage={{ 
  className: 'img-90 rounded-circle', 
  src: authenticated ? `${BaseURL}/uploads/images/${singleAdmin?.photo}` : profile, 
  alt: '' 
}} />
                    <div className="badge-bottom">
                        <div className="badge badge-primary">New
                        </div>
                    </div>
                    <h6>{singleAdmin?.name} </h6>
                </Link>
                <P attrPara={{ className: 'mb-0 font-roboto' }} >Keendroid Pvt Limited</P>
                <UL attrUL={{ className: 'flex-row simple-list' }}>
                   </UL>
            </div>
        </Fragment >
    );
};

export default Profile;