import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { BaseURL } from "../../../api/DataApis";

function View() {
  document.title = "Bus Route | View";

  const [routeData, setRouteData] = useState([]);
  const [map, setMap] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

   useEffect(() =>  {
    const googleMapsScript = document.createElement("script");
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDZW0zTKDKdxBG1eC5ACKsR1Gp9PcduvKo`;
    googleMapsScript.async = true;
    window.document.body.appendChild(googleMapsScript);
    googleMapsScript.addEventListener("load", () => {
      const newMap = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 26.922070, lng: 75.778885 },
        zoom: 8,
      });
      setMap(newMap);
    });

    return () => {
      window.document.body.removeChild(googleMapsScript);
    };
  }, []);

  const handleRouteClick = (index) => {
    setSelectedRoute(index);
    const selectedRouteData = routeData[index];
    const start = selectedRouteData[0];
    const end = selectedRouteData[selectedRouteData.length - 1];
    const routePath = new window.google.maps.Polyline({
      path: [{ lat: start.lat, lng: start.lng }, { lat: end.lat, lng: end.lng }],
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    routePath.setMap(map);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const device = "404704100062969";
    const routedate = selectedDate.toLocaleDateString();

    axios
      .post(`${BaseURL}/busroute/busRouteView/435/404704100062969`, {  date:"26-06-2024" })
      .then((response) => {
        if (response.data.length > 0) {
          setRouteData(response.data);
          console.log(response.data);
        } else {
          setRouteData([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div Id="hide-table">
              <h1 className="page-title">Bus Route View</h1>
              <div id="example1_wrapper" className="dataTables_wrapper form-inline no-footer">
                <div className="row">
                  <div className="col-sm-12">
                    <form className="form-horizontal" role="form" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="routedate" className="col-sm-5 control-label">
                            Route Date
                          </label>
                          <div className="col-sm-7">
                            <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="dd/MM/yyyy"
                            />
                            <input type="hidden" className="form-control" id="device" name="device" value="404701099354424" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-offset-2 col-sm-12 add-class">
                            <input type="submit" id="RouteFind" className="btn btn-success" value="Find Route" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div id="mapDiv">
                          <div id="routebox" className="col-sm-3">
                            <ul id="routeList" style={{ textAlign: "center" }}>
                              {routeData.length > 0 ? (
                                routeData.map((route, index) => (
                                  <li key={index} onClick={() => handleRouteClick(index)}>
                                    {route.name}
                                  </li>
                                ))
                              ) : (
                                <li>No Record Found</li>
                              )}
                            </ul>
                            <div style={{ display: "none" }} id="routeData"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div id="map" style={{ height: "500px", width: "100%" }}>
      
      </div>
    </>
  );
}

export default View;