import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BaseURL, schoolyearID, uniqueToken } from '../../../api/DataApis';
import request from '../../../Apis/request';
import Swal from 'sweetalert2';

const BusStand = () => {
  
  const [loading, setLoading] = useState(false);
  const [BusStandData, setBusStandData] = useState([]);

  const FetchBusStandData = async () => {
    setLoading(true);
    const url = `${BaseURL}/busstand/getBusStands`;

    try {
      const res = await request({
        url: url,
        method: 'POST',
        data: JSON.stringify({ schoolyearID }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log('res', res);

      if (res && res.busStands) {
        setBusStandData(res.busStands);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchBusStandData();
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (results) => {
      const DeleteBusStandApi = `${BaseURL}/busstand/deleteBusStand/${id}`;
  
      if (results.value) {
        try {
          const res = await request({
            url: DeleteBusStandApi,
            method: 'POST',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${uniqueToken}`,
            },
          });
  
          if (res.status === "200") {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'Data Deleted Successfully...',
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to delete data...',
              showConfirmButton: false,
              timer: 1500,
            });
          }
          FetchBusStandData();
        } catch (error) {
          console.error('Error deleting data:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'An error occurred while deleting the data...',
            showConfirmButton: false,
            timer: 1500,
          });
          FetchBusStandData();
        }
      }
    });
  };
  
  console.log('BusStandData', BusStandData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="col-sm-4 add">
                  <Link
                    className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                    style={{ width: 'auto' }}
                    to="/transport/busstand/add"
                  >
                    <i className="fa fa-plus"></i> Add Bus Stand
                  </Link>
                </div>
                <div className="d-flex"></div>
                <div>
                  <hr />
                </div>
                <h5>Bus Stands</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>Sr.no</th>
                        <th>Stand Name</th>
                        <th>Bus No.</th>
                        <th>Monthly Fees</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {BusStandData &&
                        BusStandData.map((u, i) => (
                          <tr key={u.busstandID}>
                            <td>{i + 1}</td>
                            <td>{u.standname}</td>
                            <td>{u.busID}</td>
                            <td>{u.amount}</td>
                            <td>
                              <Link to={``}>
                                <i className="fa fa-edit" style={{ fontSize: '18px' }}></i>
                              </Link>
                              <Link to={``}>
                                <i className="fa fa-eye" style={{ fontSize: '18px' }}></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDelete(u.busstandID);
                                }}
                              >
                                <i className="fa fa-trash delete"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusStand;
