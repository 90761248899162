import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from '../../../api/DataApis';
import Photo1 from "../../../assets/images/dummy_pic.jpeg";


// import { useParams  } from "react-router-dom";

function View(props) {
  document.title = "System Admin | View";
  let navigate = useNavigate();
  let location = useLocation();
  const params = useParams();

  let SystemViewData = location.state?.SystemViewData;
  

  console.log(SystemViewData, "SystemViewData");
  const [loading, setLoading] = useState(false);
  const [EnquiryyData, setEnquiryyData] = useState({});
  const [EnquiryStatus, setEnquiryStatus] = useState('Open');
  const [EnquiryExtraDetails, setEnquiryExtraDetails] = useState([]);

  const fetchData = async () => {
    const apiEndpoint = `${BaseURL}/enquiry/viewEnquiries`;

    console.log("function call");
    //https://demo.keendroid.in/student/viewStudent/${u.studentID}/${u.classesID}
    // props.setProgress(10);
    setLoading(true);
    // console.log("enquireID", Enquiredata.enquiryID);
    // console.log("classId", Enquiredata.classesID);
  let res = await request({
      url:`${apiEndpoint}/3`,
      
      // url: 'posts',
      method:"POST",
      data: JSON.stringify({
        "schoolyearID":schoolyearID,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
     }
    });
    console.log("res",res)
    if (res && res.student) {

      const statusString = res.student.status.toString(); // Convert to string if needed
      if (statusString === '0') {
          setEnquiryStatus('Open');
      } else if (statusString === '1') {
          setEnquiryStatus('Closed');
      }

      setEnquiryyData(res.student);
      setEnquiryExtraDetails(res.detail);
       }
     return [];
}
useEffect(() => {
  fetchData ();
}, []);
console.log("SystemViewData", SystemViewData);
console.log("enquiry-status", EnquiryStatus);
console.log("extra-enquiryData", EnquiryExtraDetails);

  return (
    <>
 
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div id="printablediv">
                <section className="panel">
                  {/* <h4 className="mx-3">Student Details</h4>
                    <hr /> */}
                  {/* <div className="profile-view-head">
                    <a href="#">
                      <img
                        className="imagev"
                        src="http://localhost/schoolcode/uploads/images/defualt.png"
                        alt=""
                      />
                    </a>

                    <h5>Manan choudhary</h5>
                    <p>Class II</p>
                  </div> */}
                  <div className="panel-body profile-view-dis">

                  <div className='text-center'>
                  {/* <img style={{marginTop:"39px",marginBottom:"40px"}} className='view_icon_img' src={Photo1} alt="" srcset="" /> */}
                  <img src={`${BaseURL}/uploads/images/${SystemViewData?.photo}`} alt="User Photo" style={{marginTop:"39px",marginBottom:"40px"}} />
                  </div>
                    <h3>Personal Information</h3>
                    <div className="row">
                      <div className="profile-view-tab">
                        <p>
                          <span>Name </span>: <b>{SystemViewData?.name}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Date of Birth</span>:<b>{SystemViewData?.dob}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span> Joining Date </span>:<b> {SystemViewData?.jod}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Gender</span>:   <b>{SystemViewData?.sex}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Religion </span>: <b>{SystemViewData?.religion}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Email</span>:<b>{SystemViewData?.email}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Phone</span>:<b>{SystemViewData?.phone}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Address </span>:<b>{SystemViewData?.address}</b>
                        </p>
                      </div>
                      <div className="profile-view-tab">
                        <p>
                          <span>Username</span>: <b>{SystemViewData?.username}</b>
                        </p>
                      </div>
                                         
                   
                      
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default View;
