import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import "bootstrap-icons/font/bootstrap-icons.css";

// Function to convert date from yyyy-mm-dd to dd-mm-yyyy
const formatDate = (date) => {
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

// Function to convert date from dd-mm-yyyy to yyyy-mm-dd
const parseDate = (date) => {
  const [day, month, year] = date.split('-');
  return `${year}-${month}-${day}`;
};

function View(props) {
  const [TransportData, setTransportData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date().toISOString().split('T')[0]));
  const [loading, setLoading] = useState(false);

  let location = useLocation();
  const BusViewData = location.state?.BusAttendanceViewData;

  useEffect(() => {
    if (BusViewData) {
      // Fetch data based on today's date when the component mounts
      FetchTransportData(BusViewData, selectedDate);
    }
  }, [BusViewData, selectedDate]);

  const FetchTransportData = async (BusViewData, date) => {
    setLoading(true);

    const url = BusViewData?.transportID
      ? `${BaseURL}/transport/busView/${BusViewData?.transportID}/${BusViewData?.deviceid}`
      : `${BaseURL}/transport/busView/${BusViewData?.transportID}/${BusViewData?.deviceid}`;

    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
          date: date // Date sent in dd-mm-yyyy format
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (res && res?.transportData) {
        setTransportData(res?.transportData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(formatDate(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    FetchTransportData(BusViewData, selectedDate);
  };
console.log("BusViewData", BusViewData);
console.log("TransportData", TransportData);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-12 add">
                    <form className="form-horizontal" role="form" method="post" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="routedate" className="col-sm-2 control-label">
                            <h5>Transport</h5>
                          </label>
                          <div className="form-group col-sm-3">
                            <input
                              type="date"
                              value={parseDate(selectedDate)}
                              placeholder="Select Date"
                              className="form-control"
                              id="routedate"
                              name="routedate"
                              onChange={handleDateChange}
                              style={{ marginTop: "-24px" }}
                            />
                          </div>
                      
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>IN</th>
                        <th>Out</th>
                        <th>IN Time</th>
                        <th>Out Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TransportData &&
                        TransportData.map((u, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{u?.Name}</td>
                            <td>
                              {u?.in_out === "In" ? (
                                <i className="bi bi-check"></i>
                              ) : (
                                <i className="bi bi-x text-danger"></i>
                              )}
                            </td>
                            <td>
                              {u?.in_out === "Out" ? (
                                <i className="bi bi-check"></i>
                              ) : (
                                <i className="bi bi-x text-danger"></i>
                              )}
                            </td>
                            <td>{u?.submit_time}</td>
                            <td>{u?.submit_time}</td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default View;
