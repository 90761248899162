import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseURL, uniqueToken, schoolyearID } from "../../../api/DataApis";
import request from "../../../Apis/request";
import { Color } from "../../../Constant";
import Swal from "sweetalert2";

function Issue() {
  const [BookIssueData, setBookIssueData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [filteredData, setFilteredData] = useState([]); // State for filtered data

  const FetchIssueBooksData = async () => {
    setLoading(true);

    const url = `${BaseURL}/issue/issueList`;

    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res?.issues) {
        setBookIssueData(res?.issues);
        setFilteredData(res?.issues); // Initialize filteredData with all data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchIssueBooksData();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery) {
      const filtered = BookIssueData.filter((item) =>
        item?.lID?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(BookIssueData); // Show all data if searchQuery is empty
    }
  };

  const ReturnBookFunction = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, Return it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const ReturnBookApi = `${BaseURL}/issue/bookReturn`;

      if (results.value) {
        try {
          const res = await request({
            url: `${ReturnBookApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              schoolyearID: schoolyearID,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.Status === "200") {
            Swal.fire({
              icon: "success",
              title: "Returned!",
              text: "Book Returned Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            FetchIssueBooksData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to Return Book.",
            });
          }
          FetchIssueBooksData();
        } catch (error) {
          console.error("Error deleting Member:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while deleting the Member.",
          });
        }

      }
    });
  };



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/library/issue/add"
                    >
                      <i className="fa fa-plus"></i> Issue a Book{" "}
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-lg-offset-3 list-group"
                  style={{ marginLeft: "30%" }}
                >
                  <div className="list-group-item list-group-item-warning">
                    <form
                      className="form-horizontal"
                      role="form"
                      onSubmit={handleSearch} // Attach search handler
                    >
                      <div className="form-group">
                        <div className="row p-3">
                          <label htmlFor="lid" className="control-label">
                            Library ID{" "}
                          </label>
                          <div className="col-sm-6" style={{ width: "45%" }}>
                            <input
                              type="text"
                              className="form-control"
                              style={{borderColor: "#198754"}}
                              id="lid"
                              name="lid"
                              value={searchQuery} // Bind to state
                              onChange={(e) => setSearchQuery(e.target.value)} // Update state on change
                            />
                          </div>
                          <div className="col-sm-3">
                            <input
                              type="submit"
                              className="btn btn-success iss-mar"
                              value="Search"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>All Issue Books</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead className="bg-primary">
                      <tr>
      <th>#</th>
      <th>Book</th>
      <th>Author</th>
      <th>Serial No</th>
      <th>Student Lib ID</th>
      <th>Class</th>
      <th>Due Date</th>
      <th>Status</th>
      <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData &&
                        filteredData.map((u, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{u?.book}</td>
                              <td>{u?.author}</td>
                              <td>{u?.serial_no}</td>
                              <td>{u?.lID}</td>
                              <td>{u?.class}</td>
                              <td>{u?.due_date}</td>
                              <td>
                                <b>{u?.return_date ? "Returned" : "Issued"}</b>
                              </td>
                              <td>
                                <Link
                                  to={`/library/issue/edit/${u?.issueID}`}
                                  state={{
                                    IssueEditData: u,
                                  }}
                                >
                                  <i className="fa fa-edit edit"></i>
                                </Link>
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    ReturnBookFunction(u?.issueID);
                                  }}
                                >
                                  <i className="fa fa-reply-all "></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {loading && <div>Loading...</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Issue;
