import React, {useState, useEffect} from "react";
import { BaseURL, uniqueToken,  schoolyearID} from "../../../api/DataApis";
import { Link, useNavigate } from "react-router-dom";
import request from "../../../Apis/request";
import Swal from "sweetalert2";



function Add() {
  let navigate = useNavigate();
  const [BooksData, setBooksData] = useState([]);
  const [LibraryID, setLibraryID] =  useState([]);
  const [ SelectedBook, setSelectedBook] =  useState([]);
  const [SerialNo, SetSerialNo] =  useState([]);
  const [due_date, setDue_date] =  useState([]);
  const [Note, setNote] =  useState([]);
  
  const [loading, setLoading] = useState(false);
  // const [selectedHostelType, setSelectedhoste] = useState("");
  
                 
const HandleLibraryIDChange = (e)=> {
  setLibraryID(e.target.value);
}
const HandleBookChange = (e)=> {
  setSelectedBook(e.target.value);
}
const HandleSerialNumberChange = (e)=> {
  SetSerialNo(e.target.value);
}
const HandleDueDateChange = (e)=> {
  setDue_date(e.target.value);
}
const HandleNoteChange = (e)=> {
  setNote(e.target.value);
}

 
  
  const FetchBooksData = async () => {
    setLoading(true);

    const url = `${BaseURL}/book/getBooksData`
                                               
 
    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res?.data) {
        setBooksData(res?.data);
}

    
        
      
    
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchBooksData();
  }, []);

  const   AddIssue = async () => {

    let response = await request({
      url: `${BaseURL}/issue/addIssue`,
      // url: 'posts',
      method:"POST",
      data: JSON.stringify({
        // "feetype_name" : FeeType,
        "lid" : LibraryID,
        "book" : SelectedBook,
        "serial_no" : SerialNo,
        "due_date" : due_date,
        "note" : Note
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken    }`,
      }
    });
  
     if( response.status == "200" ) {
      Swal.fire({
        icon: "success",
        title: "success!",
        text: "Book   Issued Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate(`/library/issue`);
     }
    
     else if  (response.status == "404") {
        Swal.fire({
          icon: 'error',
          title: 'Oops..."',
          text:'No Library Member Exist!.',
          showConfirmButton: false,
          timer: 1500,
        });


       }
     
 
      
    }
      
     
  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Issue Details
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{marginTop:"-10px"}} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form class="form-horizontal" role="form" method="post" style={{marginTop:"-23px"}}>
                        <div class="form-group">
                        <div className="row">
                          <label for="lid" class="col-sm-2 control-label">
                            Library ID{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="lid"
                              name="lid"
                              onChange={HandleLibraryIDChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label
                            for="s2id_autogen1"
                            class="col-sm-2 control-label"
                          >
                            Book{" "}
                          </label>
                          <div class="col-sm-6">
                            <select
                              name="book"
                              id="book"
                              class="form-control select2 select2-offscreen"
                              tabindex="-1"
                              onChange={HandleBookChange}
                            >
                              <option value="0">Select Book</option>
                              { }
                              {BooksData && BooksData?.map((b) => {
                        return (
                          <option
                            key={b?.bookID}
                            value={b?.bookID}
                          >
                            {b?.book}
                          </option>
                        );
                      })}
                            </select>{" "}
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label for="author" class="col-sm-2 control-label">
                            Author{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="author"
                              name="author"
                              readonly="readonly"
                              value=""
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label
                            for="subject_code"
                            class="col-sm-2 control-label"
                          >
                            Subject Code{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="subject_code"
                              name="subject_code"
                              readonly="readonly"
                              value=""
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label for="serial_no" class="col-sm-2 control-label">
                            Serial No{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="serial_no"
                              name="serial_no"
                              onChange={HandleSerialNumberChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                        <div className="row">
                          <label for="due_date" class="col-sm-2 control-label">
                            Due Date{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="date"
                              class="form-control"
                              id="due_date"
                              name="due_date"
                              onChange={HandleDueDateChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                          <div className="row">
                          <label for="note" class="col-sm-2 control-label">
                            Note{" "}
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              id="note"
                              name="note"
                              onChange={HandleNoteChange}
                            />
                          </div>
                          <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick= {AddIssue}
                              value="Add Issue"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
