import React, { useState, useEffect } from "react";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import "./Monthly.css";

function Add() {
  document.title = "Bus Route | Monthly Route";
  const [loading, setLoading] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);

  const fetchBusMasterData = async () => {
    setLoading(true);
    const url = `${BaseURL}/busroute/busmonthroute`;
    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("API Response:", res);

      // Find the object containing 'dis'
      const disObject = res.find(obj => obj.dis);

      if (disObject && Array.isArray(disObject.dis)) {
        setMonthlyData(disObject.dis);
      } else {
        console.warn("Response does not contain 'dis' array in the expected format:", res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBusMasterData();
  }, []);

  console.log("monthlyData", monthlyData);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <h1 className="page-title">Bus Details</h1>
            <div className="portlet light bordered">
              <div className="portlet-title">
                <div className="caption font-dark">
                  <h4 className="caption-subject bold uppercase mx-3">
                    Bus Details
                  </h4>
                </div>
              </div>
              <div className="portlet-body mx-3">
                <hr />
                <div className="row">
                  <form
                    className="form-horizontal"
                    role="form"
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault(); // Prevent default form submission
                      // Implement form submission logic if needed
                    }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="month_block d-flex">
                            <div className="select_month col-xs-6 col-md-5">
                              <select
                                name="month"
                                id="month"
                                className="form-control select2"
                                style={{
                                  marginBottom: "5px",
                                  width: "100%",
                                  marginLeft: "-23px",
                                }}
                              >
                                <option value="00">Select a Month</option>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                            </div>
                            <div className="col-xs-6 col-md-10">
                              <input
                                type="submit"
                                className="btn btn-success iss-mar"
                                value="Find Route"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="col-sm-12">
                          <table
                            id="mexample_route"
                            className="table"
                            style={{ marginLeft: "-10px" }}
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Bus Name</th>
                                {[...Array(30)].map((_, index) => (
                                  <th key={index + 1}>{index + 1}</th>
                                ))}
                                <th>Total Distance</th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td colSpan="32" style={{ textAlign: "center" }}>
                                    Loading...
                                  </td>
                                </tr>
                              ) : (
                                monthlyData.map((bus, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>Bus {index + 1}</td> {/* Replace with actual bus name if available */}
                                    {[...Array(30)].map((_, dayIndex) => (
                                      <td key={dayIndex}>
                                        {bus[dayIndex.toString()] || "0.00"} Km
                                      </td>
                                    ))}
                                    <td>{bus.total} Km</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add;
