import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Loader from "../../../../Components/Loader/Loader";
import request from "../../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../../api/DataApis";

function Payment(props) {
  const apiEndpoint = `${BaseURL}/classes/getClasses`;

  document.title = "Account | Payment History";

  const [loading, setLoading] = useState(false);
  const [feePayment, setFeePayment] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectClassid, setSelectClassid] = useState("");
  const [TotalAmount, setTotalAmount] = useState([]);

  const handleClassChange = (event) => {
    setSelectClassid(event.target.value);
    fetchData(event.target.value);
  };

  const fetchData = async (selectedClass) => {
    setLoading(true);

    let url = `${BaseURL}/paymenthistory/paymentList`;
    if (selectedClass) {
      url = `${BaseURL}/paymenthistory/paymentList/${selectedClass}`;
    }

    let res = await request({
      url: url,
      method: "POST",
      data: JSON.stringify({
        schoolyearID: schoolyearID,
        usertypeID: "1",
        username: "admin",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });

    setLoading(false);

    if (res && res?.payments) {
      setFeePayment(res.payments);
    }

    if (res && res?.totalAmount) {
      setTotalAmount(res.totalAmount);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataa = async () => {
    setLoading(true);

    let res = await request({
      url: apiEndpoint,
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });

    setLoading(false);

    if (res && res.classes) {
      setClasses(res.classes);
    }
  };

  useEffect(() => {
    fetchDataa();
  }, []);

  const handleDelete = async (BcID) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteFeeCollectionApi = `${BaseURL}/buscollection/deleteBusFee`;

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteFeeCollectionApi}/${BcID}`,
            method: "POST",
            data: JSON.stringify({
              schoolyearID: schoolyearID,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            setFeePayment(feePayment.filter((item) => item.bcID !== BcID));
            fetchData();

            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            alert("Error");
          }
        } catch (error) {
          console.error("Error deleting class:", error);
        }
      }
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-2 add">
                    <select
                      className="select1"
                      onChange={handleClassChange}
                      style={{
                        padding: "8px 10px",
                        borderRadius: "40px",
                        position: "relative",
                        top: "6px",
                      }}
                    >
                      <option value="">Select Class</option>
                      <option value="-1">View All</option>
                      {classes.map((classses) => {
                        return (
                          <option
                            key={classses.classesID}
                            value={classses.classesID}
                          >
                            {classses.classes}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-1"></div>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={`Total amount: ${TotalAmount}`}
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>All Payment History</h5>
              </div>
              <div className="card-body">
                {loading && <Loader />}
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Student</th>
                        <th>Class</th>
                        <th>Fee Type</th>
                        <th>Method</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Payment By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {feePayment &&
                        feePayment?.map((u, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{u?.srname}</td>
                              <td>{u?.srclasses}</td>
                              <td>{u?.feetype}</td>
                              <td>{u?.paymenttype}</td>
                              <td>{u?.amount}</td>
                              <td>{u?.paymentdate}</td>
                              <td>{u?.uname}</td>
                              <td>
                                <i
                                  className="fa fa-trash delete"
                                  onClick={() => handleDelete(u.bcID)}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
