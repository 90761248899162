import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import request from '../../../Apis/request';
import { schoolyearID, uniqueToken, BaseURL } from '../../../api/DataApis';
import axios from 'axios';

// Component to render the student list
const StudentList = ({ LibraryReportData }) => {
  useEffect(() => {
    console.log("Received LibraryReportData:", LibraryReportData);
  }, [LibraryReportData]);

  return (
    <div className="card-body" style={{ marginLeft: "-19px" }}>
      <div className="table-responsive">
        <table className="table display dataTable" id="advance-1">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Book Name</th>
              <th>Book Number</th>
              <th>Library ID Number</th>
              <th>Student Name</th>
              <th>Class</th>
              <th>Section</th>
              <th>Issue Date</th>
              <th>Return Date</th>
              <th>Due Date</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {LibraryReportData && LibraryReportData.map((u, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{u?.book}</td>
                <td>{u?.serial_no}</td>
                <td>{u?.lID}</td>
                <td>{u?.student}</td>
                <td>{u?.class}</td>
                <td>{u?.section}</td>
                <td>{u?.issue_date}</td>
                <td>{u?.return_date || "Not Returned"}</td>
                <td>{u?.due_date}</td>
                <td>{u?.note}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const LibraryReport = () => {
  const [selectedReporttype, setselectedReporttype] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [Date, setDate] = useState('');
  const [Month, setMonth] = useState('');
  const [Year, setYear] = useState('');
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [LibraryReportData, setLibraryReportData] = useState([]);
  const [classs, setClasss] = useState([]);
  const [section, setSection] = useState([]);

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}/classes/getClasses`);
      setClasss(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchStudents = async () => {
    setLoading(true);
    const apiEndpoint = `${BaseURL}/libraryreport/getLibReportData`;

    try {
      const response = await request({
        url: apiEndpoint,
        method: "POST",
        data: JSON.stringify({
          "type" : selectedReporttype,   // Issue, Return, Both
          "classID" : selectedClass,   // "all" for all classes
          "sectionID" : selectedSection,  // nothing for all sections
          "year" : Year,
          "month" : Month,
          "Date" : Date
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response?.LibraryRecord) {
        setLibraryReportData(response?.LibraryRecord);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReportTypeChange = (event) => {
    setselectedReporttype(event.target.value);
  };

  const handleYearOption = (event) => {
    setYear(event.target.value);
  };

  const handleMonthOption = (event) => {
    setMonth(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleRenderList = async () => {
    if (selectedReporttype  && Year) {
      await fetchStudents();
      setShowList(true);
    } else {
      alert("Please select the essential options");
    }
  };

  const fetchSection = async () => {
    setLoading(true);
    const response = await axios.get(`${BaseURL}/section/getSections`);
    const data = await response.data;
    setTimeout(() => {
      if (data) {  
        setLoading(false);
        setSection(data.sections); 
      }
    }, 1000);
  };

  useEffect(() => {
    fetchSection();
  }, []);

  console.log("LibraryReportData", LibraryReportData);
  console.log("sections", section);

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card'>
              <div className="portlet-body mx-3">
                <h4>Library Report</h4>
                <hr style={{ marginTop: "0px" }} />
                <div className="row">
                  <div className="col-sm-12">
                    <form className="form-horizontal" role="form" method="post" style={{ marginTop: "-20px" }}>
                      <div className="row px-3">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-2">
                              <div className="form-group">
                                <label
                                  style={{ marginLeft: "-15px", fontSize: "14px" }}
                                  htmlFor="s2id_autogen1"
                                  className="control-label"
                                >
                                  Type{" "}
                                </label>
                                <select
                                  onChange={handleReportTypeChange}
                                  style={{ marginLeft: "-20px" }}
                                  name="examID"
                                  id="examID"
                                  className="form-control select2 select2-offscreen"
                                  tabIndex="-1"
                                >
                                  <option value="0" defaultValue="selected">
                                    Select Type
                                  </option>
                                  <option value="Issue">Issue</option>
                                  <option value="Return">Return</option>
                                  <option value="Both">Both</option>
                                </select>{" "}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label
                                  htmlFor="s2id_autogen2"
                                  className="control-label"
                                  style={{ marginLeft: "20px" }}
                                >
                                  Class{" "}
                                </label>
                                <select
                                  value={selectedClass}
                                  onChange={handleClassChange}
                                  style={{ marginLeft: "-10px" }}
                                  name="classesID"
                                  id="classesID"
                                  className="form-control select2 select2-offscreen"
                                  tabIndex="-1"
                                >
                                  <option value="0" defaultValue="selected">
                                    Select class
                                  </option>
                                  <option value="-1" >
                                    Select All
                                  </option>
                                  {classs?.map((classes) => (
                                    <option
                                      key={classes.classesID}
                                      value={classes.classesID}
                                    >
                                      {classes.classes}
                                    </option>
                                  ))}
                                </select>{" "}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label
                                  style={{ marginLeft: "10px" }}
                                  className="controlhhh-label"
                                >
                                  Section
                                </label>
                                <select
                                  value={selectedSection}
                                  onChange={handleSectionChange}
                                  style={{ marginLeft: "-10px" }}
                                  name="sectionID"
                                  id="sectionID"
                                  className="form-control select2 select2-offscreen"
                                  tabIndex="-1"
                                >
                                  <option value="0" defaultValue="selected">Select Section</option>
                                  {section && section?.map((u) => (
                                    <option key={u?.sectionID} value={u.sectionID}>{u?.section}</option>
                                  ))} 
                                </select>{" "}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label
                                  style={{ marginLeft: "10px" }}
                                  className="control-label"
                                >
                                  Year
                                </label>
                                <input
                                  type="number" 
                                  id="year"
                                  min="1900" 
                                  max="2100" 
                                  step="1"
                                  onChange={handleYearOption}
                                  style={{ marginLeft: "-10px" }}
                                  name="year"
                                  className="form-control"
                                  placeholder="Enter year"
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label
                                  style={{ marginLeft: "10px" }}
                                  className="control-label"
                                >
                                  Month
                                </label>
                                <input
                                  type="number" 
                                  id="month"
                                  min="1" 
                                  max="12" 
                                  step="1"
                                  onChange={handleMonthOption}
                                  style={{ marginLeft: "-10px" }}
                                  name="month"
                                  className="form-control"
                                  placeholder="Enter month"
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label
                                  style={{ marginLeft: "10px" }}
                                  className="control-label"
                                >
                                  Date
                                </label>
                                <input
                                  type="number" 
                                  id="date"
                                  min="1" 
                                  max="31" 
                                  step="1"
                                  onChange={handleDateChange}
                                  style={{ marginLeft: "-10px" }}
                                  name="date"
                                  className="form-control"
                                  placeholder="Enter date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-right">
                          <button
                            onClick={handleRenderList}
                            type="button"
                            className="btn btn-primary"
                          >
                            Render List
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Render the student list if showList is true */}
              {showList && LibraryReportData.length > 0 ? (
                <StudentList LibraryReportData={LibraryReportData} />
              ) : (
                <p>No data available to display.</p>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LibraryReport;
