import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";

function Fee_type() {
  const [Subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [FeeLists, setFeeLists] = useState([]);
  const [selectClassid, setSelectClassid] = useState("");
  const [loading, setLoading] = useState(false);
  const [Tenure, setTenure] = useState([]);

  const apiEndpoint = `${BaseURL}/feetypes/getFeetypes`;
  document.title = "Fee Type";
  let navigate = useNavigate();

  // Function to handle class selection
  const SelectClassHandler = (e) => {
    setSelectClassid(e.target.value);
  };

  // Function to fetch fee data based on the selected class
  const FetchFeeData = async (classesID) => {
    setLoading(true);

    const url = classesID
      ? `${BaseURL}/feetypes/getFeetypes/${classesID}`
      : apiEndpoint;

    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("API Response:", res);

      if (res && res.fee_list) {
        setFeeLists(res.fee_list);
        setClasses(res.classes);
      }

      if (res && res.tenure) {
        setTenure(res.tenure);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch fee data whenever the selected class ID changes
  useEffect(() => {
    FetchFeeData(selectClassid);
  }, [selectClassid]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="add" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Link className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm" style={{ width: 'auto', marginLeft: "30px" }} to="/account/feetypes/add">
                      <i className="fa fa-plus"></i> Add Fee Type
                    </Link>

                    <select
                      onChange={SelectClassHandler}
                      className="form-control text-center"
                      style={{
                        borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                        border: "-1px ", position: "relative", top: "5px"
                      }}
                    >
                      <option value="">Select class</option>
                      {classes && classes.map((cls) => (
                        <option
                          key={cls.classesID}
                          value={cls.classesID}
                        >
                          {cls.classes}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>Fee types</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Class</th>
                        <th>Month</th>
                        <th>Fee Type</th>
                        <th>Fee Amount Regular</th>
                        <th>Fee Amount Repeat</th>
                        <th>Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {FeeLists &&
                        FeeLists.map((e, i) => {
                          const result = classes.find(item => item.classesID === e.class_id);
                          return (
                            <tr key={e.subfeetype_id}>
                              <td>{i + 1}</td>
                              <td>{e.fee_name}</td>
                              <td>{result ? result.classes : ''}</td>
                              <td>{result ? result.name : ''}</td>
                              <td>{e.fee_tenure === '1' ? 'General' : e.fee_tenure === '0' ? 'Optional' : ''}</td>
                              <td>{e.fee_amount_old}</td>
                              <td>{e.fee_amount}</td>
                              <td>{e.display_order}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        {/* Footer content if needed */}
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fee_type;
