import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2";


    
function Notice() {
  document.title = "Notice";
  const [NoticeData, setNoticeData] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState("");
  // const [selectedHostelType, setSelectedhoste] = useState("");
  
                 
  // const deleteBusApi = `${BaseURL}/transport/busDelete`;
  const apiNoticepoint = `${BaseURL}/notice/noticeList`;

  
  const FetchNoticeData = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: apiNoticepoint,
        method: "GET",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        }
      });

      if (res && res?.notices) {
        setNoticeData(res?.notices);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchNoticeData();
  }, []);

  const handleEdit = (id) => {
    setIds(id);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteNoticeApi = `${BaseURL}/notice/noticeDelete`;
      

      if (results?.value) {
        try {
          const res = await request({
            url: `${DeleteNoticeApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              //  schoolyearID: schoolyearID
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            FetchNoticeData();
          } else {
          FetchNoticeData();
          }
        } catch (error) {
          console.error("Error deleting Data:", error);
        }
      }
    });
  }; 
  useEffect(() => {
    FetchNoticeData();
  }, []);

  console.log("NoticeData", NoticeData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
                  <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to={"/annoucement/notice/add"}
                    >
                      <i className="fa fa-plus"></i> Add Notice{" "}
                    </Link>
                    {/* <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/marks/add"
                    >
                      <i className="fa fa-plus"></i> Add a Mark{" "}
                    </Link> */}
                  </div>
                </div>
                <div>
              
                  <hr />
                  <h5>All Notices</h5>
                </div>
               
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Class</th>
                        <th>Date</th>
                        <th>File</th>
                        <th>Notice</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {NoticeData &&
                        NoticeData?.map((u, i) => { 
                       return (
                      <tr>
                        <td>{i+1}</td>
                        <td>{u?.title}</td>
                        <td>{u?.classesID === "-1" ? 'All' : u?.classesID}</td>
                        <td>{u?.date}</td>
                        <td>{u?.file ? "file uploaded" : "no file"}</td>
                        <td>{u?.notice}</td>
                        <td>
                        {/* <Link to="/transportmember/add">
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link> */}
                          <Link to={`/annoucement/notice/view/${u?.noticeID}`}>
                            <i
                              className="fa fa-check-square-o"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                          <Link to={`/annoucement/notice/edit/${u?.noticeID}`}
                          state = {{NoticeEditData : u}}
                          >
                            <i
                              className="fa fa-edit"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                           
<Link onClick={(e) => { e.preventDefault(); handleDelete(u?.noticeID); }}>
              <i className="fa fa-trash delete"></i>
            </Link>
                        </td>
                      </tr>
                       ); 
                     })}
                    </tbody>
                   
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notice;
