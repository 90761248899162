import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../Components/Loader/Loader";
import axios from "axios";
import request from "../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../api/DataApis";

function Buscollection() {
  const apiEndpoint = `${BaseURL}/classes/getClasses`;
  document.title = "Feecollection";

  const [loading, setLoading] = useState(false);
  const [feeCollection, setFeeCollection] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectClassid, setSelectClassid] = useState("");
  const [isToggled, setToggled] = useState(false);

  const handleToggle = () => {
    if (feeCollection.status === 1) setToggled(isToggled);
    else setToggled(!isToggled);
  };

  const handleClassChange = (event) => {
    setSelectClassid(event.target.value);
    fetchData(event.target.value);
  };

  const fetchData = async (selectedClass) => {
    setLoading(true);
    let url = `${BaseURL}/buscollection/getBusFeeCollection`;

    try {
      let res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (res && res.BusFeeCollection) {
        setFeeCollection(res.BusFeeCollection);
        console.log("feeCollection", res.BusFeeCollection);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchClasses = async () => {
    setLoading(true);

    try {
      let res = await request({
        url: apiEndpoint,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (res && res.classes) {
        setClasses(res.classes);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const handleDelete = async (BcID) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      if (results.value) {
        try {
          const DeleteFeeCollectionApi = `${BaseURL}/buscollection/deleteBusFee`;
          const res = await request({
            url: `${DeleteFeeCollectionApi}/${BcID}`,
            method: "POST",
            data: JSON.stringify({
              schoolyearID: schoolyearID,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            setFeeCollection(feeCollection.filter((item) => item.bcID !== BcID));
            fetchData();
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            alert("Error deleting fee collection.");
          }
        } catch (error) {
          console.error("Error deleting fee collection:", error);
        }
      }
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div
                    className="add"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto", marginLeft: "30px" }}
                      to="/buscollection/add"
                    >
                      <i className="fa fa-plus"></i> Add Bus Fee
                    </Link>
                    <select
                        className="form-control text-center"
                        style={{
                          borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                        border: "-1px ", position: "relative", top: "5px"
                          
                        }}
                      onChange={handleClassChange}
                     
                    >
                      <option value="">Select Class</option>
                      <option value="-1">View All</option>
                      {classes.map((classItem) => (
                        <option key={classItem.classesID} value={classItem.classesID}>
                          {classItem.classes}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
                <h5>Bus Fee Collection</h5>
              </div>
              <div className="card-body">
                {loading && <Loader />}
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>Receipt No</th>
                        <th>Student Bar Code</th>
                        <th>Name</th>
                        <th>Transaction Date</th>
                        <th>Payment Method</th>
                        <th>Route Distance</th>
                        <th>Deposit Amount</th>
                        <th>Bus Name</th>
                        <th>Stand</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {feeCollection &&
                        feeCollection.map((u, i) => (
                          <tr key={i}>
                            <td>{u.receiptNO}</td>
                            <td>{u.studentID}</td>
                            <td>{u.name}</td>
                            <td>{u.creationDate}</td>
                            <td>{u.paymentMethod}</td>
                            <td>{u.routeDistance}</td>
                            <td>{u.dAmount}</td>
                            <td>{u.comments}</td>
                            <td>{u.stand}</td>
                            {/* <td>
                              <Link to={""}>
                                <i className="fa fa-edit edit"></i>
                              </Link>
                              <Link to={""}>
                                <i className="fa fa-eye view"></i>
                              </Link>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDelete(u.bcID);
                                }}
                              >
                                <i className="fa fa-trash delete"></i>
                              </Link>
                            </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Buscollection;
