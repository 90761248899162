import React, {useState, useEffect} from "react";
// import { BaseURL, uniqueToken } from "../../../../api/DataApis";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import "../../../assets/css/datatables.css";
import "../../../assets/css/style_my.css";
import { Link } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import AddButton from "../../../Components/Buttons/AddButton";
import ClassList from "../../../Components/ClassList/ClassList";
import Breadcrumb from "../../../Components/BreadCrumb/Breadcrumb";
import ReactPaginate from "react-paginate";
import { PieChart } from "react-minimal-pie-chart";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { BorderRadius } from "../../../Constant";

// import "./Student.css";


function Acadmic_year() {
  document.title = "Administrator || Academic Year";
  const [academicData, setAcademicData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAcademic = async () => {
    // props.setProgress(10);
    setLoading(true);
    await axios
      .get(`${BaseURL}/schoolyear/schoolYearList`)
      .then(async (response) => {
        // props.setProgress(30);
        const data = await response.data;
        // props.setProgress(80);
        setTimeout(() => {
          if (data) {
            setLoading(false);
            setAcademicData(data?.schoolyears);
          }
          // props.setProgress(100);
        }, 1000);
      });
  };

  useEffect(() => {
    fetchAcademic();
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteAcademicyearApi =`${BaseURL}/schoolyear/deleteSchoolYear` ;

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteAcademicyearApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              // schoolyearID: 10,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            alert("res", res);
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            fetchAcademic();
          } else {
            alert("Error");
          }
        } catch (error) {
          console.error("Error deleting class:", error);
        }
      }
    });
  }; 

  
  console.log("academicData", academicData);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <Link
                    className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                    style={{ width: "auto" }}
 
                     to="/Administrator/acadmicAdd"
                  >
                    <i className="fa fa-plus"></i> ADD Acadmiicyear{" "}
                  </Link>
                </div>
                <div>
                  <hr style={{ marginTop: "12px" }} />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex" style={{ display:"flex",justifyContent:"space-between"}}>
                      <div className=""style={{display:"flex", gap:"10px", }}>
                        <button
                          className="drop-marg12"
                          style={{
                            marginLeft: "",
                            border: "1px solid black",
                            borderRadius: "27%",
                            background: "white",
                            height: "32px",
                          }}
                        >
                          {" "}
                          Copy
                        </button>
                        <button
                          className="drop-marg12"
                          style={{
                            marginLeft: "-8px",
                            border: "1px solid black",
                            borderRadius: "27%",
                            background: "white",
                            height: "32px",
                           position:"relative",
                            left:"20px"
                          }}
                        >
                          {" "}
                          Excel
                        </button>
                        <button
                          className="drop-marg12"
                          style={{
                            marginLeft: "-8px",
                            border: "1px solid black",
                            borderRadius: "27%",
                            background: "white",
                            height: "32px",
                             position:"relative",
                            left:"40px"
                          }}
                        >
                          {" "}
                          CSV
                        </button>
                        <button
                          className="drop-marg12"
                          style={{
                            marginLeft: "-8px",
                            border: "1px solid black",
                            borderRadius: "27%",
                            background: "white",
                            height: "32px",
                            position:"relative",
                            left:"62px"
                          }}
                        >
                          {" "}
                          PDF
                        </button>
                      </div>
                      <div
                        className="mlsearch"
                     
                      >
                        <input
                          className="drop-marg_searchs12"
                          type="text"
                          placeholder="search here"
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <hr style={{ marginTop: "2px" }} />
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                  
                      <tr>
                        <th>#</th>
                        <th>year</th>
                        <th>year-title</th>
                        <th>school year ID</th>
                        <th>action</th>
                      </tr>
                         
                    </thead>
                    

                    <tbody>
                    {academicData &&
                        academicData?.map((u, i) => {
                          return (
                      <tr>
                        <td>{i+1}</td>
                        <td>{u?.schoolyear}</td>
                        <td>{u?.schoolyeartitle}</td>
                        <td>{u?.schoolyearID}</td>
                        
                      
                        <td>
                        {/* <Link to="/transportmember/add">
                            <i
                              className="fa fa-plus"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link> */}
                          {/* <Link to={`/Administrator/acadmiceview/${u?.schoolyearID}`}>
                            <i
                              className="fa fa-check-square-o"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link> */}
                          <Link to={`/Administrator/acadmicedit/${u?.schoolyearID}`}
                          state = {{AcademicEditData : u}}
                          >
                            <i
                              className="fa fa-edit"
                              style={{ fontSize: "18px" }}
                            ></i>{" "}
                          </Link>
                           
<Link onClick={(e) => { e.preventDefault(); handleDelete(u?.schoolyearID); }}>
              <i className="fa fa-trash delete"></i>
            </Link>
                        </td>
                      </tr>
                       ); 
                     })}
                    </tbody>

                
                  </table>
                  {/* Render pagination */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Acadmic_year;
