import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { BaseURL, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import { Link } from "react-router-dom";

function Edit(props) {
  document.title = "System Admin |  Edit";

  let navigate = useNavigate();
  let location = useLocation();
  let SystemEditData = location.state.SystemEditData;
  console.log("SystemEditData", SystemEditData);

  useEffect(() => {
    setName(SystemEditData?.name);
    setDOB(SystemEditData?.dob);
    setGender(SystemEditData?.sex);
    setReligion(SystemEditData?.religion);
    setEmail(SystemEditData?.email);
    setPhone(SystemEditData?.phone);
    setAddress(SystemEditData?.address);
    setDOJ(SystemEditData?.jod);
    setUserName(SystemEditData?.username);
    

    

  }, [SystemEditData]);

  const [Name, setName] = useState([]);
  const [DOB, setDOB] = useState([]);
  const [Gender, setGender] = useState([]);
  const [Religion, setReligion] = useState([]);
  const [Email, setEmail] = useState([]);
  const [Phone, setPhone] = useState([]);
  const [Address, setAddress] = useState([]);
  const [DOJ, setDOJ] = useState([]);
  const [Photo, setPhoto] = useState(null);
  const [UserName, setUserName] = useState([]);
  const [Password, setPassword] = useState([]);

  const handleNameChange = (e) => setName(e.target.value);
  const handleDOBChange = (e) => setDOB(e.target.value);
  const handleGenderChange = (e) => setGender(e.target.value);
  const handleReligionChange = (e) => setReligion(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleAddressChange = (e) => setAddress(e.target.value);
  const handleDOJChange = (e) => setDOJ(e.target.value);
  const handlePhotoChange = (e) => setPhoto(e.target.files[0]);
  const handleUserNameChange = (e) => setUserName(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const EditSystemAdmin = async () => {

    let response = await request({
  url: `${BaseURL}/systemadmin/editSystemAdmin/${SystemEditData?.systemadminID}`,
      // url: 'posts',
      method: "POST",
      data: JSON.stringify({
        "name" : Name,
        "dob" : DOB,
        "sex" : Gender,
        "religion" : Religion,
        "email" : Email,
        "phone" : Phone,
        "address" : Address,
        "jod" : DOJ,
        "username" : UserName,
        "password" : Password,
        "loginuserID" : "3",
        
        "usertype" : "admin"
    }
    ),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${uniqueToken}`,
      }
    });

    if (response.status == "200") {
      Swal.fire({
        icon: "success",
        title: "success!",
        text: " System Updated Successfully...",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate('/Administrator/system-admin');
   
    }
    

  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title row">
                  <div className="caption font-dark col-md-10">
                    <h4 className="caption-subject bold uppercase mx-3">
                      Edit System Admin
                    </h4>
                  </div>
                  <div className="caption font-dark col-md-2">
                    <h5 className="caption-subject bold uppercase mx-3">
                      {/* <button className="btn btn-success" onClick={redirect}> */}
                      {/* Back */}
                      {/* </button> */}
                    </h5>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr style={{ marginTop: "-10px" }} />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        encType="multipart/form-data"
                        role="form"
                        method="post"
                        style={{ marginTop: "-20px" }}
                      >
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="name_id"
                              className="col-sm-2 control-label"
                            >
                              Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                id="name_id"
                                name="name"
                                onChange={handleNameChange}
                                value={Name}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="dob_id"
                              className="col-sm-2 control-label"
                            >
                              Date Of Birth
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="date"
                                className="form-control"
                                id="dob_id"
                                onChange={handleDOBChange}
                                value={DOB}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="gender_id"
                              className="col-sm-2 control-label"
                            >
                              Gender
                            </label>
                            <div className="col-sm-6">
                              <select
                                className="form-control"
                                id="gender_id"
                                onChange={handleGenderChange}
                                value={Gender}
                              >
                                <option value="0">select gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="religion_id"
                              className="col-sm-2 control-label"
                            >
                              Religion
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="religion_id"
                                onChange={handleReligionChange}
                                value={Religion}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="email_id"
                              className="col-sm-2 control-label"
                            >
                              Email
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="email"
                                className="form-control"
                                id="email_id"
                                onChange={handleEmailChange}
                                value={Email}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="phone_id"
                              className="col-sm-2 control-label"
                            >
                              Phone
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="phone_id"
                                onChange={handlePhoneChange}
                                value={Phone}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="address_id"
                              className="col-sm-2 control-label"
                            >
                              Address
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="address_id"
                                onChange={handleAddressChange}
                                value={Address}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="doj_id"
                              className="col-sm-2 control-label"
                            >
                              Joining Date
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="date"
                                className="form-control"
                                id="doj_id"
                                onChange={handleDOJChange}
                                value={DOJ}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="photo_id"
                              className="col-sm-2 control-label"
                            >
                              Photo
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="file"
                                className="form-control"
                                id="photo_id"
                                onChange={handlePhotoChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="username_id"
                              className="col-sm-2 control-label"
                            >
                              Username
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="username_id"
                                onChange={handleUserNameChange}
                                value={UserName}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label
                              htmlFor="password_id"
                              className="col-sm-2 control-label"
                            >
                              Password
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="password"
                                className="form-control"
                                id="password_id"
                                placeholder="can not change from  here!!!!"
                                readOnly
                                onChange={handlePasswordChange}
                                value={Password}
                                style={{width:"93%"}}
                              />
                                  <Link
                                    to={`/Administrator/Reset-password`}
                                    state={{
                                    
                                    }}
                                  >
                                    <i
                                      style={{
                                        marginTop: "-15px",
                                      
                                        fontSize: "22px",
                                        float : "inline-end",
                                        position : "relative",
                                        top: "-13px",
                                        left: "5px"
                                      }}
                                      className="fa fa-edit edit"
                                    ></i>
                                  </Link>
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              className="btn btn-success"
                              onClick={EditSystemAdmin}
                              value="Add System Admin"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
