import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2";

function Category() {
  const [CategoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const FetchHostelData = async () => {
    setLoading(true);

    const url = `${BaseURL}/category/getCategories`;

    try {
      const res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log("res", res);

      if (res && res.categorys) {
        setCategoryData(res.categorys);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchHostelData();
  }, []);

  const DeleteFunction = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteCategoryApi = `${BaseURL}/category/delCategory`;

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteCategoryApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              schoolyearID: schoolyearID,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            FetchHostelData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to delete data.",
            });
          }
        } catch (error) {
          console.error("Error deleting category:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while deleting the category.",
          });
        }
      }
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <div className="col-sm-4 add">
                    <Link
                      className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm"
                      style={{ width: "auto" }}
                      to="/hostel/category/add"
                    >
                      <i className="fa fa-plus"></i> Add category
                    </Link>
                  </div>
                </div>
                <div>
                  <hr />
                  <h5>All Category</h5>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" id="advance-1">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Hostel Name</th>
                        <th>Class Type</th>
                        <th>Hostel fee</th>
                        <th>Note</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CategoryData &&
                        CategoryData.map((u, i) => {
                          return (
                            <tr key={u.categoryID}>
                              <td>{i + 1}</td>
                              <td>{u?.name}</td>
                              <td>{u?.class_type}</td>
                              <td>{u?.hbalance}</td>
                              <td>{u?.note}</td>
                              <td>
                                <Link to="">
                                  <i
                                    className="fa fa-check-square-o"
                                    style={{ fontSize: "18px" }}
                                  ></i>{" "}
                                </Link>
                                <Link
                                  to={`/hostel/category/edit/${u?.categoryID}`}
                                  state={{ categoryEditData: u }}
                                >
                                  <i
                                    className="fa fa-edit"
                                    style={{ fontSize: "18px" }}
                                  ></i>{" "}
                                </Link>
                                <Link onClick={(e) => e.preventDefault()}>
                                  <i
                                    onClick={() => DeleteFunction(u?.categoryID)}
                                    className="fa fa-trash delete"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
