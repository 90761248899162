import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function HostelMember() {
  const { enquiryID, classesID } = useParams();
  const [classes, setClasses] = useState([]);
  const [studentss, setStudents] = useState([]);
  const [selectClassid, setSelectClassid] = useState("");
  const [loading, setLoading] = useState(false);
  const [isToggled, setToggled] = useState(false);
  const [EnquiryStatus, setEnquiryStatus] = useState("");
  const [TransportData, setTransportData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleToggle = () => {
    if (studentss.status === 1) setToggled(isToggled);
    else setToggled(!isToggled);
  };

  const SelectClassHandler = (e) => {
    setSelectClassid(e.target.value);
    fetchData(e.target.value);
  };

  const fetchData = async (classesID) => {
    const apiEndpoint = `${BaseURL}/student/getStudents`;

    setLoading(true);

    var url = apiEndpoint;
    if (classesID) {
      url = `${apiEndpoint}/${classesID}`;
    }

    let res = await request({
      url: url,
      method: "POST",
      data: JSON.stringify({
        schoolyearID: schoolyearID,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${uniqueToken}`,
      },
    });
    setClasses(res.classes);

    console.log("res", res);
    console.log("enquiry_status", EnquiryStatus);
    if (res && res.students) {
      setTransportData(res.students); // Update TransportData with all students
    }

    return [];
  };

  useEffect(() => {
    fetchData();
  }, [TransportData]);

  useEffect(() => {
    // Filter students based on searchQuery
    const filteredStudents = TransportData.filter(
      (student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.registerNO.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setStudents(filteredStudents);
  }, [searchQuery, TransportData]);

  console.log("classes", classes);
  console.log("transport-data", TransportData);
  console.log("filteredstudentss", studentss);
  const DeleteFunction = async (id) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (results) => {
      const DeleteHmemberApi = `${BaseURL}/hmember/delete_hmember`;

      if (results.value) {
        try {
          const res = await request({
            url: `${DeleteHmemberApi}/${id}`,
            method: "POST",
            data: JSON.stringify({
              schoolyearID: schoolyearID,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${uniqueToken}`,
            },
          });

          if (res.status === "200") {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Data Deleted Successfully...",
              showConfirmButton: false,
              timer: 1500,
            });
            fetchData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to delete data.",
            });
          }
          fetchData();
        } catch (error) {
          console.error("Error deleting Member:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while deleting the Member.",
          });
        }

      }
    });
  };

  console.log("students", TransportData);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <div
                  className="add"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 style={{ marginLeft: "30px", marginTop: "13px" }}>
                    All Hostel Members
                  </h5>
                  <select
                      className="form-control text-center"
                      style={{
                        borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                      border: "-1px ", position: "relative", top: "5px"
                        
                      }}
                      onChange={SelectClassHandler}
                    >
                      <option value="">Select Class</option>
                      <option value="-1">View All</option>
                      {classes.map((classes) => {
                        return (
                          <option
                            key={classes.classesID}
                            value={classes.classesID}
                          >
                            {classes.classes}
                          </option>
                        );
                      })}
                    </select>
                  {/* <Link className="btn btn-transparent grey-salsa btn-outline btn-circle btn-sm" style={{ width: 'auto' }} to="/transport/member/add"><i className="fa fa-plus"></i> Add Transport Member </Link> */}
                </div>
              </div>
              <div>
                <hr style={{ marginTop: "18px" }} />
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <table className="display dataTable" id="advance-1">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Photo</th>
                      <th>Roll</th>
                      {/* <th>Email</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TransportData.length > 0 ? (
                      TransportData.map((u, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <img style={{width:"24px"}} src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png" />

                              </td>
                            <td>{u?.name}</td>
                            <td>{u?.registerNO}</td>
                            
                            <td>
                              {/* Render link to add transport member only for students with transport field value equal to 0 */}
                              {u?.hostel === "0" && (
                                <Link
                                  to={`/hostel/member/add/${u.studentID}/${u.classesID}`}
                                  state={{ HostelAddData: u }}
                                >
                                  <i className="fa fa-plus"></i>
                                </Link>
                              )}
                              {u?.hostel === "1" && (
                                <Link
                                  to={`/hostel/member/edit/${u.studentID}/${u.classesID}`}
                                  state={{
                                    HostelEditData: u
                                  }}
                                >
                                  <i className="fa fa-edit edit"></i>
                                </Link>
                              )}
                              {u?.hostel === "1" && (
                                <Link
                                  to={`/hostel/member/view/${u.studentID}`}
                                  state={{ HostelViewData: u }}
                                >
                                  <i className="fa fa-eye view"></i>
                                </Link>
                              )}
                              {u?.hostel === "1" && (
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    DeleteFunction(u?.studentID);
                                  }}
                                >
                                  <i className="fa fa-trash delete"></i>
                                </Link>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7">Please Select Class</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostelMember;
