import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import { schoolyearID, uniqueToken, BaseURL } from '../../../api/DataApis';
import Swal from 'sweetalert2';
import request from '../../../Apis/request';

function Edit(props) {
  let location = useLocation();
  let navigate = useNavigate();
  let BooksEditData = location?.state?.BooksEditData;
  console.log("BooksEditData", BooksEditData);

  const [BooksNames, setBooksNames] = useState('');
  const [AuthorName, setAuthorNames] = useState('');
  const [SubjectCode, setSubjectCode] = useState('');
  const [Price, setPrice] = useState('');
  const [Quantity, setQuantity] = useState('');
  const [RackNo, setRackNo] = useState('');

  useEffect(() => {
    if (BooksEditData) {
      setBooksNames(BooksEditData.book);
      setAuthorNames(BooksEditData.author);
      setSubjectCode(BooksEditData.subject_code);
      setPrice(BooksEditData.price);
      setQuantity(BooksEditData.quantity);
      setRackNo(BooksEditData.rack);
    }
  }, [BooksEditData]);

  const handleBooksNamesChange = (event) => {
    setBooksNames(event.target.value);
  };

  const handleAuthorNameChange = (event) => {
    setAuthorNames(event.target.value);
  };

  const handleSubjectCodeChange = (event) => {
    setSubjectCode(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleRackNoChange = (event) => {
    setRackNo(event.target.value);
  };

  const UpdateBooks = async () => {
    if (!BooksEditData?.bookID) {
      console.error("Book ID is missing");
      return;
    }

    try {
      let response = await request({
        url: `${BaseURL}/book/editBook/${BooksEditData.bookID}`,
        method: "POST",
        data: JSON.stringify({
          book: BooksNames,
          author: AuthorName,
          subject_code: SubjectCode,
          price: Price,
          quantity: Quantity,
          due_quantity: "0",
          rack: RackNo,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response.status === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Book Updated Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });

        navigate(`/library/books`);
      }
    } catch (error) {
      console.error("Error updating book:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    UpdateBooks();
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item ms-4">
            <i className="fa fa-laptop"></i>
            <Link to="/"> Dashboard</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/marks"> Books </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit
          </li>
        </ol>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                     Edit Books Details
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr />
                  <div className="row">
                    <div className="col-sm-12">
                      <form className="form-horizontal" role="form"  >
                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="book" className="col-sm-2 control-label">
                              Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="book"
                                name="book"
                                value={BooksNames}
                                onChange={handleBooksNamesChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="author" className="col-sm-2 control-label">
                              Author
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="author"
                                name="author"
                                value={AuthorName}
                                onChange={handleAuthorNameChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="subject_code" className="col-sm-2 control-label">
                              Subject code
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="subject_code"
                                name="subject_code"
                                value={SubjectCode}
                                onChange={handleSubjectCodeChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="price" className="col-sm-2 control-label">
                              Price
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="price"
                                value={Price}
                                onChange={handlePriceChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="quantity" className="col-sm-2 control-label">
                              Quantity
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="quantity"
                                name="quantity"
                                value={Quantity}
                                onChange={handleQuantityChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label htmlFor="rack" className="col-sm-2 control-label">
                              Rack No
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                id="rack"
                                name="rack"
                                value={RackNo}
                                onChange={handleRackNoChange}
                              />
                            </div>
                            <span className="col-sm-4 control-label"></span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-8 add-class">
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-success"
                            >
                              Update Book
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit;
