import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { schoolyearID, uniqueToken, BaseURL } from "../../../api/DataApis";
import request from "../../../Apis/request";
import Swal from "sweetalert2"; 
 

function Add(props) {
  const [LibraryID, setLibraryID] = useState([]);
  const[LibraryFees, setLibraryFees] = useState([]);

const handleLibraryIDChange = (e) => {
  setLibraryID(e.target.value);
}
const handleLibraryFeesChange = (e) => {
  setLibraryFees(e.target.value);
}


  let location =  useLocation();
  let navigate =  useNavigate();
  let LibraryAddData = location.state.LibraryAddData;
  console.log("LibraryAddData", LibraryAddData);

  const AddMember = async () => {
    if (!LibraryAddData?.studentID) {
      console.error("Student ID is missing");
      return;
    }

    try {
      let response = await request({
        url: `${BaseURL}/lmember/lmemberAdd/${LibraryAddData?.studentID}`,
        method: "POST",
        data: JSON.stringify({
          schoolyearID: schoolyearID,
          lID: LibraryID,
          lbalance: LibraryFees,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response.status === "200") {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Member Added Successfully...",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate(`/library/member`);
      }
    } catch (error) {
      console.error("Error adding member:", error);
    }
  };

  return (
    <>
      
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div className="portlet light bordered">
                <div className="portlet-title">
                  <div className="caption font-dark">
                    <h4 className="caption-subject bold uppercase mx-3">
                     Add Library Member
                    </h4>
                  </div>
                </div>
                <div className="portlet-body mx-3">
                  <hr />
                  <div className="row">
                    <div className="col-sm-12">
                      <form
                        className="form-horizontal"
                        role="form"
                        method="post"
                      >
                        <div class="form-group">
                          <div className="row">
                            <label for="lID" class="col-sm-2 control-label">
                              Library ID{" "}
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="lID"
                                name="lID"
                                
                                onChange={handleLibraryIDChange}
                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="row">
                            <label
                              for="lbalance"
                              class="col-sm-2 control-label"
                            >
                              Library Fee{" "}
                            </label>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                id="lbalance"
                                name="lbalance"
                                onChange={handleLibraryFeesChange}
                              />
                            </div>
                            <span class="col-sm-4 control-label"></span>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-offset-2 col-sm-8 add-class">
                            <input
                              type="button"
                              onClick={AddMember}
                              className="btn btn-success"
                              value="Add Member"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
