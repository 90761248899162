import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import request from "../../../Apis/request";
import { BaseURL, schoolyearID, uniqueToken } from "../../../api/DataApis";
import Swal from "sweetalert2";
import "../Active-deactive/Active.css";
import axios from "axios";

function RTE_Students() {
  document.title = "Students | RTE";
  const { enquiryID, classesID } = useParams();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClassID, setSelectedClassID] = useState("");
  const [loading, setLoading] = useState(false);
  const [TransportData, setTransportData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudentIDs, setSelectedStudentIDs] = useState([]);
  const [parents, setParents] = useState([]);
  const [sections, setSections] = useState([]);

  const SelectClassHandler = (e) => {
    setSelectedClassID(e.target.value);
    fetchData(e.target.value);
  };

  const fetchData = async (classesID = "") => {
    const apiEndpoint = `${BaseURL}/student/getStudents`;
    setLoading(true);
    let url = apiEndpoint;
    if (classesID) {
      url = `${apiEndpoint}/${classesID}`;
    }

    try {
      let res = await request({
        url: url,
        method: "POST",
        data: JSON.stringify({ schoolyearID: schoolyearID }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      
    
      if (res && res.students) {
        setTransportData(res.students);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredStudents = TransportData.filter(
      (student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.registerNO.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setStudents(filteredStudents);
  }, [searchQuery, TransportData]);

  const handleStudentSelection = (studentID) => {
    setSelectedStudentIDs((prevSelected) =>
      prevSelected.includes(studentID)
        ? prevSelected.filter((id) => id !== studentID)
        : [...prevSelected, studentID]
    );
  };

  const changeStatus = async (studentIDs, newStatus) => {
    if (studentIDs.length === 0) {
      alert("Please select at least one student.");
      return;
    }

    try {
      const response = await request({
        url: `${BaseURL}/student/rteStatus`,
        method: "POST",
        data: JSON.stringify({
          sID: studentIDs,
          schoolyearID: schoolyearID,
          status: newStatus,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      console.log(response);
      fetchData(selectedClassID);

      Swal.fire({
        icon: "success",
        title: "Status Updated",
        text: "The status has been successfully changed.",
      });
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const fetchParents = async () => {
    try {
      setLoading(true);
      const response = await request({
        url: `${BaseURL}/parents/getParents`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response.parents) {
        setParents(response.parents);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParents();
  }, []);

  const getFatherName = (parentID) => {
    const parent = parents.find((p) => p.parentsID === parentID);
    return parent ? parent.father_name : "Loading....";
  };

  const getClassName = (classID) => {
    const classItem = classes.find((c) => c.classesID === classID);
    return classItem ? classItem.classes : "Loading....";
  };

  const fetchSection = async () => {
    setLoading(true);
    const response = await axios.get(`${BaseURL}/section/getSections`);
    const data = await response.data;
    setTimeout(() => {
      if (data) {
        setLoading(false);
        setSections(data.sections);
      }
    }, 1000);
  };

  useEffect(() => {
    fetchSection();
  }, []);

  const getSectionName = (sectionID) => {
    const section = sections.find((s) => s.sectionID === sectionID);
    return section ? section.section : "Loading....";
  };
  const fetchClasses = async () => {
    setLoading(true);
    try {
      const response = await request({
        url: `${BaseURL}/classes/getClasses`,
        method: "POST",
        data: JSON.stringify({ schoolyearID }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${uniqueToken}`,
        },
      });

      if (response && response.classes) {
        setClasses(response.classes);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchClasses();
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <div
                  className="add"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 style={{ marginLeft: "30px", marginTop: "15px" }}>
                    Student RTE Status
                  </h5>
                  <select
                    className="form-control text-center"
                    onChange={SelectClassHandler}
                    style={{
                      borderRadius: "34px", width: "133px", fontSize: "2.5vh",
                      border: "-1px ", position: "relative", top: "5px"                    }}
                  >
                    <option value="">Select Class</option>
                    <option value="-1">View All</option>
                    {classes &&
                      classes.map((classItem) => (
                        <option
                          key={classItem.classesID}
                          value={classItem.classesID}
                        >
                          {classItem.classes}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <hr style={{ marginTop: "18px" }} />
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="display dataTable" id="advance-1">
                  <thead>
                    <tr>
                      <th>
                        All
                        <input
                        style={{marginLeft:"4px",}}
                          type="checkbox"
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setSelectedStudentIDs(
                              checked ? TransportData.map((s) => s.studentID) : []
                            );
                          }}
                        />
                      </th>
                      <th>#</th>
                      <th>Photo</th>
                      <th>Name</th>
                      <th>Father Name</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Registration No</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.length > 0 ? (
                      students.map((student, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedStudentIDs.includes(student.studentID)}
                              onChange={() => handleStudentSelection(student.studentID)}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              style={{ width: "24px" }}
                              src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png"
                              alt="student"
                            />
                          </td>
                          <td>{student?.name}</td>
                          <td>{getFatherName(student?.parentID)}</td>
                          <td>{getClassName(student?.classesID)}</td>
                          <td>{getSectionName(student?.sectionID)}</td>
                          <td>{student?.registerNO}</td>
                          <td>
                            {student?.rte === "0" ? (
                              <button
                                className="btn btn-success"
                                onClick={() => changeStatus([student?.studentID], "1")}
                              >
                                Change to RTE
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger"
                                onClick={() => changeStatus([student.studentID], "0")}
                              >
                                Remove from RTE
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9">Please Select Class</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {selectedClassID && TransportData.length > 0 && (
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => changeStatus(selectedStudentIDs, "1")}
                  >
                    Change to RTE
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => changeStatus(selectedStudentIDs, "0")}
                  >
                    Remove from RTE
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RTE_Students;
