import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import image from "../../../assets/images/dummy_pic.jpeg";



const View = (props) => {
  let location = useLocation();
  let studentData = location.state.studentData;
  console.log("studentData", studentData);


  return (
    <>
    
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <h1 className="page-title"></h1>
              <div id="printablediv">
                <section className="panel">
                  {/* <h4 className="mx-3">Student Details</h4>
                    <hr /> */}
                  <div className="profile-view-head">
                    <a href="#">
                      <img
                        className="imagev"
                        src={image}
                        alt=""
                      />
                       <h5>{studentData?.name}</h5>
                    </a><br /><br /><br /><br /><br />
                   </div>
                  <div class="panel-body profile-view-dis">
                <h1>Personal Information</h1>
                <div class="row">
                    <div class="profile-view-tab">
                        <p><span>Register NO</span>:<b> {studentData?.registerNO}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Roll</span>: <b>{studentData?.roll}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Section </span>: <b>{studentData?.sectionID}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Date Of Birth </span>:<b> {studentData?.dob}</b></p>
                    </div>
                    {/* <div class="profile-view-tab">
                        <p><span>Gender</span>: 01 Jan 1970</p>
                    </div> */}
                    <div class="profile-view-tab">
                        <p><span>Gender </span>:<b> {studentData?.sex}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Blood Group </span>:<b> {studentData?.bloodgroup}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Religion </span>:<b>{studentData?.religion}</b> </p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Email </span>: <b>{studentData?.email}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Phone </span>: <b>{studentData?.phone}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Address </span>:<b> {studentData?.address}</b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>State </span>: <b> {studentData?.state} </b></p>
                    </div>
                    <div class="profile-view-tab">
                        <p><span>Country</span>: <b>{studentData?.country}</b> </p>
                    </div>
                            <div class="profile-view-tab">
                        <p><span>Username </span>:<b> {studentData?.username} </b></p>
                    </div>
                    </div>
                <h1>Attendance Information</h1>
                <div class="row">
                    <div class="col-sm-12" style={{marginLeft:"-20px"}}>
                        <div id="hide-table">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr >
                                        <th>#</th>
                                        <th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th><th>7</th><th>8</th><th>9</th><th>10</th><th>11</th><th>12</th><th>13</th><th>14</th><th>15</th><th>16</th><th>17</th><th>18</th><th>19</th><th>20</th><th>21</th><th>22</th><th>23</th><th>24</th><th>25</th><th>26</th><th>27</th><th>28</th><th>29</th><th>30</th><th>31</th>                                    </tr>
                                </thead>
                                                            </table>
                        </div>
                    </div>
                </div>

            </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
